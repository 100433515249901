import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../service/data.service';
import { iBisEditService } from '@dohu/ibis-common';

@Injectable()

export class CalendarService extends iBisEditService {
  constructor(public ds: DataService) {
    super(ds.lg);
  }

  createDefault() {
    return {};
  }

  getById(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data.appointment) {
        const sDate = data.startDate ? new Date(data.startDate) : new Date();
        const eDate = data.endDate ? new Date(data.endDate) : new Date();
        const sTime = data.startDate ? new Date(data.startDate) : null;
        const eTime = data.endDate ? new Date(data.endDate) : null;
        const doctor = data.userId || ((data.groups && data.groups.ap_userId) || data.ap_userId);
        const operationRoom = data.operatingRoomId || data.ap_operatingRoomId || (data.groups && data.groups.ap_operatingRoomId);

        const obj = {
          id: data.ap_id,
          patientId: data.ap_patientId,
          userId: doctor,
          startDate: sDate,
          startTime: this.ds.returnTime(sTime),
          endTime: this.ds.returnTime(eTime),
          endDate: eDate,
          description: data.ap_description,
          statusId: data.ap_statusId,
          operatingRoomId: operationRoom || null
        };
        resolve(obj);
      } else {
        const q = new EntityQuery('Appointment').eq('id', data.ap_id);
        EntityStore.fromQuery(q).single().then((result: any) => {
          if (!result) {
            reject();
          }
          result.patientName = data.patientName;
          resolve(result);
        }, err => {
          reject(err);
        });
      }
    });
  }

  onRemove(id: string): Promise<void> {
    return new Promise((resolve) => { });
  }

  onSaveEv(): Promise<any> {
    return new Promise((resolve) => {
      resolve();
    });
  }

  reset(): void {
    this.model = {};
  }

}
