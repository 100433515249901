import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()

export class EditUserSpecializationService extends iBisEditService {

    clinicSpecs: any = [];
    initialClinicSpecs: any = [];

    constructor(public ds: DataService, public lg: iBisLanguageService) {
        super(ds.lg);
        this.validation = 'userSpecValidation';

    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!data.isSpecUserInsert) {
                this.ds.getPartySpecialization(data.id).load().then((res: any) => {
                    if (res && res.data && res.data.length) {
                        res.data.forEach((el: any) => {
                            this.clinicSpecs.push(el.specializationId);
                            this.initialClinicSpecs.push(el.specializationId);
                        });
                    }
                });
            }
            resolve(data);
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            const toDelete = this.differencesInArrays(this.initialClinicSpecs, this.clinicSpecs);
            const toInsert = this.differencesInArrays(this.clinicSpecs, this.initialClinicSpecs);
            this.model.newSpecs = this.clinicSpecs.slice(0);
            if (this.model.isSpecUserInsert) {
                this.addSpecialization(this.model.id, toInsert).then(() => {
                    this.addUserSpecialization(this.model.id, this.model.userId, this.clinicSpecs).then((r: string) => {
                        this.model.isSaving = r === 'OK';
                        resolve(this.model);
                    });
                });
            } else {
                this.addDeletePartySpecialization(this.model.id, toInsert, toDelete).then((r: string) => {
                    this.model.isSaving = r === 'OK';
                    resolve(this.model);
                });
            }
        });
    }

    addUserSpecialization(partyId: string, userId: string, toInsert: any) {
        return new Promise((resolve: any) => {
            for (let i = 0; i < toInsert.length; i++) {
                this.ds.setDocSpec(userId, toInsert[i], partyId).then(() => {
                    if (toInsert.length === i + 1) {
                        resolve('OK');
                    }
                }, error => resolve('ERROR'));
            }
        });
    }

    deleteSpecialization(partyId: string, toDelete: any) {
        return new Promise((resolve: any) => {
            for (let i = 0; i < toDelete.length; i++) {
                const usQuery = new EntityQuery('UserSpecialization').eq('partyId', partyId);
                const psQuery = new EntityQuery('PartySpecialization').eq('partyId', partyId);
                EntityStore.removeByQuery(usQuery.eq('specializationId', toDelete[i])).then(() => {
                    EntityStore.removeByQuery(psQuery.eq('specializationId', toDelete[i])).then(() => {
                        if (toDelete.length === i + 1) {
                            resolve('OK');
                        }
                    }, error => resolve('ERROR'));
                }, error => resolve('ERROR'));
            }
        });
    }

    addSpecialization(partyId: string, toInsert: any) {
        return new Promise((resolve: any) => {
            const psi = EntityStore.fromQuery(new EntityQuery('PartySpecialization'));
            if (toInsert.length === 0) { resolve('OK'); }
            for (let i = 0; i < toInsert.length; i++) {
                psi.insert({ partyId: partyId, specializationId: toInsert[i] }).then(() => {
                    if (toInsert.length = i + 1) {
                        resolve('OK');
                    }
                }, error => resolve('ERROR'));
            }
        });
    }

    addDeletePartySpecialization(partyId: any, toInsert: any, toDelete: any) {
        return new Promise((resolve: any) => {
            if (toInsert.length && toDelete.length) {
                // are specializari de adaugat si de sters
                this.deleteSpecialization(partyId, toDelete).then((deleteResponse: any) => {
                    if (deleteResponse === 'OK') {
                        this.addSpecialization(partyId, toInsert).then((r: string) => {
                            resolve(r);
                        });
                    }
                });
            } else if (toInsert.length && !toDelete.length) {
                // are doar de adaugat specializari
                this.addSpecialization(partyId, toInsert).then((r: string) => {
                    resolve(r);
                });
            } else if (!toInsert.length && toDelete.length) {
                // are doar de sters specializari
                this.deleteSpecialization(partyId, toDelete).then((r: string) => {
                    resolve(r);
                });
            } else {
                // nu s-au facut modificari dar returnam ok pentru cazul cand 
                // la drag and drop de doctor seteaza doctorul pe o specializare existenta
                resolve('OK');
            }
        });
    }

    differencesInArrays(arr1: any, arr2: any) {
        const difference = arr1.filter((x: any) => !arr2.includes(x));
        return difference;
    }

    reset(): void {
        this.model = {};
        this.clinicSpecs = [];
        this.initialClinicSpecs = [];
    }

    createDefault() {
        this.reset();
    }
}
