import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';

@Injectable()

export class EditSendSmsService extends iBisEditService {

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'sendSmsValidation';

    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(data);
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            EntityStore.execute('SendSMS', { body: this.model.messageText }).then(data => {
                if (data === 'OK') {
                    notify('Mesajele au fost date în procesare', 'success', 3000);
                    resolve(data);
                } else {
                    notify('Nu s-a putut procesa trimiterea mesajelor', 'error', 3000);
                }
            }, err => {
                notify(err, 'error', 3000);
            });
        });
    }

    reset(): void {
        this.model = { messageText: '' };
    }

    createDefault() {
        this.reset();
    }

}
