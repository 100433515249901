import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { DataService } from './service/data.service';
import { EntityStore, EntityStoreOptions, EntityQuery } from '@dohu/ibis-entity';
import devices from 'devextreme/core/devices';
import { HttpClient } from '@angular/common/http';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisGridConfigService, iBisLanguageService } from '@dohu/ibis-common';
import { locale, loadMessages } from 'devextreme/localization';
import * as labelNames from '../assets/label.json';
import * as devExtremeMessages from '../assets/devExtreme.json';
import { EditRoleSelectionService } from './common/role-selection/role-selection.service';
import { EditSpecSelectionService } from './common/spec-selection/spec.selection.service';
import { EditPasswordService } from './common/edit-password/edit-password.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public appVersion: any;

  constructor(public ds: DataService, public auth: iBisAuthService, public http: HttpClient, public router: Router,
    public localization: iBisLanguageService, public selectRole: EditRoleSelectionService, public selectSpec: EditSpecSelectionService,
    public editPassword: EditPasswordService) {
    EntityStoreOptions.DefaultServer = environment.DefaultUrlServer;
    EntityStoreOptions.Http = http;
    auth.deployId = environment.deployId;
    auth.authServer = environment.authServer;
    auth.saasServer = environment.saasServer;
    localization.server = environment.saasServer;
    auth.appName = 'MedAsist';
    ds.appName = 'MedAsist';
  }

  ngOnInit() {
    const { version: appVersion } = require('../../package.json');
    this.appVersion = appVersion;
    locale('ro');
    loadMessages(<any>labelNames);
    loadMessages(<any>devExtremeMessages);
    const dev = devices.current();
    switch (dev.deviceType) {
      case 'phone':
      case 'tablet':
        this.ds.device = 'phone';
        break;
      default:
        this.ds.device = 'desktop';
    }
  }

  openEditPassword() {
    this.editPassword.showPopup({}, false).then((res: any) => {
      this.editPassword.model = this.editPassword.createDefault();
    });
  }

  getSwitchView() {
    if (this.router.url.indexOf('/form') === 0) {
      return 'form-card';
    } else {
      return 'single-card';
    }
  }

  checkLogin() {
    return this.ds.isLoginModalVisible && this.router.url !== '/login';
  }

  openChangeUserRole() {
    this.ds.getRolesForUser({ userId: this.ds.userLogin.userId, partyId: this.ds.currentClinic.id }).then((roles: any) => {
      const stringRoles = [];
      roles.forEach((el: any) => {
        stringRoles.push(el.name);
      });
      this.ds.userLogin.roles = stringRoles;
      this.selectRole.showPopup(this.ds.userLogin, true).then((res: any) => {
        if (res && res.isSaving) {
          if (res.userType === 2) {
            this.ds.getDoctorSpecializations(res.userId, this.ds.currentClinic.id).then((specs: any) => {
              if (specs && specs.data && specs.data.length > 1) {
                res.specs = specs.data;
                this.selectSpec.showPopup(res, true).then((sel: any) => {
                  this.ds.setUser(sel);
                });
              } else if (specs && specs.data && specs.data.length === 1) {
                res.specId = specs.data[0].id;
                this.ds.setUser(res);
              } else {
                notify('Nu aveți atribuită nicio specializare pe clinica selectată.' +
                  'Va rugăm contactați un administrator', 'error', 5000);
                this.ds.userLogin = JSON.parse(sessionStorage.getItem('currentUser' + this.ds.appName));
              }
            });
          } else {
            this.ds.setUser(res);
          }
        }
      });
    });
  }
}

