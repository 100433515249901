import { Component, OnInit, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DataService } from '../../../../app/service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DxListComponent, DxAccordionComponent } from 'devextreme-angular';
import { EditUserSpecializationService } from '../../../common/userSpecialization/userSpecialization.service';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-company-details',
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.scss']
})

export class CompanyDetailsComponent implements OnInit {

	@ViewChild('specList') specList: DxListComponent;
	@ViewChild('roleList') roleList: DxListComponent;
	@ViewChild('partyList') partyList: DxListComponent;
	@ViewChild('accordion') accordion: DxAccordionComponent;

	dsParty: any = [];
	dsDoctorSchedule: any = [];
	dsClinicDoctors: any = [];
	dsClinicUsers: any = [];
	currentClinic: any = [];
	dsDoctors: any = [];
	dsUsers: any = [];
	dsSpecs: any = [];
	// dsOperatingRoom: any = [];
	dsOperatingRoom: CustomStore;
	partyData: any = {};
	temporarySaveData: any = {};
	societyData: any = {};


	localityInstance: any;
	dsLocality: any;
	dsCounty: CustomStore;
	dsRoles: CustomStore;

	clinicReadOnlyCondition: boolean;
	companyReadOnlyCondition: boolean;
	isLogoLoaded: boolean;
	doctorRoleId: string;
	adminRoleId: string;
	selectedOperatingRoom: string;

	schedulerSubject: Subject<any> = new Subject<any>();
	logoSubscription: Subscription;


	constructor(public ds: DataService, public userSpec: EditUserSpecializationService) { }


	ngOnInit() {
		if (!this.ds.auth.isAuth) { return; }
		this.isLogoLoaded = this.ds.logoPath ? true : false;
		this.logoSubscription = this.ds.logoSubject.subscribe((loaded: boolean) => {
			if (loaded) {
				this.isLogoLoaded = true;
			}
		});
		this.ds.getAppRoles().load().then((roles: any) => {
			if (roles && roles.data && roles.data.length) {
				this.dsRoles = roles.data;
				// pentru incarcarea doctorilor
				this.doctorRoleId = roles.data.find((x: any) => x.name === 'doctor') ? roles.data.find((x: any) => x.name === 'doctor').id : null;
				// pentru asocierea automata a unui admin la creare de clinica
				this.adminRoleId = roles.data.find((x: any) => x.name === 'admin') ? roles.data.find((x: any) => x.name === 'admin').id : null;
				this.initParty();
			}
		});
		this.initSocietyData();
		this.ds.loadUsers().then((result: any) => {
			// pentru drag and drop, nu merge customStore
			this.dsUsers = result.data;
		}, err => notify(err, 'error', 3000));



		this.setLocalityDataSource();
		this.dsCounty = this.ds.geographicAreas(2, 'id');
		this.clinicReadOnlyCondition = true;
		this.companyReadOnlyCondition = true;
	}

	initSocietyData() {
		this.ds.getSocietyData().then((res: any) => {
			if (res.data && res.data.length) {
				const currentSociety = res.data.find((x: any) => x._Companie_ === this.ds.auth.companyName);
				Object.assign(this.societyData, currentSociety);
			}
		});
	}

	getRegisterAttrFields(model: any) {
		const keys = Object.keys(model);
		const newModel = {};
		for (let i = 0; i < keys.length; i++) {
			if (keys[i].indexOf('_') > -1) {
				const newKey = keys[i].substring(1, keys[i].length - 1);
				newModel[newKey] = model[keys[i]];
			}
		}
		return newModel;
	}

	initClinicDoctors(partyId: string, roleId: string) {
		this.ds.getUsersForRole({ partyId: partyId, roleId: roleId }).then((dcs: any) => {
			if (dcs === 'NO_USERS') {
				this.dsDoctors = [];
				return;
			}
			this.dsDoctors = dcs;
		});
	}

	collapseAccordionItems() {
		const openedTabs = this.accordion.instance.option('selectedItemKeys');
		if (openedTabs.length > 0) {
			for (let i = 0; i < openedTabs.length; i++) {
				switch (openedTabs[i].title) {
					case 'Program clinică':
						this.accordion.instance.collapseItem(1);
						break;
					case 'Drepturi utilizatori clinică':
						this.accordion.instance.collapseItem(2);
						break;
					case 'Specializări clinică':
						this.accordion.instance.collapseItem(3);
						break;
					case 'Bloc operator clinică':
						this.accordion.instance.collapseItem(4);
						break;
				}
			}
		}
	}

	onPartyChanged(ev: any) {
		if (ev.addedItems && ev.addedItems.length) {
			if (ev.addedItems[0].id === 0) {
				this.partyData = { tabIndex: 0 };
				this.collapseAccordionItems();
				this.accordion.instance.option('selectedIndex', 0);
				this.clinicReadOnlyCondition = false;
				return;
			}
			this.clinicReadOnlyCondition = true;
			Object.assign(this.partyData, ev.addedItems[0]);

			this.schedulerSubject.next({ index: 'clinicSchedule', partyId: this.partyData.id });
			if (this.partyData.localityId) {
				this.ds.getCountyId(this.partyData.localityId).then((res: any) => {
					if (res) {
						this.partyData.countyId = res.parentId;
					}
				}, (error: any) => console.log(error));
			}
			// this.ds.getOperatingRoom(this.partyData.id).load().then((ors: any) => {
			//     if (ors && ors.data) {
			//         this.dsOperatingRoom = ors.data || [];
			//     }
			// });
			this.dsOperatingRoom = this.ds.getOperatingRoom(this.partyData.id);

			this.dsClinicDoctors = [];
			if (this.doctorRoleId) {
				this.initClinicDoctors(this.partyData.id, this.doctorRoleId);
			}
			if (this.roleList && this.roleList.selectedItemKeys.length) {
				const selectedRole = this.roleList.selectedItemKeys[0];
				this.dsClinicUsers = [];
				this.ds.getPartyUserById(this.partyData.id, selectedRole).load().then((res: any) => {
					if (res && res.data && res.data.length) {
						for (let i = 0; i < res.data.length; i++) {
							const user = this.dsUsers.find((x: any) => x.id === res.data[i].userId);
							this.dsClinicUsers.push(user);
						}
					}
				});
			}
			this.initPartySpecs();
			this.populateSpecUserDropArea();
		}
	}

	populateSpecUserDropArea() {
		if (this.specList && this.specList.selectedItemKeys.length) {
			const selectedSpec = this.specList.selectedItemKeys[0];
			this.dsClinicDoctors = [];
			this.ds.getSpecUser(selectedSpec, this.partyData.id).then((res: any) => {
				if (res && res.data && res.data.length) {
					for (let i = 0; i < res.data.length; i++) {
						const doctor = this.dsUsers.find((x: any) => x.id === res.data[i].userId);
						this.dsClinicDoctors.push(doctor);
					}
				}
			}, error => console.log(error));
		}
	}

	onRoleChanged(ev: any) {
		if (ev.addedItems && ev.addedItems.length) {
			this.dsClinicUsers = [];
			this.ds.getPartyUserById(this.partyData.id, ev.addedItems[0].id).load().then((res: any) => {
				if (res && res.data && res.data.length) {
					for (let i = 0; i < res.data.length; i++) {
						const user = this.dsUsers.find((x: any) => x.id === res.data[i].userId);
						this.dsClinicUsers.push(user);
					}
				}
			});
		}
	}

	initPartySpecs() {
		this.ds.getSpecializationByParty(this.partyData.id).load().then((res: any) => {
			if (res && res.data) {
				this.dsSpecs = res.data;
			}
			this.dsSpecs.unshift({ id: 0, description: 'Adaugă +' });
		}, error => console.log(error));
	}

	initParty(selectItem?: any) {
		this.ds.checkIfAppAdmin().then((isAppAdmin: number) => {
			const op = isAppAdmin ? this.ds.getParty() : this.ds.getPartyUsers(this.adminRoleId);
			op.load().then((res: any) => {
				if (res && res.data) {
					this.dsParty = res.data;
				}
				this.dsParty.unshift({ id: 0, name: 'Adaugă +' });
				if (this.partyList) {
					this.partyList.instance.unselectAll();
				}
				const firstItem = res.data && res.data.length > 0 ? res.data[1].id : this.dsParty[0];
				setTimeout(() => {
					this.currentClinic.push(selectItem || firstItem);
				}, 1000);
			});
		});
	}

	onSpecChanged(ev: any) {
		if (ev.addedItems && ev.addedItems.length) {
			if (ev.addedItems[0].id === 0) {
				this.userSpec.showPopup(this.partyData, true).then(() => {
					this.userSpec.createDefault();
					this.initPartySpecs();
					this.dsClinicDoctors = [];
					if (this.specList) {
						this.specList.instance.unselectAll();
					}
				});
				return;
			}
			this.dsClinicDoctors = [];
			this.ds.getSpecUser(ev.addedItems[0].id, this.partyData.id).then((res: any) => {
				if (res && res.data && res.data.length) {
					for (let i = 0; i < res.data.length; i++) {
						const doctor = this.dsUsers.find((x: any) => x.id === res.data[i].userId);
						this.dsClinicDoctors.push(doctor);
					}
				}
			}, error => console.log(error));
		}
	}

	onDoctorChanged(ev: any) {
		if (ev.addedItems && ev.addedItems.length) {
			const doctor = ev.addedItems[0].id;
			this.schedulerSubject.next({ index: doctor, userId: doctor, partyId: this.partyData.id });
		}
	}

	onPartyUserDeleting(ev: any) {
		const promise = new Promise((resolve: any) => {
			confirm('Sunteți sigur că doriți să ștergeți acest utilizator de pe rol ? ', 'Atenție').then((val) => {
				if (!val) {
					resolve(!val);
				} else {
					this.ds.removeUserParty(ev.itemData.id, this.partyData.id, this.roleList.selectedItems[0]).then((response: any) => {
						if (response !== 'ERROR') {
							resolve(false);
							notify('Succes.', 'success', 3000);
							if (response === 'OK_DOCTOR') {
								this.initClinicDoctors(this.partyData.id, this.doctorRoleId);
								this.populateSpecUserDropArea();
							}
						} else {
							resolve(true);
						}
					});
				}
			});
		});
		ev.cancel = promise;
	}

	onUserSpecializationDeleting(ev: any) {
		const promise = new Promise((resolve: any) => {
			confirm('Sunteți sigur că doriți să ștergeți acest doctor de pe specializare ? ', 'Atenție').then((val) => {
				if (!val) {
					resolve(!val);
				} else {
					this.ds.removeUserSpecialization(ev.itemData.id, this.partyData.id, this.specList.selectedItemKeys[0]).then(() => {
						this.populateSpecUserDropArea();
						notify('Succes.', 'success', 3000);
					});
				}
			});
		});
		ev.cancel = promise;
	}

	onLocalityInit = (ev: any) => {
		this.localityInstance = ev.component;
	}

	onCountyChange = (ev: any) => {
		if (ev.event) {
			this.ds.resetValidation(this.localityInstance);
		}
		if (ev.value) {
			this.localityInstance.option('dataSource', this.ds.getLocalitiesByCountyId(ev.value));
		} else if (ev.value === null) {
			this.setLocalityDataSource();
		}
	}

	setLocalityDataSource() {
		this.dsLocality = new DataSource({
			store: this.ds.geographicAreas(1, 'id'),
			paginate: true,
			pageSize: 50
		});
	}

	onDragStart = (e: any) => {
		e.itemData = e.fromData[e.fromIndex];
	}

	onDragDoctorEnd = (e: any) => {
		if (e.toData.length === 0) {
			const el = document.getElementById('doctor-dragging-container');
			el.classList.remove('dragging-effect');
			const doctorExists = this.dsClinicDoctors.find((x: any) => x.id === e.itemData.id);
			if (doctorExists) {
				notify('Doctorul este deja asociat specializării selectate', 'warning', 3000);
				return;
			}
			this.dsClinicDoctors.push(e.itemData);
			const selectedSpec = this.specList.selectedItemKeys[0];
			this.ds.setDocSpec(e.itemData.id, selectedSpec, this.partyData.id);
		}
	}

	onDragUserEnd = (e: any) => {
		if (e.toData.length === 0) {
			const el = document.getElementById('user-dragging-container');
			el.classList.remove('dragging-effect');
			const userExists = this.dsClinicUsers.find((x: any) => x.id === e.itemData.id);
			if (userExists) {
				notify('Utilizatorul este deja asociat rolului selectat.', 'warning', 3000);
				return;
			}
			const selectedRole = this.roleList.selectedItemKeys[0];
			if (selectedRole === this.doctorRoleId) {
				// are mereu butonul de Adauga specializare
				if (this.dsSpecs.length === 1) {
					this.userSpec.showPopup(this.partyData, true).then((res: any) => {
						this.userSpec.createDefault();
						if (res && res.isSaving) {
							for (let i = 0; i < res.newSpecs.length; i++) {
								this.ds.setDocSpec(e.itemData.id, res.newSpecs[i], this.partyData.id).then(() => {
									if (res.newSpecs.length === i + 1) {
										this.dsClinicUsers.push(e.itemData);
										this.ds.setUserParty(e.itemData.id, this.partyData.id, selectedRole).then(() => {
											this.initPartySpecs();
											this.initClinicDoctors(this.partyData.id, this.doctorRoleId);
										});
									}
								});
							}
						}
					});
				} else {
					this.userSpec.initialClinicSpecs = [];
					for (let i = 1; i < this.dsSpecs.length; i++) {
						this.userSpec.initialClinicSpecs.push(this.dsSpecs[i].id);
					}
					this.partyData.isSpecUserInsert = true;
					this.partyData.userId = e.itemData.id;
					this.userSpec.showPopup(this.partyData, true).then((res: any) => {
						this.userSpec.createDefault();
						this.partyData.isSpecUserInsert = false;
						if (res && res.isSaving) {
							this.dsClinicUsers.push(e.itemData);
							this.ds.setUserParty(e.itemData.id, this.partyData.id, selectedRole).then(() => {
								this.initPartySpecs();
								this.initClinicDoctors(this.partyData.id, this.doctorRoleId);
								this.populateSpecUserDropArea();
							});
						}
					});
				}
			} else {
				this.dsClinicUsers.push(e.itemData);
				this.ds.setUserParty(e.itemData.id, this.partyData.id, selectedRole);
			}
		}
	}

	onDragDoctorMove = (e: any) => {
		const el = document.getElementById('doctor-dragging-container');
		if (e.toData.length === 0) {
			el.classList.add('dragging-effect');
		} else {
			el.classList.remove('dragging-effect');
		}
	}

	onDragUserMove = (e: any) => {
		const el = document.getElementById('user-dragging-container');
		if (e.toData.length === 0) {
			el.classList.add('dragging-effect');
		} else {
			el.classList.remove('dragging-effect');
		}
	}

	cancelClinicDetails() {
		this.clinicReadOnlyCondition = !this.clinicReadOnlyCondition;
		this.partyData = JSON.parse(JSON.stringify(this.temporarySaveData));
		this.temporarySaveData = {};
	}

	toggleClinicDetails(ev: any) {
		if (this.clinicReadOnlyCondition) {
			this.temporarySaveData = JSON.parse(JSON.stringify(this.partyData));
			this.clinicReadOnlyCondition = !this.clinicReadOnlyCondition;
		} else {
			const validation = ev.validationGroup.validate();
			if (validation.isValid) {
				this.ds.addEditParty(this.partyData, this.partyData.id).then((result: any) => {
					if (result.key) {
						notify('Datele clinicii au fost modificate cu succes.', 'success', 3000);
						this.initParty(this.partyData.id);
						if (this.ds.currentClinic.id === this.partyData.id) {
							this.ds.currentClinic.name = this.partyData.name;
						} else {
							const editedParty = this.ds.dsClinic.find((x: any) => x.id === this.partyData.id);
							if (editedParty) { editedParty.name = this.partyData.name; }
						}
					} else {
						this.initParty(result.id);
						this.ds.dsClinic.push({ id: result.id, text: this.partyData.name });
						this.ds.setUserParty(this.ds.userLogin.userId, result.id, this.adminRoleId);
						notify('Clinica a fost adaugată cu succes.', 'success', 3000);
					}
					this.clinicReadOnlyCondition = !this.clinicReadOnlyCondition;
				});
			}
		}
	}

	cancelCompanyDetails() {
		this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
		this.initSocietyData();
	}

	toggleCompanyDetails(ev: any) {
		if (this.companyReadOnlyCondition) {
			this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
		} else {
			const validation = ev.validationGroup.validate();
			if (validation.isValid) {
				this.ds.getEnumValueAttr(this.societyData.typeId).then((en: any) => {
					const enumValue = en.data;
					const attrFields = this.getRegisterAttrFields(this.societyData);
					const attrKeys = Object.keys(attrFields);
					this.ds.getRegisterModelAttr(this.societyData.id).then((rm: any) => {
						const requestArray = [];
						for (let i = 0; i < attrKeys.length; i++) {
							let item: any = {};
							if (rm && rm.data && rm.data.length) {
								// de facut update
								item = rm.data.find((a: any) => {
									return enumValue.find((b: any) => {
										return a.attrId === b.id && b.attrName === attrKeys[i];
									});
								});
								if (item && item.attrValue !== attrFields[attrKeys[i]]) {
									item.attrValue = attrFields[attrKeys[i]];
									requestArray.push(item);
								}
							}
							if (!item && attrFields[attrKeys[i]]) {
								const enumItem = enumValue.find((x: any) => x.attrName === attrKeys[i]);
								const obj = {
									attrValue: attrFields[attrKeys[i]],
									attrId: enumItem.id,
									parentId: this.societyData.id,
								};
								requestArray.push(obj);
							}
						}
						if (requestArray.length) {
							for (let j = 0; j < requestArray.length; j++) {
								this.ds.addEditRegisterRequestAttr(requestArray[j], requestArray[j].id || null).then(() => {
									// tslint:disable-next-line: max-line-length
									// const isCompanyField = enumValue.find((x: any) => x.id === requestArray[j].attrId && x.attrName === 'Companie');
									// if (isCompanyField) {
									//     this.ds.editCompanyName(requestArray[j].attrValue, this.societyData.id);
									//     const currentCompany = JSON.parse(sessionStorage.getItem('currentCompanyMCA'));
									//     currentCompany.name = requestArray[j].attrValue;
									//     sessionStorage.setItem('currentCompanyMCA', JSON.stringify(currentCompany));
									// }
									if (requestArray.length === j + 1) {
										notify('Datele societății au fost modificate cu succes.', 'success', 3000);
										this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
									}
								}, error => {
									notify('Eroare la procesare încercați mai târziu.', 'error', 3000);
									this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
								});
							}
						} else {
							this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
						}
					}, error => {
						notify('Eroare la procesare încercați mai târziu.', 'error', 3000);
						this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
					});
				}, error => {
					notify('Eroare la procesare încercați mai târziu.', 'error', 3000);
					this.companyReadOnlyCondition = !this.companyReadOnlyCondition;
				});
			}
		}
	}

	addLogo(replace = false) {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.addEventListener('change', (e) => {
			if (replace) {
				this.ds.deleteInitialLogo().then(() => {
					this.ds.uploadLogo(input.files[0]).then(() => {
						notify('Logo adaugat cu succes.', 'success', 3000);
						this.ds.initLogo(true);
					});
				});
			} else {
				this.ds.uploadLogo(input.files[0]).then(() => {
					notify('Logo adaugat cu succes.', 'success', 3000);
					this.ds.initLogo(true);
				});
			}

		}, false);
		input.click();
	}

	onTabChanged(ev: any) {
		if (ev.addedItems && ev.addedItems.length) {
			switch (ev.addedItems[0].title) {
				// switch in caz ca se mai pun conditii
				case 'Drepturi utilizatori clinică':
					if (this.roleList && this.roleList.selectedItemKeys && this.roleList.selectedItemKeys.length === 0) {
						this.roleList.instance.option('selectedItemKeys', this.adminRoleId);
					}
					break;
				case 'Specializări clinică':
					if (this.specList && this.specList.selectedItemKeys && this.specList.selectedItemKeys.length === 0) {
						if (this.dsSpecs && this.dsSpecs.length > 1) {
							this.specList.instance.option('selectedItemKeys', this.dsSpecs[1].id);
						}
					}
					break;
			}
		}
	}

	onNewOperatingRoom(ev: any) {
		ev.data.partyId = this.partyData.id;
	}

	onOperatingRoomClick(data: any) {
		this.selectedOperatingRoom = data.id;
	}

	toggleSelectedDoctor(sItem: any, dc: any) {
		return dc.id !== (sItem.selectedItemKeys && sItem.selectedItemKeys.length && sItem.selectedItemKeys[0].id);
	}

}
