import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../service/data.service';
import notify from 'devextreme/ui/notify';
import { EditUserSpecializationService } from './userSpecialization.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
	selector: 'app-user-spec',
	templateUrl: './userSpecialization.component.html',
	styleUrls: ['./userSpecialization.component.scss']
})
export class EditUserSpecializationComponent implements OnInit {
	dsSpecs: CustomStore;

	constructor(public ds: DataService, public edit: EditUserSpecializationService) {
	}

	ngOnInit() {
		this.dsSpecs = this.ds.getSpecializations();
	}

	onClosePopup = () => {
		this.edit.closePopup();
	}
}
