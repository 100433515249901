import { Component, OnInit, AfterViewInit, OnDestroy, NgModule } from '@angular/core';
import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../../service/data.service';
import { ScreenService } from '../../service/screen.service';
import { NavigationMenuModule } from '../navigation-menu/navigation-menu.component';
import { DxDrawerModule, DxScrollViewModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '../header/header.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { ChatAdapter, NgChatModule } from 'ng-chat';
import { SignalRAdapter } from '../../../app/service/signalr-adapter';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-nav-toolbar',
	templateUrl: './nav-toolbar.component.html',
	styleUrls: ['./nav-toolbar.component.scss']
})
export class NavToolbarComponent implements OnInit, AfterViewInit, OnDestroy {

	selectedRoute = '';

	menuOpened: boolean;
	temporaryMenuOpened = false;
	companyName: string;

	menuMode = 'shrink';
	menuRevealMode = 'expand';
	minMenuSize = 0;
	shaderEnabled = false;
	menuItems: any[];


	// public chatAdapter: SignalRAdapter;
	// public isChatVisible: boolean;
	// public isChatDisabled: boolean;
	// public chatName: string;
	// public chatUserId: string;
	// public chatStartedSubscription: Subscription;

	constructor(private screen: ScreenService, private router: Router, public ds: DataService, public http: HttpClient,
		public auth: iBisAuthService) {
		// this.chatAdapter = new SignalRAdapter(ds);
		// const currentUser = JSON.parse(sessionStorage.getItem('currentUser' + this.ds.appName));
		// this.chatName = 'MCA Chat';
		// this.chatUserId = currentUser ? currentUser.userId : 'cosmin.cojocaru@dohu.ro';
		// this.isChatVisible = true;
		// this.isChatDisabled = true;
		// make chat disabled until signalR is not started (acts like visible)
		// this.chatStartedSubscription = this.chatAdapter.ChatSubject.subscribe((response: any) => {
		// 	if (response) {
		// 		this.isChatDisabled = false;
		// 	}
		// });
	}

	ngOnInit() {
		this.menuOpened = this.ds.userLogin && this.ds.userLogin.userType === 1 ? this.screen.sizes['screen-large'] : false;

		this.router.events.subscribe(val => {
			if (val instanceof NavigationEnd) {
				this.selectedRoute = val.urlAfterRedirects.split('?')[0];
				this.updateDrawer();
			}
		});
		this.screen.changed.subscribe(() => this.updateDrawer());
		setTimeout(() => {
			this.updateDrawer();
		}, 0);
	}
	ngAfterViewInit() {
	}

	ngOnDestroy(): void {
		// const myAdapter = (this.chatAdapter as SignalRAdapter);
		// if (myAdapter != null) {
		//   myAdapter.disconnect();
		//   this.chatAdapter = null;
		// }
	}

	updateDrawer() {
		const isXSmall = this.screen.sizes['screen-x-small'];
		const isLarge = this.screen.sizes['screen-large'];

		this.menuMode = isLarge ? 'shrink' : 'overlap';
		this.menuRevealMode = 'expand';
		this.minMenuSize = this.ds.userLogin.userType === 1 ? 60 : 0;
		// this.menuOpened = this.menuOpened;
		this.menuOpened = false;
		// this.menuRevealMode = isXSmall ? 'slide' : 'expand';
		// this.minMenuSize = isXSmall ? 0 : 60;
		this.shaderEnabled = !isLarge;
	}

	get hideMenuAfterNavigation() {
		return this.menuMode === 'overlap' || this.temporaryMenuOpened;
	}

	get showMenuAfterClick() {
		return !this.menuOpened;
	}

	onMessagesSeen(ev: any) {
		// this.chatAdapter.onMessagesSeen(ev);
	}

	navigationChanged(event) {
		const path = event.itemData.path;
		const pointerEvent = event.event;

		if (path && this.menuOpened) {
			if (event.node.selected) {
				pointerEvent.preventDefault();
			} else {
				this.router.navigate([path]);
			}

			if (this.hideMenuAfterNavigation) {
				this.temporaryMenuOpened = false;
				this.menuOpened = false;
				pointerEvent.stopPropagation();
			}
		} else if (this.menuOpened && !event.itemData.items) {
			this.router.navigate([event.itemData.infoPath]);
		} else {
			pointerEvent.preventDefault();
		}
	}

	navigationClick() {
		if (this.showMenuAfterClick) {
			this.temporaryMenuOpened = true;
			this.menuOpened = true;
		}
	}
}

@NgModule({
	imports: [NavigationMenuModule, HeaderModule, DxDrawerModule, CommonModule, RouterModule, DxScrollViewModule, NgChatModule],
	exports: [NavToolbarComponent],
	declarations: [NavToolbarComponent, BreadcrumbComponent]
})
export class NavToolbarModule { }

