import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../service/data.service';
import { EditPatientHistoryService } from './patient-history.service';
import { DxListComponent, DxDataGridComponent } from 'devextreme-angular';
import { environment } from '../../../environments/environment';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-history-patient',
    templateUrl: './patient-history.component.html'
})
export class EditPatientHistoryComponent implements OnInit {


    dsHistProcedures: any;
    dsHistDiagnostics: any;
    dsPatientHistory: CustomStore;

    // de mutat pe ui service
    pListHistoryGridHeight: any;
    pListHistoryGridWidth: any;

    previewHeight: any;
    previewWidth: any;
    previewTarget: any;
    selectedPatient: any;
    isPreviewVisible: boolean;
    isHistoryDetailsVisible: boolean;

    previewFile: SafeResourceUrl;

    @ViewChild('pList') pList: DxListComponent;
    @ViewChild('patientHistory') patientHistory: DxDataGridComponent;
    @ViewChild('historyListDetail') historyListDetail: DxDataGridComponent;

    constructor(public ds: DataService, public edit: EditPatientHistoryService,
        public httpClient: HttpClient, private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.isHistoryDetailsVisible = false;
        this.isPreviewVisible = false;
        this.changeHistoryGridSize();
    }

    onPatientChange(event: any) {
        if (event && event.name === 'items' && event.value) {
            this.pList.instance.unselectAll();
            this.isHistoryDetailsVisible = false;
            this.changeHistoryGridSize();
            this.selectedPatient = null;
            if (event.value.length === 1) {
                this.initPatientHistoryGrid(event.value[0].id);
                this.pList.selectedItemKeys = [event.value[0].id];
            } else {
                this.patientHistory.instance.option('dataSource', []);
            }
        }
    }

    onHistoryPatientSelChange(event: any) {
        this.isHistoryDetailsVisible = false;
        this.changeHistoryGridSize();
        this.selectedPatient = null;
        this.initPatientHistoryGrid(event.itemData.id);
    }

    initPatientHistoryGrid(patientId: string) {
        this.dsPatientHistory = this.ds.getAppointmentsForPatient(patientId);
        this.ds.loadPatientHistDiagnostics(patientId).then((result: any) => {
            this.dsHistDiagnostics = result.data;
        });
        this.ds.loadPatientHistProcedures(patientId).then((result: any) => {
            this.dsHistProcedures = result.data;
        });
    }

    changeHistoryGridSize() {
        this.pListHistoryGridHeight = 'calc(100% - 30px)';
        this.pListHistoryGridWidth = 'calc(70vw - 35px)';
    }

    uploadFileDirectly(column: any) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '.*');
        input.addEventListener('change', (e) => {
            this.ds.uploadFile(input.files[0], column.data, true).then(() => {
                notify('Fisier adaugat cu succes.', 'success', 3000);
                if (this.historyListDetail) {
                    this.historyListDetail.instance.option('dataSource', this.ds.getFileInfo(column.data.ap_id));
                }
                this.initPatientHistoryGrid(column.data.ap_patientId);
            });
        }, false);
        input.click();
    }

    downloadFile = (file: any) => {
        const apiUrl = environment.saasServer + 'Download/' + file.row.data.id;
        this.ds.getFile(apiUrl);
    }

    openAttachedFiles(data: any) {
        let previousElement: any;
        if (this.selectedPatient) {
            previousElement = document.getElementById(this.selectedPatient);
            (<HTMLImageElement>previousElement).src = '../assets/img/outlined-info.svg';
        }
        if (this.selectedPatient === data.data.ap_id) {
            this.isHistoryDetailsVisible = false;
            this.changeHistoryGridSize();
            this.selectedPatient = null;
        } else {
            this.selectedRow(data);
        }
    }

    setSrc(row: any) {
        if (this.selectedPatient === row.data.ap_id) {
            return '../assets/img/filled-info.svg';
        } else {
            return '../assets/img/outlined-info.svg'
        }
    }

    showPreview(file: any) {
        this.previewTarget = '#preview' + file.rowIndex;
        const format = file.data.name.split('.').reverse();
        const apiUrl = environment.saasServer + 'Download/' + file.data.id;
        let headers = new HttpHeaders();
        for (const key in EntityStoreOptions.Headers) {
            if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
                headers = headers.append(key, EntityStoreOptions.Headers[key]);
            }
        }
        this.httpClient.get(apiUrl, { responseType: 'blob', observe: 'response' as 'body', headers: headers }).subscribe((res: any) => {
            const header = res.headers.get('Content-Disposition');
            let fileName;
            if (header) {
                const startIndex = header.indexOf('filename=') + 9;
                const endIndex = header.length;
                fileName = header.substring(startIndex, endIndex);
            } else {
                notify('Descărcarea a eșuat', 'error', 3000);
                return;
            }

            let blob: any, fileUrl: any;
            switch (format[0]) {
                case 'txt':
                    blob = new Blob([res.body], { type: 'text/plain' });
                    fileUrl = URL.createObjectURL(blob);
                    setTimeout(() => {
                        this.resizeIframe('document');
                    }, 0);
                    break;
                case 'docx': case 'doc': case 'rtf':
                    // tslint:disable-next-line:max-line-length
                    // this.previewFile = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/viewer?url=' + fileUrl + '&embedded=true');
                    blob = new Blob([res.body], { type: 'text/plain' });
                    fileUrl = URL.createObjectURL(blob);
                    break;
                case 'pdf':
                    setTimeout(() => {
                        this.resizeIframe('document');
                    }, 0);
                    blob = new Blob([res.body], { type: 'application/pdf' });
                    fileUrl = URL.createObjectURL(blob);
                    break;
                default:
                    setTimeout(() => {
                        this.resizeIframe('img');
                    }, 500);
                    blob = new Blob([res.body], { type: 'image/png' });
                    fileUrl = URL.createObjectURL(blob);
            }
            this.isPreviewVisible = true;
            this.previewFile = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
        });
    }

    PatientDiagList(ap_id: any) {
        if (this.dsHistDiagnostics) {
            const ds = this.dsHistDiagnostics.filter(obj => obj.ad_appointmentId === ap_id);
            return ds;
        }
    }

    PatientProcList(ap_id: any) {
        if (this.dsHistProcedures) {
            const ds = this.dsHistProcedures.filter(obj => obj.ap_appointmentId === ap_id);
            return ds;
        }
    }

    resizeIframe(type: string) {
        const ifrm = document.getElementById('iframe') as HTMLIFrameElement;
        switch (type) {
            case 'document':
                ifrm.style.height = '480px';
                ifrm.style.width = '480px';
                this.previewHeight = '480px';
                this.previewWidth = '480px';
                break;
            case 'img':
                const doc = ifrm.contentDocument ? ifrm.contentDocument : ifrm.contentWindow.document;
                ifrm.style.visibility = 'hidden';
                ifrm.style.height = '10px';
                ifrm.style.width = '10px';
                ifrm.style.height = this.getDocHeight(doc) + 10 + 'px';
                ifrm.style.width = this.getDocWidth(doc) + 10 + 'px';
                this.previewHeight = this.getDocHeight(doc) + 10 + 'px';
                this.previewWidth = this.getDocWidth(doc) + 10 + 'px';
                ifrm.style.visibility = 'visible';
                break;
        }
    }

    getDocHeight(doc: any) {
        doc = doc || document;
        const body = doc.body, html = doc.documentElement;
        const height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
        return height;
    }

    getDocWidth(doc: any) {
        doc = doc || document;
        const body = doc.body, html = doc.documentElement;
        const width = Math.max(body.scrollWidth, body.offsetWidth,
            html.clientWidth, html.scrollWidth, html.offsetWidth);
        return width;
    }

    onCellPrepared(e: any) {
        // if (e.rowType === 'data' && e.columnIndex === 1) {
        //     if (e.data.hasFile === false) {
        //         e.cellElement.children[0].classList.add('hideInfo');
        //     }
        // }
        if (e.rowType === 'data' && e.column.command === 'expand') {
            if (!e.data.ap_observation && !e.data.ap_notes) {
                e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
            }
        }
    }

    onPatientHistoryClick(e: any) {
        if (e.rowType === 'data') {
            if (e.cellElement.classList[0] === 'dx-command-expand' && !e.data.ap_observation && !e.data.ap_notes) {
                e.component.collapseAll(-1);
            }
        }
    }

    selectedRow(data: any) {
        this.isHistoryDetailsVisible = true;
        this.pListHistoryGridHeight = 'calc(50% - 20px)';

        const element = document.getElementById(data.data.ap_id);
        this.selectedPatient = data.data.ap_id;
        (<HTMLImageElement>element).src = '../assets/img/filled-info.svg';
        this.historyListDetail.instance.option('dataSource', this.ds.getFileInfo(data.data.ap_id));
    }

    cancelClick() {
        this.isHistoryDetailsVisible = false;
        this.changeHistoryGridSize();
        if (this.patientHistory) {
            this.patientHistory.instance.option('dataSource', []);
        }
        if (this.pList) {
            this.pList.instance.option('searchValue', null);
        }
        this.edit.closePopup();
    }
}
