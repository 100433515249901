import { Component, OnInit } from '@angular/core';
import { ConsultationService } from './consultation.service';
import notify from 'devextreme/ui/notify';
import { DataService } from '../../service/data.service';
import { Guid } from 'guid-typescript';
import DataSource from 'devextreme/data/data_source';
import * as $ from 'jquery';
import NumberBox from 'devextreme/ui/number_box';
import { environment } from '../../../environments/environment';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})

export class ConsultationComponent implements OnInit {

  dsDiagnostics: any;
  dsDoctors: any;
  timeoutSearch: any;
  currentProcs: any = [];
  cancelSelection = false;
  isPopupFullscreen = false;

  onCustomItemCreatingDiagnostic: any;
  saveConsultationClick: any;
  cancelConsultationClick: any;
  finishConsultationClick: any;

  addFileOptions = {
    icon: 'file',
    onClick: (e: any) => {
      this.createUploadEvent();
    },
    text: 'Adaugă fișier',
    elementAttr: { class: 'custom-button' }
  };


  constructor(public ds: DataService, public edit: ConsultationService, public httpClient: HttpClient, private sanitizer: DomSanitizer) {
    this.onCustomItemCreatingDiagnostic = this.onCustomItemCreatingDiagnosticEv.bind(this);
    this.saveConsultationClick = this.saveConsultationEv.bind(this);
    this.finishConsultationClick = this.finishConsultationEv.bind(this);
    this.cancelConsultationClick = this.cancelConsultationEv.bind(this);
  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.initData();
  }

  toggleFullScreen() {
    this.isPopupFullscreen = !this.isPopupFullscreen;
  }

  calculateProceduresList() {
    return this.isPopupFullscreen ? 'calc(100vh - 630px)' : 'calc(80vh - 630px)';
  }

  setVisibility(data: any) {
    if (this.edit.model.procedure.length) {
      const exists = this.edit.model.procedure.find((id: any) => id === data.id);
      if (exists) {
        return true;
      }
    }
    return false;
  }


  saveConsultationEv(event: any) {
    if (this.edit.model.procedure.length !== 0) {
      this.edit.onSave(event);
      this.ds.savePatientFiles(this.edit.model, { files: this.edit.files, initialFiles: this.edit.initialFiles }).then(() => {
        this.ds.getFileInfo(this.edit.model.id).load().then((res: any) => {
          if (res && res.data) {
            this.edit.files = res.data;
            this.edit.initialFiles = res.data;
          }
        });
      });
    } else {
      notify('Selectați cel puțin o procedură!', 'warning', 3000);
    }
  }

  finishConsultationEv(event: any) {
    if (this.edit.model.procedure.length !== 0) {
      this.ds.savePatientFiles(this.edit.model, { files: this.edit.files, initialFiles: this.edit.initialFiles }).then(() => {
        this.edit.finishConsultation();
      });
    } else {
      notify('Selectați cel puțin o procedură!', 'warning', 3000);
    }
  }

  cancelConsultationEv(event: any) {
    this.edit.closePopup();
  }

  initData() {
    this.ds.loadDiagnostics().then((result: any) => {
      result.data.forEach((element: any) => {
        element.customName = element.code + ' - ' + element.name;
      });
      this.dsDiagnostics = new DataSource({
        store: result.data,
        paginate: true,
        pageSize: 50
      });
    }, error => notify(error, 'error', 3000));

    this.ds.loadClinicDoctors().then((result: any) => {
      this.dsDoctors = result.data;
    });
  }

  filesTagBoxInit(event: any) {
    this.edit.filesTagBoxInstance = event.component;
  }

  onProceduresListInit(event: any) {
    this.edit.proceduresListInstance = event.component;
  }

  onCustomItemCreatingDiagnosticEv(e: any) {
    if (e.text === null || e.text.trim() === '') {
      return;
    }
    const ds = this.dsDiagnostics.store()._array;
    for (const t of ds) {
      if (t === e.text.trim()) {
        e.customItem = t;
        e.aircraftType = t;
        return;
      }
    }

    e.customItem = { id: Guid.create().toString(), name: e.text.trim(), customName: e.text.trim(), isCustom: true };
    this.ds.setNewCustomItem('Diagnostic', e.customItem);
    this.dsDiagnostics.store().insert(e.customItem);
    this.dsDiagnostics.reload();
    this.edit.model.diagnostic = e.customItem.id;
  }

  PatientDiagList(ap_id: string) {
    if (this.edit.dsHistDiagnostics) {
      const ds = this.edit.dsHistDiagnostics.filter((obj: any) => obj.ad_appointmentId === ap_id);
      return ds;
    }
  }

  PatientProcList(ap_id: string) {
    if (this.edit.dsHistProcedures) {
      const ds = this.edit.dsHistProcedures.filter((obj: any) => obj.ap_appointmentId === ap_id);
      return ds;
    }
  }

  onProcedureSelCh(ev: any) {
    if (this.cancelSelection) {
      this.cancelSelection = false;
      return;
    }
    this.currentProcs = ev.component.option('selectedItems');
    if (ev.removedItems.length && ev.removedItems[0].hasQuantity) {
      this.currentProcs.push(ev.removedItems[0]);
    }
    if (ev.addedItems.length && (ev.addedItems[0].hasQuantity)) {
      const el = document.getElementById(ev.addedItems[0].id);
      const instance = NumberBox.getInstance(el) as NumberBox;
      if (instance) {
        setTimeout(() => {
          instance.focus();
        }, 300);
      }
    }
  }

  onItemClick(ev: any) {
    if (ev.itemData.hasQuantity === true) {
      const uniqueIds = this.currentProcs.map((p: any) => p.id)
        .filter((id: any, index: any, arr: any) => arr.indexOf(id) === index);
      ev.component.option('selectedItemKeys', uniqueIds);
      this.cancelSelection = true;
    }
  }

  onQuantityInit(ev: any, id: string) {
    if (this.edit.model && this.edit.model.quantities.length) {
      const val = this.edit.model.quantities.find((x: any) => x.id === id);
      if (val && val.quantity) {
        ev.component.option('value', val.quantity);
      }
    }
  }

  onQuantityChanged(ev: any, data: any) {
    if (ev.value) {
      const val = this.edit.model.quantities.find((x: any) => x.id === data.id);
      if (val) {
        val.quantity = ev.value;
      } else {
        this.edit.model.quantities.push({ quantity: ev.value, id: data.id });
      }
    }
  }

  onFileDelete(event) {
    if (event.previousValue && event.value) {
      if (event.previousValue.length > event.value.length) {
        this.edit.files = event.value;
        this.edit.filesTagBoxInstance.repaint();
      }
    }
  }

  createUploadEvent() {
    const input: any = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.*');
    input.addEventListener('change', (e) => {
      this.edit.files.push(input.files[0]);
      input.files[0].id = null; // for filter
      this.edit.filesTagBoxInstance.option('value', this.edit.files);
      this.edit.filesTagBoxInstance.repaint();
    }, false);
    input.click();
  }

  // master detail prevention
  onCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.command === 'expand') {
      if (!e.data.ap_observation && !e.data.ap_notes) {
        e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
      }
    }
  }

  onPatientHistoryClick(e: any) {
    if (e.rowType === 'data') {
      if (e.cellElement.classList[0] === 'dx-command-expand' && !e.data.ap_observation && !e.data.ap_notes) {
        e.component.collapseAll(-1);
      }
    }
  }

  onTabChanged(ev: any) {
    if (ev.name === 'selectedItems') {
      // tslint:disable-next-line: deprecation
      $('document').ready(() => {
        // tslint:disable-next-line: deprecation
        $('.consultationTabs .consultationTabItem').mouseover(
          function () {
            $('.consultationTabItem').attr('title', '');
            // tslint:disable-next-line: deprecation
          }).mouseout(
            function () {
              $('.consultationTabItem').attr('title', '');
            });
      });
    }
  }

  // files

  downloadFile(file: any) {
    const apiUrl = environment.saasServer + 'Download/' + file.selectedItems[0].id;
    this.ds.getFile(apiUrl);
  }

  onFileClick(ev: any) {
    this.showPreview(ev.itemData);
  }

  showPreview(file: any) {
    const format = file.name.split('.').reverse();
    const apiUrl = environment.saasServer + 'Download/' + file.id;
    let headers = new HttpHeaders();
    for (const key in EntityStoreOptions.Headers) {
      if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
        headers = headers.append(key, EntityStoreOptions.Headers[key]);
      }
    }
    this.httpClient.get(apiUrl, { responseType: 'blob', observe: 'response' as 'body', headers: headers }).subscribe((res: any) => {
      const header = res.headers.get('Content-Disposition');
      let fileName;
      if (header) {
        const startIndex = header.indexOf('filename=') + 9;
        const endIndex = header.length;
        fileName = header.substring(startIndex, endIndex);
      } else {
        notify('Nu se poate previzualiza fișierul.', 'error', 3000);
        return;
      }

      let blob: any, fileUrl: any;
      switch (format[0]) {
        case 'txt':
          blob = new Blob([res.body], { type: 'text/plain' });
          fileUrl = URL.createObjectURL(blob);
          break;
        case 'docx': case 'doc': case 'rtf':
          // tslint:disable-next-line:max-line-length
          // this.previewFile = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/viewer?url=' + fileUrl + '&embedded=true');
          blob = new Blob([res.body], { type: 'text/plain' });
          fileUrl = URL.createObjectURL(blob);
          break;
        case 'pdf':
          blob = new Blob([res.body], { type: 'application/pdf' });
          fileUrl = URL.createObjectURL(blob);
          break;
        default:
          blob = new Blob([res.body], { type: 'image/png' });
          fileUrl = URL.createObjectURL(blob);
      }
      this.edit.previewFile = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
      this.resizeIframeContent();
    });
  }

  resizeIframeContent() {
    setTimeout(() => {
      const ifrm: any = document.getElementById('iframe') as HTMLIFrameElement;
      if (ifrm && ifrm.contentWindow && ifrm.contentWindow.document && ifrm.contentWindow.document.body) {
        $(ifrm.contentWindow.document.body).children().width('100%');
      }
      const factor = 0.05;
      let scale = 1;
      const html = $(ifrm.contentWindow.document).children()[0];
      html.style.width = '100vw';
      html.style.height = '100vh';
      html.style.overflow = 'hidden';
      const body: any = $(ifrm.contentWindow.document.body)[0];
      body.style.width = '100vw';
      body.style.height = '100vh';
      body.style.overflow = 'scroll';
      const content = $(ifrm.contentWindow.document.body).children()[0];
      content.style.transformOrigin = '0 0';
      // const style = document.createElement('style');
      // style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
      // html.children[0].appendChild(style);

      // mouse move
      let previousX: any, previousY: any;
      body.addEventListener('mousedown', (ev: any) => {
        ev.preventDefault();
        previousX = ev.pageX;
        previousY = ev.pageY;
      });

      body.addEventListener('mousemove', (ev: any) => {
        ev.preventDefault();
        // only do this when the primary mouse button is pressed (event.buttons = 1)
        if (ev.buttons) {
          let dragX = 0;
          let dragY = 0;
          // skip the drag when the x position was not changed
          if (ev.pageX - previousX !== 0) {
            dragX = previousX - ev.pageX;
            previousX = ev.pageX;
          }
          // skip the drag when the y position was not changed
          if (ev.pageY - previousY !== 0) {
            dragY = previousY - ev.pageY;
            previousY = ev.pageY;
          }
          // scrollBy x and y
          if (dragX !== 0 || dragY !== 0) {
            body.scrollBy(dragX, dragY);
          }
        }
      });
      // zoom in - out

      body.addEventListener('wheel', (ev: any) => {
        if (ev.ctrlKey) {
          let delta = ev.delta || ev.wheelDelta;
          if (delta === undefined) {
            delta = ev.originalEvent.detail;
          }
          delta = Math.max(-1, Math.min(1, delta));
          const offset = { x: body.scrollLeft, y: body.scrollTop };
          const fileLocation = { x: ev.pageX + offset.x, y: ev.pageY + offset.y };
          const zoomPoint = { x: fileLocation.x / scale, y: fileLocation.y / scale };
          scale += delta * factor * scale;
          scale = Math.max(0.5, Math.min(4, scale));
          const newZoomPoint = { x: zoomPoint.x * scale, y: zoomPoint.y * scale };
          const scroll = { x: newZoomPoint.x - ev.pageX, y: newZoomPoint.y - ev.pageY };
          // content.style.transformOrigin = scale >= 1 ? '0 0' : 'center';
          content.style.transform = 'scale(' + scale + ',' + scale + ')';
          body.scrollTop = scroll.y;
          body.scrollLeft = scroll.x;
        }
      });
      // prevent scrolling
      content.addEventListener('DOMMouseScroll', this.edit.mouseWheel, false);
      content.addEventListener('mousewheel', this.edit.mouseWheel, false);
      const obj: any = document.body;
      if (obj.addEventListener) {
        obj.addEventListener('DOMMouseScroll', this.edit.mouseWheel, false);
        obj.addEventListener('mousewheel', this.edit.mouseWheel, false);
      }

    }, 100);
  }

}
