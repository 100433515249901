import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';

@Component({
	selector: 'app-specialization',
	templateUrl: './specialization.component.html'
})
export class SpecializationComponent implements OnInit {

	public dsSpecialization: CustomStore;
	constructor(public ds: DataService) { }

	ngOnInit() {
		this.dsSpecialization = this.ds.getSpecializations();
	}

	onSpecializationInserting(ev: any) {
		if (ev.data && ev.data.description) {
			ev.data.code = ev.data.description.substring(0, 3).toUpperCase();
		}
	}

	onSpecializationInit(ev: any) {
		ev.data.isActiv = true;
	}

	onProcedureContentReady(e: any, specId: string) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.ds.getProceduresBySpecId(specId));
		}
	}

	onRowProcInserting(event: any, specId: string) {
		event.data.specializationId = specId;
	}

	onInitNewRowProc(event: any) {
		event.data.hasQuantity = false;
	}
}
