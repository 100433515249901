import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService } from '../../service/data.service';
import { DxSchedulerComponent, DxTextBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { HttpClient } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { EditOperationService } from '../../common/edit-operation/edit-operation.service';
import { CalendarService } from '../calendar/calendar.service';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-operation-room',
    templateUrl: './operation-room.component.html'
})
export class OperationRoomComponent implements OnInit, OnDestroy {

    selectedDate: Date = new Date();
    currentDate: Date = new Date();
    operationalRoomSelected = [];
    timeout: any;

    dsOperationRoom: CustomStore;
    dsOperationRoomList: CustomStore;
    dsResourceBO: CustomStore;
    dsClinicWorkingDays: any = [];

    @ViewChild('scheduler') scheduler: DxSchedulerComponent;
    partySubscription: Subscription;
    listenerSubscription: Subscription;

    constructor(private router: Router, public ds: DataService, public editOperation: EditOperationService,
        public http: HttpClient, public auth: iBisAuthService, public edit: CalendarService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        if (this.ds.userLogin.userType === 1) {
            this.router.navigateByUrl('/admin/company-details');
            return;
        }
        if (!this.ds.currentClinic) {
            this.partySubscription = this.ds.isPartyLoaded.subscribe(() => {
                this.ds.loadClinicDoctors().then((res: any) => {
                    this.dsOperationRoom = this.ds.getOperationAppointments(res.data);
                });
                this.dsOperationRoomList = this.ds.getOperatingRoom();
                this.getOperationRoomById();
                this.initClinicWorkingSchedule();
            });
        } else {
            this.ds.loadClinicDoctors().then((res: any) => {
                this.dsOperationRoom = this.ds.getOperationAppointments(res.data);
            });
            this.dsOperationRoomList = this.ds.getOperatingRoom();
            this.getOperationRoomById();
            this.initClinicWorkingSchedule();
        }

        $(() => {
            this.listenerSubscription = this.ds.listenerSubject.subscribe((res: any) => {
                if (res.listenerType === 'NotifyModel') {
                    if (this.scheduler && this.scheduler.instance && this.scheduler.instance.getDataSource()) {
                        this.scheduler.instance.getDataSource().reload();
                    }
                }
            });
        });
    }

    ngOnDestroy() {
        if (this.partySubscription) {
            this.partySubscription.unsubscribe();
        }
    }

    initClinicWorkingSchedule() {
        this.ds.loadWorkingDays().then((result: any) => {
            this.dsClinicWorkingDays = result.data;
        });
    }

    setDayHour(type: string) {
        const currentDay = this.selectedDate.getDay();
        const clinicSch = this.dsClinicWorkingDays.find((x: any) => x.weekDay === currentDay);
        if (type === 'start') {
            return clinicSch && clinicSch.startTime ? Number(clinicSch.startTime.split(':')[0]) : 10;
        } else {
            return clinicSch && clinicSch.endTime ? Number(clinicSch.endTime.split(':')[0]) : 20;
        }
    }

    preventFormPopupOpening(ev: any) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
    }

    onOptionChanged(event: any) {
        if (event.name === 'currentDate') {
            const date = event.value;
            if (date.getDay() === 0) {
                const direction = event.previousValue.getDay() === 1 ? -1 : 1;
                event.value = new Date(date.getFullYear(), date.getMonth(), date.getDate() + direction, 0, 0, 0);
            }
        }
    }

    getAppointmentData(data: any) {
        const app = data.appointmentData;
        // for Month view
        if (data.event.currentTarget.classList.contains('dx-list-item')) {
            data.cancel = true;
        }
        if (data.cancel) {
            this.openOperationPopup(app);
        }

    }

    onAppointmentDblClick(event: any) {
        event.cancel = true;
        const data = event.appointmentData;
        this.openOperationPopup(data);
    }

    openOperationPopup(data?: any, ev?: any) {
        if (ev) {
            ev.event.preventDefault();
            ev.event.stopPropagation();
        }
        const obj: any = {};
        Object.assign(obj, data);
        obj.appointment = true;
        obj.operatingRoomId = (this.operationalRoomSelected && this.operationalRoomSelected.length === 1) ?
            this.operationalRoomSelected[0] : obj.operatingRoomId;
        this.scheduler.instance.hideAppointmentTooltip();
        this.edit.getById(obj).then((result: any) => {
            this.editOperation.showPopup(result, true).then((res) => {
                this.editOperation.resetAppointmentsList();
            }, err => notify(err, 'error', 3000));
        });
    }

    deleteOperationAppointment(data: any, ev: any) {
        if (ev) {
            ev.event.preventDefault();
            ev.event.stopPropagation();
        }
        confirm('Ești sigur că dorești anularea programării ?', 'Confirm').then(val => {
            if (val) {
                this.scheduler.instance.hideAppointmentTooltip();
                EntityStore.fromQuery(new EntityQuery('Appointment')).remove(data.ap_id).then(() => {
                    notify('Programare ștearsă cu succes', 'success', 3000);
                }, err => notify(err, 'error', 3000));
            }
        });
    }

    onCellClick(event: any) {
        if (!this.timeout) {
            this.timeout = setTimeout(() => {
                this.timeout = null;
            }, 500);
        } else {
            this.openOperationPopup(event.cellData);
        }
        event.cancel = true;
    }

    getOperationRoomById() {
        this.ds.getOperatingRoomById(this.operationalRoomSelected).load().then((result: any) => {
                result.data.forEach((element: any) => {
                    element.text = element.name;
                });
            this.dsResourceBO = result.data;
            this.scheduler.instance.repaint();
        });
    }
}


