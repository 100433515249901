import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../app/service/data.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-form',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FormComponent implements OnInit {

    constructor(public ds: DataService, public router: Router) {
    }

    ngOnInit() {
    }

    goToForm(link: string) {
        this.router.navigateByUrl('/form' + link);
    }

    hideSelection() {
        const link = this.router.url.split('/');
        return link.length === 2;
    }
}
