import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../../app/service/data.service';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-working-schedule',
    templateUrl: './working-schedule.component.html'
})

export class WorkingScheduleComponent implements OnInit {

    @Input() schedulerSubject: Subject<any> = new Subject<any>();
    @Input() schedulerIndex: any;
    userId: string;
    partyId: string;

    schedulerSubscription: Subscription;
    schedulerComponent: any;

    constructor(public ds: DataService) { }

    ngOnInit() {
        this.schedulerSubscription = this.schedulerSubject.subscribe((res: any) => {
            if (res && res.index === this.schedulerIndex) {
                this.userId = res.userId;
                this.partyId = res.partyId;
                this.ds.getWorkingDays(res.partyId, res.userId || null).then((r: any) => {
                    this.schedulerComponent.option('dataSource', this.createSchedule(r.data));
                });
            }
        });
    }

    onScheduleInit(ev: any) {
        this.schedulerComponent = ev.component;
    }

    createSchedule(schedule: any) {
        const obj = {};
        for (let i = 0; i < 7; i++) {
            const fromHourKey = 'startTime' + i;
            const toHourKey = 'endTime' + i;
            const tdy = new Date();
            const dayExists = schedule.find((x: any) => x.weekDay === i);
            const startTimeHours = dayExists && dayExists.startTime ? Number(dayExists.startTime.split(':')[0]) : 0;
            const startTimeMinutes = dayExists && dayExists.startTime ? Number(dayExists.startTime.split(':')[1]) : 0;
            const startTimeValue = new Date(tdy.getFullYear(), tdy.getMonth() + 1, tdy.getDate(), startTimeHours, startTimeMinutes, 0);
            obj[fromHourKey] = startTimeValue;

            const endTimeHours = dayExists && dayExists.endTime ? Number(dayExists.endTime.split(':')[0]) : 0;
            const endTimeMinutes = dayExists && dayExists.endTime ? Number(dayExists.endTime.split(':')[1]) : 0;
            const endTimeValue = new Date(tdy.getFullYear(), tdy.getMonth() + 1, tdy.getDate(), endTimeHours, endTimeMinutes, 0);
            obj[toHourKey] = endTimeValue;
        }
        return [obj];
    }

    onRowUpdating(ev: any) {
        const key = Object.keys(ev.newData)[0],
            date: any = Object.values(ev.newData)[0],
            day = Number(key.substr(key.length - 1)),
            keyToUpdate = key.substr(0, key.length - 1);
        const obj = { weekDay: day, partyId: this.partyId, userId: this.userId };
        const value = this.ds.returnTime(date);
        obj[keyToUpdate] = value;
        const q = new EntityQuery('WorkingSchedule').eq('partyId', this.partyId).eq('weekDay', day);
        if (this.userId) {
            q.eq('userId', this.userId);
        }
        EntityStore.fromQuery(q).single().then((result: any) => {
            this.ds.addEditWorkingSchedule(obj, result);
        });
    }

    onDoctorRowValidating(ev: any) {
        if (this.userId) {
            const key = Object.keys(ev.newData)[0],
                date: any = Object.values(ev.newData)[0],
                day = Number(key.substr(key.length - 1)),
                keyToUpdate = key.substr(0, key.length - 1);
            const obj = { weekDay: day, partyId: this.partyId };
            const value = this.ds.returnTime(date);
            obj[keyToUpdate] = value;
            ev.promise = this.ds.getClinicWorkingSchedule(this.partyId, day).then((wDay: any) => {
                if (wDay && wDay[keyToUpdate]) {
                    ev.errorText = 'Ora se afla in afara programului clinicii (' + wDay.startTime + ' - ' + wDay.endTime + ')';
                    if (keyToUpdate === 'startTime') {
                        ev.isValid = wDay[keyToUpdate] <= obj[keyToUpdate];
                    } else if (keyToUpdate === 'endTime') {
                        ev.isValid = wDay[keyToUpdate] >= obj[keyToUpdate];
                    }
                } else {
                    ev.isValid = true;
                }

            });
        }
    }
}
