import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';


@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

    dsMaintenance: CustomStore;
    constructor(public ds: DataService) { }

    ngOnInit() {
        this.dsMaintenance = this.ds.getMaintenance();
    }


    onMaitenanceInserting(ev: any) {
        ev.data.partyId = this.ds.currentClinic.id;
    }
}
