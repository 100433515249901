import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DataService } from '../../../../app/service/data.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import TextBox from 'devextreme/ui/text_box';

@Component({
    selector: 'app-cds-form',
    templateUrl: './cds-form.component.html',
    styleUrls: ['./cds-form.component.scss']
})
export class CdsFormComponent implements OnInit, AfterViewInit {

    formData: any = {};

    dsRoleType: any;
    dsDefault: any;
    dsDisease: any;
    dsAnesthesis: any;
    dsDental: any;
    dsDentalComplications: any;
    dsAdministrationPath: any;

    cancelSelection: boolean;

    signaturePadOptions: any = {
        'minWidth': 1,
        'maxWidth': 2,
        'canvasWidth': 400,
        'canvasHeight': 150,
        'backgroundColor': '#f0ebf8'
    };

    @ViewChild('signaturePad') signaturePad: SignaturePad;

    constructor(public ds: DataService) {
    }

    ngOnInit() {
        this.dsRoleType = this.ds.static.formRole;
        this.dsDefault = this.ds.static.defaultQuestionSet;
        this.dsDisease = this.ds.static.diseaseTypes;
        this.dsAnesthesis = this.ds.static.anesthesisType;
        this.dsDental = this.ds.static.dentalAnesthesis;
        this.dsDentalComplications = this.ds.static.dentalComplications;
        this.dsAdministrationPath = this.ds.static.administrationPath;
        this.formData = {
            date: new Date(), diseaseSubItems: [], dentalSubItems: [], anesthesisSubItems: [],
            roleData: {}, anticoagulantData: {}, specialTreatmentData: {}
        };
    }

    ngAfterViewInit() {
        this.signaturePad.set('minWidth', 1);
        this.signaturePad.clear();
    }

    onCnpChanged(ev: any) {
        const group = validationEngine.getGroupConfig('cnpValidation');
        const isValid = group.validate().isValid;
        if (isValid) {
            const sex = Number(ev.value.substring(0, 1));
            if (sex === 1 || sex === 3 || sex === 5 || sex === 7) {
                this.formData.gender = 'male';
            } else {
                this.formData.gender = 'female';
            }
        }
    }

    onRoleChanged(ev: any) {
        if (ev.addedItems && ev.addedItems.length) {
            this.formData.roleType = ev.addedItems[0].id;
        }
    }

    onDiseaseChanged(ev: any) {
        // implementare pt salvat datele din textboxes
        if (ev.addedItems && ev.addedItems.length && ev.addedItems[0].hasTextBox) {
            const el = document.getElementById(ev.addedItems[0].id);
            const instance = TextBox.getInstance(el) as TextBox;
            console.log(instance.option('value'));
        }
    }

    sendForm() {
        if (this.formData.diseaseSubItems.length) {
            const arr = [];
            // disease main items
            for (let i = 0; i < this.dsDisease.length; i++) {
                const item = this.dsDisease[i];
                if (item.hasTextBox) {
                    const el = document.getElementById(item.id);
                    const instance = TextBox.getInstance(el) as TextBox;
                    const value = instance.option('value');
                    if (value && value.length) {
                        const obj: any = { diseaseId: item.id, disease: item.text, textBoxVal: value };
                        arr.push(obj);
                    }
                }

            }
            // disease sub items
            for (let i = 0; i < this.formData.diseaseSubItems.length; i++) {
                const el = document.getElementById(this.formData.diseaseSubItems[i].id);
                const instance = TextBox.getInstance(el) as TextBox;
                const value = instance.option('value');
                if (value && value.length) {
                    this.formData.diseaseSubItems[i]['textBoxVal'] = value;
                }
                const itemId = this.formData.diseaseSubItems[i].id.toString(10);
                const groupId = itemId.length === 3 ? Number(itemId[0] + itemId[1]) : Number(itemId[0]);
                if (arr.length) {
                    const exists = arr.find((x: any) => x.diseaseId === groupId);
                    if (exists) {
                        exists.items.push(this.formData.diseaseSubItems[i]);
                        continue;
                    }
                }
                const dItem = this.dsDisease.find((x: any) => x.id === groupId);
                const obj: any = { diseaseId: dItem.id, disease: dItem.text, items: [this.formData.diseaseSubItems[i]] };
                arr.push(obj);
            }
            this.formData.diseaseData = arr;
        }
        this.formData.dentalData = this.getSubItems(this.formData.dentalSubItems);
        this.formData.anesthesisData = this.getSubItems(this.formData.anesthesisSubItems);
        console.log(JSON.stringify(this.formData));
    }

    getSubItems(itemsArr: any[]) {
        const arr: any = [];
        for (let i = 0; i < itemsArr.length; i++) {
            const el = document.getElementById(itemsArr[i].id);
            const instance = TextBox.getInstance(el) as TextBox;
            const value = instance.option('value');
            if (value && value.length) {
                itemsArr[i]['textBoxVal'] = value;
            }
            const itemId = itemsArr[i].id.toString(10);
            const groupId = itemId.length === 3 ? Number(itemId[0] + itemId[1]) : Number(itemId[0]);
            if (arr.length) {
                const exists = arr.find((x: any) => x.diseaseId === groupId);
                if (exists) {
                    exists.items.push(itemsArr[i]);
                    continue;
                }
            }
            const dItem = this.dsDisease.find((x: any) => x.id === groupId);
            const obj: any = { diseaseId: dItem.id, disease: dItem.text, items: [itemsArr[i]] };
            arr.push(obj);
        }
        return arr;
    }

    onAdiminstrationChanged(ev: any) {
        if (ev.addedItems && ev.addedItems.length) {
            this.formData.administrationMode = ev.addedItems[0];
        }
    }

    onItemClick(ev: any) {
        const isSelected = ev.component.isItemSelected(ev.itemElement);
        // deselectat
        if (!isSelected) {
            ev.component.selectItem(ev.itemElement);
        }
    }

    onBooleanChanged(ev: any, type: string) {
        if (ev.addedItems && ev.addedItems.length) {
            this.formData[type] = ev.addedItems[0].value;
        }
    }

    onDrawStart(ev: any) {
    }

    onDrawComplete(ev: any) {
        console.log(this.signaturePad.toData());
        console.log(this.signaturePad.toDataURL('image/jpeg', 0.5));
    }



    clearSignature() {
        this.signaturePad.clear();
    }
}
