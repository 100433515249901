import { Component, OnInit, ViewChild } from '@angular/core';
import { EditPasswordService } from './edit-password.service';
import { DxFormComponent } from 'devextreme-angular';
import { DataService } from '../../../app/service/data.service';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html'
})
export class EditPasswordComponent implements OnInit {

  inputAttr: { id: string };
  @ViewChild('changePassForm', { static: false }) changePassForm: DxFormComponent;

  constructor(public editPassword: EditPasswordService, public ds: DataService) { }

  ngOnInit() {
    this.inputAttr = { id: 'inputAttr' };
  }

  passwordComparison = () => {
    return this.changePassForm.instance.option('formData').newPass;
  }
}
