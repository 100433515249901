import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { EditPatientService } from './edit-patient.service';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Component({
    selector: 'app-edit-patient',
    templateUrl: './edit-patient.component.html'
})
export class EditPatientComponent implements OnInit {


    constructor(public ds: DataService, public edit: EditPatientService) {
    }

    ngOnInit() {
    }
}
