import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DataService } from '../../../app/service/data.service';
import { EditSendSmsService } from './send-sms.service';

@Component({
  selector: 'app-edit-send-sms',
  templateUrl: './send-sms.component.html'
})
export class EditSendSmsComponent implements OnInit {

  inputAttr: { id: string };
  @ViewChild('changePassForm', { static: false }) changePassForm: DxFormComponent;

  constructor(public edit: EditSendSmsService, public ds: DataService) { }

  ngOnInit() {
    this.inputAttr = { id: 'inputAttr' };
  }

  passwordComparison = () => {
    return this.changePassForm.instance.option('formData').newPass;
  }
}
