import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';

@Injectable()

export class EditPatientService extends iBisEditService {

    patientPopupInstance: any;
    patientFormInstance: any;

    constructor(public ds: DataService, public lg: iBisLanguageService) {
        super(ds.lg);
        this.validation = 'patientValidation';
    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Patient').eq('id', data.ap_patientId)).single().then((res: any) => {
                resolve(res);
            }, error => notify(error, 'error', 3000));
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            const obj: any = {};
            Object.assign(obj, this.model);
            this.model.isSaving = true;
            obj.firstName = obj.firstName.trim();
            obj.lastName = obj.lastName.trim();
            const s = EntityStore.fromQuery(new EntityQuery('Patient'));
            const op = this.model.id ? s.update(this.model.id, obj) : s.insert(obj);
            op.then((res: any) => {
                resolve();
                this.success(res);
            }, error => notify(error, 'error', 3000));
        });
    }

    reset(): void {
        this.model = {};
    }

    createDefault() {
        this.reset();
    }
}
