import { Injectable } from '@angular/core';
import { iBisGridConfigService } from '@dohu/ibis-common';
import * as ui from '../../assets/uiCustomize.json';

@Injectable()
export class UIService {
    currentGridInst: any;
    tabsWidth: any;
    rowAlternationEnabled: boolean;
    showColumnLines: boolean;
    showRowLines: boolean;
    columnHidingEnabled: boolean;
    isWindows: boolean;
    fullHeight: any;
    gridTabsHeight: any;
    scrollHeight: any;
    minWidthPopup: any;
    showSearchPanel: any;
    showHeaderFilter: any;
    showGroupPanel: any;
    showFilterRow: any;
    columnChooserEnabled: any;
    exportEnabled: any;
    stateStoringEnabled: any;
    remoteOperationsSorting: any;
    remoteOperationsPaging: any;
    remoteOperationsFiltering: any;
    scrollingMode: any;
    showScrollbar: any;
    showFilterPanel: any;
    detailsGridHeight: any;
    receivingGridHeight: any;
    showBorders: any;

    constructor(public gridService: iBisGridConfigService) {
        let data;
        data = <any>ui;

        this.showRowLines = data.showRowLines;
        this.showBorders = data.showBorders;
        this.showColumnLines = data.showColumnLines;
        this.showSearchPanel = data.showSearchPanel;
        this.showHeaderFilter = data.showHeaderFilter;
        this.showGroupPanel = data.showGroupPanel;
        this.showFilterRow = data.showFilterRow;
        this.showFilterPanel = data.showFilterPanel;
        this.columnChooserEnabled = data.columnChooserEnabled;
        this.exportEnabled = data.exportEnabled;
        this.stateStoringEnabled = data.stateStoringEnabled;
        this.remoteOperationsSorting = data.remoteOperationsSorting;
        this.remoteOperationsPaging = data.remoteOperationsPaging;
        this.remoteOperationsFiltering = data.remoteOperationsFiltering;
        this.rowAlternationEnabled = data.rowAlternationEnabled;
        this.columnHidingEnabled = data.columnHidingEnabled;
        this.fullHeight = data.fullHeight;
        this.gridTabsHeight = data.gridTabsHeight;
        this.tabsWidth = data.tabsWidth;
        this.scrollHeight = data.scrollHeight;
        this.minWidthPopup = data.minWidthPopup;
        this.scrollingMode = data.scrollingMode;
        this.showScrollbar = data.showScrollbar;
    }

    onContentReady(e: any) {
        e.component.option('height', 'calc(100vh - 250px)');
    }

    onPopupShown(ev: any) {
        this.scrollHeight = ev.component.content().clientHeight - 20;
    }

    onPopupHidden(ev: any) {
        this.scrollHeight = '100%';
    }

    prepareToolbar(event: any, customStore: boolean = false, hideRefresh = false) {
        const ss = event.component.option('stateStoring');
        if (customStore) {
            ss.type = 'custom';
            ss.customLoad = () => this.gridService.loadState(event.component, ss.storageKey);
            ss.customSave = (state: any) => this.gridService.saveState(ss.storageKey, state);
            event.component.option('stateStoring', ss);
        }
        if (!hideRefresh) {
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: { hint: 'Reîncarcă datele', icon: 'refresh', onClick: (e: any) => event.component.refresh() },
                location: 'after'
            });
        }
        if (customStore) {
            this.gridService.addButton(event, this);
        }
    }

}

