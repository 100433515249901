import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { DataService } from '../../service/data.service';
import { custom } from 'devextreme/ui/dialog';
import { environment } from '../../../environments/environment';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DxValidationGroupComponent } from 'devextreme-angular';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

	user: any;
	passwordComparison;
	forgotPassword: boolean;
	resetEmail: string;
	registerData: any = {};
	@ViewChild('emailValidation') emailValidation: DxValidationGroupComponent;
	termCond: string;

	constructor(private router: Router, private ds: DataService, private activeRoute: ActivatedRoute, public auth: iBisAuthService) { }

	ngOnInit() {
		this.activeRoute.queryParams.subscribe(params => {
			this.forgotPassword = params['forgotPassword'] === 'true';
		});

		this.passwordComparison = () => {
			return this.registerData.correctPassword;
		};

		this.registerData = { isConfirm: false };
		this.termCond = window.location.origin + '/#/terms-and-conditions';
	}

	onRegister(e: any) {
		const validation = e.validationGroup.validate();
		if (!validation.isValid) {
			notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
			return;
		}

		const fields = ['_Companie_', '_CIF_', '_Telefon_'];
		const values = new Array();
		for (let i = 0; i < fields.length; i++) {
			values.push(this.registerData[fields[i]]);
		}
		const obj = {
			registerType: 'MDS',
			fullName: this.registerData.fullName,
			emailAddress: this.registerData.emailAddress,
			deployId: environment.deployId,
			fields: fields,
			values: values
		};

		const customDialog = custom({
			title: 'Alertă!',
			message: 'În câteva momente vei primi un e-mail de confirmare cu toate detaliile necesare. Îți mulțumim.',
			buttons: [{
				text: 'OK',
				onClick: () => {
					this.router.navigateByUrl('/login');
					this.registerData = { isConfirm: false };
				}
			}],
			showTitle: true
		});
		this.auth.register(obj).then(() => {
			customDialog.show();
		}, err => notify(err, 'error', 3000));
	}

	onBack() {
		this.router.navigateByUrl('/login');
	}

	onResetPassword(e: any) {
		const validation = e.validationGroup.validate();
		if (!validation.isValid) {
			return;
		}
		this.auth.forgotPassword(this.resetEmail).then((result) => {
			// raspunsul vine prea devreme
			setTimeout(() => {
				if (result === 'OK') {
					notify('În câteva momente vei primi un e-mail de confirmare cu toate detaliile necesare. Îți mulțumim.',
						'success', 10000);
					this.emailValidation.instance.reset();
					setTimeout(() => {
						this.router.navigateByUrl('/login');
					}, 3000);
				} else {
					notify('Adresa de e-mail este invalidă', 'error', 10000);
				}
			}, 500);
		}, err => notify(err, 'error', 3000));
	}

	// register(event) {
	//     const validation = event.validationGroup.validate();
	//     if (validation.isValid) {
	//         registerData.fullName = registerData.lastName + ' ' + registerData.firstName;
	//         EntityStore.execute('RegisterUser', registerData)
	//             .then((result) => {
	//                 if (result === 'request_invalid') {
	//                     notify('Cererea de inregistrare inexistenta sau expirata!', 'error', 3000);
	//                     return;
	//                 }
	//                 notify('Utilizator creat cu succes!', 'success', 3000);
	//                 this.router.navigateByUrl('/login');
	//             });
	//     }
	// }
}

