import { Component, NgModule, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DataService } from '../../service/data.service';
import { Router } from '@angular/router';
import { DxPopoverModule, DxButtonModule, DxListModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { EditSpecSelectionService } from '../spec-selection/spec.selection.service';
import { EditRoleSelectionService } from '../role-selection/role-selection.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Output() menuToggle = new EventEmitter<boolean>();
  @Input() menuToggleEnabled = false;

  isSubMenuVisible: boolean;

  menuItems: any[];
  onShowMenu: any;
  currentTarget: string;

  constructor(private auth: iBisAuthService, public ds: DataService, public router: Router, public editRole: EditRoleSelectionService,
    public editSpec: EditSpecSelectionService) {
    this.isSubMenuVisible = false;
    this.onShowMenu = this.onShowMenuEv.bind(this);
    this.ds.initPartyUser();
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  onShowMenuEv(target: string, e: any) {
    if (e) {
      e.event.preventDefault();
    }
    this.currentTarget = target;
    switch (target) {
      case '#company-options':
        this.ds.setDsCompanies(this.auth.companyId);
        this.menuItems = JSON.parse(sessionStorage.getItem('dsCompanies')) || [];
        const hasPlus = this.menuItems.find(x => x.icon === 'plus');
        if (hasPlus) { this.menuItems.pop(); }
        if (!this.menuItems.length) { return; } // sa nu deschida popover-ul daca nu are alte companii
        break;
      case '#clinic-options':
        this.menuItems = JSON.parse(JSON.stringify(this.ds.dsClinic));
        break;
    }
    this.isSubMenuVisible = true;

  }

  onMenuLeave() {
    this.isSubMenuVisible = false;
  }

  onIconMenuClick(e: any) {
    switch (this.currentTarget) {
      case '#company-options':
        if (e.itemData.id && e.itemData.text) {
          const temporarySave = {};
          Object.assign(temporarySave, this.ds.currentClinic);
          sessionStorage.removeItem('currentClinic');
          this.auth.changeCompany(e.itemData).then(result => {
            this.ds.setDsCompanies(e.itemData.id);
          }, err => {
            sessionStorage.setItem('currentClinic', JSON.stringify(temporarySave));
            notify('Cererea nu a putut fi procesată.', 'error', 3000);
          });
        }
        break;
      case '#clinic-options':
        this.ds.currentClinic = e.itemData;
        const previousData = {
          clinic: JSON.parse(sessionStorage.getItem('currentClinic')),
          user: JSON.parse(sessionStorage.getItem('currentUser' + this.ds.appName))
        };
        sessionStorage.setItem('currentClinic', JSON.stringify(e.itemData));
        this.ds.userLogin.role = null;
        this.ds.userLogin.userType = null;

        this.ds.getRolesForUser({ userId: this.ds.userLogin.userId, partyId: e.itemData.id }).then((r: any) => {
          if (r.length === 1) {
            this.ds.userLogin.roles = [r[0].name];
            if (r[0].name === 'doctor') {
              this.ds.getDoctorSpecializations(this.ds.userLogin.userId, e.itemData.id).then((specs: any) => {
                if (specs && specs.data && specs.data.length > 1) {
                  this.ds.userLogin.specs = specs.data;
                  this.editSpec.showPopup(this.ds.userLogin, true).then((sel: any) => {
                    if (sel && sel.isSaving) {
                      this.ds.setUser(sel);
                    } else {
                      this.onCancelSelection(previousData);
                    }
                  });
                } else if (specs && specs.data && specs.data.length === 1) {
                  this.ds.userLogin.specId = specs.data && specs.data[0].id;
                  this.ds.setUser(this.ds.userLogin);
                } else {
                  notify('Nu aveți atribuită nicio specializare pe clinica selectată.' +
                    'Va rugăm contactați un administrator', 'error', 5000);
                  this.onCancelSelection(previousData);
                }
              });
            } else {
              this.ds.setUser(this.ds.userLogin);
            }

          } else if (r.length > 1) {
            const stringRoles = [];
            r.forEach((el: any) => {
              stringRoles.push(el.name);
            });
            this.ds.userLogin.roles = stringRoles;
            this.editRole.showPopup(this.ds.userLogin, true).then((res: any) => {
              if (res && res.isSaving) {
                if (res.userType === 2) {
                  this.ds.getDoctorSpecializations(res.userId, e.itemData.id).then((specs: any) => {
                    if (specs && specs.data && specs.data.length > 1) {
                      res.specs = specs.data;
                      this.editSpec.showPopup(res, true).then((sel: any) => {
                        if (sel && sel.isSaving) {
                          this.ds.setUser(sel);
                        }
                      });
                    } else if (specs && specs.data && specs.data.length === 1) {
                      res.specId = specs.data && specs.data[0].id;
                      this.ds.setUser(res);
                    } else {
                      notify('Nu aveți atribuită nicio specializare pe clinica selectată.' +
                        'Va rugăm contactați un administrator', 'error', 5000);
                      this.onCancelSelection(previousData);
                    }
                  });
                } else { this.ds.setUser(res); }
              } else {
                this.onCancelSelection(previousData);
              }
            });
          }
        });
        break;
    }
    this.onMenuLeave();
  }

  onCancelSelection(previousData: any) {
    const clinic = previousData.clinic;
    const user = previousData.user;
    this.ds.userLogin = user;
    this.ds.currentClinic = clinic;
    sessionStorage.setItem('currentClinic', JSON.stringify(clinic));
  }


  logout() {
    if (this.ds.connection) {
      this.ds.connection.stop();
    }
    this.auth.logout().then(() => {
      this.ds.hasRoles = false;
      this.ds.logoPath = null;
      this.ds.userLogin = {};
      this.ds.currentClinic = null;
      sessionStorage.removeItem('currentClinic');
      sessionStorage.removeItem('dsCompanies');
      sessionStorage.removeItem('currentUser' + this.ds.appName);
      sessionStorage.removeItem('currentCompany' + this.ds.appName);
      sessionStorage.removeItem('navMenuItem');
      this.router.navigateByUrl('/login');
    });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxToolbarModule,
    DxPopoverModule,
    DxButtonModule,
    DxListModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
