import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import * as $ from 'jquery';
import { DxDataGridComponent } from 'devextreme-angular';
import { DataService } from '../../../service/data.service';

@Component({
    selector: 'app-stuff',
    templateUrl: './stuff.component.html'
})
export class MedicalStuffComponent implements OnInit {
    dsDiagnostics: CustomStore;

    constructor(private router: Router, public ds: DataService) {
    }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsDiagnostics = this.ds.getDiagnostics();
    }
}
