import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { iBisAuthService, iBisAuthModule } from '@dohu/ibis-auth';
import { iBisGridConfigService, iBisCommonModule } from '@dohu/ibis-common';
import {
  DxSchedulerModule, DxCalendarModule, DxSelectBoxModule, DxButtonModule, DxDataGridModule, DxSlideOutViewModule, DxValidationSummaryModule,
  DxFormModule, DxPopupModule, DxCheckBoxModule, DxListModule, DxTextBoxModule, DxTabPanelModule, DxScrollViewModule, DxAutocompleteModule,
  DxFileUploaderModule, DxTagBoxModule, DxDateBoxModule, DxAccordionModule, DxValidatorModule, DxPopoverModule, DxToolbarModule,
  DxDrawerModule, DxChartModule, DxPieChartModule, DxNumberBoxModule, DxLoadPanelModule, DxSpeedDialActionModule, DxValidationGroupModule,
  DxLookupModule,
  DxSortableModule,
  DxSwitchModule,
  DxTextAreaModule,
  DxRadioGroupModule,
} from 'devextreme-angular';
import { AppComponent } from './app.component';
import { DataService } from './service/data.service';
import { UIService } from './service/ui.service';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { RegisterComponent } from './view/register/register.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { CalendarComponent } from './view/calendar/calendar.component';
import { AppointmentComponent } from './common/appointment/appointment.component';
import { ConsultationComponent } from './common/consultation/consultation.component';
import { AppointmentService } from './common/appointment/appointment.service';
import { CalendarService } from './view/calendar/calendar.service';
import { ConsultationService } from './common/consultation/consultation.service';
import { SingleCardModule } from './common/single-card/single-card.component';
import { FooterModule } from './common/footer/footer.component';
import { EditPatientComponent } from './common/patient/edit-patient.component';
import { EditPatientService } from './common/patient/edit-patient.service';
import { EditPatientHistoryService } from './common/patient-history/patient-history.service';
import { EditPatientHistoryComponent } from './common/patient-history/patient-history.component';
import { AppRoutingModule } from './app-routing.module';
import { PatientComponent } from './view/admin/patient/patient.component';
import { UserComponent } from './view/admin/user/user.component';
import { MedicalStuffComponent } from './view/admin/medical-stuff/stuff.component';
import { ScreenService } from './service/screen.service';
import { NavToolbarModule } from './common/nav-toolbar/nav-toolbar.component';
import { HeaderModule } from './common/header/header.component';
import { EditRoleSelectionComponent } from './common/role-selection/role-selection.component';
import { EditRoleSelectionService } from './common/role-selection/role-selection.service';
import { EditSpecSelectionComponent } from './common/spec-selection/spec-selection.component';
import { EditSpecSelectionService } from './common/spec-selection/spec.selection.service';
import { OperationRoomComponent } from './view/operation-room/operation-room.component';
import { EditOperationService } from './common/edit-operation/edit-operation.service';
import { EditOperationComponent } from './common/edit-operation/edit-operation.component';
import { EnumGeneralComponent } from './view/admin/enumGeneral/enumGeneral.component';
import { EditPasswordComponent } from './common/edit-password/edit-password.component';
import { CompanyDetailsComponent } from './view/admin/company-details/company-details.component';
import { EditUserSpecializationService } from './common/userSpecialization/userSpecialization.service';
import { EditUserSpecializationComponent } from './common/userSpecialization/userSpecialization.component';
import { WorkingScheduleComponent } from './common/working-schedule/working-schedule.component';
import { AfterLoginComponent } from './view/after-login/after-login.component';
import { MaintenanceComponent } from './view/admin/maintenance/maintenance.component';
import { ShowNotificationComponent } from './common/show-notification/show-notification.component';
import { EditSendSmsService } from './common/send-sms/send-sms.service';
import { EditSendSmsComponent } from './common/send-sms/send-sms.component';
import { SmsQueueComponent } from './view/admin/sms-queue/sms-queue.component';
import { CdsFormComponent } from './view/forms/cds-form/cds-form.component';
import { FormComponent } from './view/forms/forms.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { CovidFormComponent } from './view/forms/covid-form/covid-form.component';
import { TermsAndConditionsComponent } from './view/terms-and-conditions/terms-and-conditions.component';
import { SpecializationComponent } from './view/admin/specialization/specialization.component';

@NgModule({
  declarations: [
    AppComponent,
    CalendarComponent,
    OperationRoomComponent,
    DashboardComponent,
    RegisterComponent,
    AppointmentComponent,
    EditOperationComponent,
    EditPatientComponent,
    EditPatientHistoryComponent,
    EditSendSmsComponent,
    EditRoleSelectionComponent,
    EditSpecSelectionComponent,
    EditUserSpecializationComponent,
    ConsultationComponent,
    PatientComponent,
    EnumGeneralComponent,
    UserComponent,
    MedicalStuffComponent,
    CompanyDetailsComponent,
    EditPasswordComponent,
    WorkingScheduleComponent,
    AfterLoginComponent,
    MaintenanceComponent,
    ShowNotificationComponent,
    SmsQueueComponent,
    CdsFormComponent,
    CovidFormComponent,
    FormComponent,
    TermsAndConditionsComponent,
    SpecializationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NavToolbarModule,
    HeaderModule,
    AppRoutingModule,
    DxSchedulerModule,
    DxCalendarModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxCheckBoxModule,
    DxListModule,
    DxLoadPanelModule,
    DxFormModule,
    DxTextBoxModule,
    DxTabPanelModule,
    DxFileUploaderModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxValidatorModule,
    DxSwitchModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxAccordionModule,
    DxChartModule,
    DxPieChartModule,
    DxPopoverModule,
    DxToolbarModule,
    DxSlideOutViewModule,
    DxScrollViewModule,
    DxAutocompleteModule,
    DxSpeedDialActionModule,
    DxNumberBoxModule,
    DxLookupModule,
    SingleCardModule,
    FooterModule,
    DxDrawerModule,
    DxSortableModule,
    DxRadioGroupModule,
    DxTextAreaModule,
    iBisAuthModule,
    iBisCommonModule,
    SignaturePadModule,
    // tslint:disable-next-line: deprecation
    HttpModule
  ],
  providers: [DataService,
    UIService,
    iBisGridConfigService,
    AppointmentService,
    EditOperationService,
    EditPatientService,
    EditPatientHistoryService,
    EditSendSmsService,
    EditRoleSelectionService,
    EditSpecSelectionService,
    EditUserSpecializationService,
    CalendarService,
    ConsultationService,
    HttpClient,
    iBisAuthService,
    ScreenService
  ],
  exports: [
    EditPatientComponent,
    EditPatientHistoryComponent,
    EditSendSmsComponent,
    EditRoleSelectionComponent,
    EditSpecSelectionComponent,
    WorkingScheduleComponent,
    ShowNotificationComponent,
    CdsFormComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
