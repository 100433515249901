import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../../service/data.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit {
    constructor(private router: Router, public auth: iBisAuthService, public ds: DataService) { }
    patientArr: any = [];
    maleObj = { gender: 'Masculin', Child: 0, Adolescent: 0, Adult: 0, Elder: 0, Total: 0 };
    femaleObj = { gender: 'Feminin', Child: 0, Adolescent: 0, Adult: 0, Elder: 0, Total: 0 };
    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        if (this.ds.userLogin.userType !== 1) {
            this.router.navigateByUrl('/calendar');
            return;
        }
        this.ds.getPatientsCNP().then((res: any) => {
            if (res && res.data && res.data.length) {
                for (let i = 0; i < res.data.length; i++) {
                    if (this.ds.checkCNP({ value: res.data[i].cnp })) {
                        this.parseCnp(res.data[i].cnp);
                        if (res.data.length === i + 1) {
                            this.patientArr = [this.maleObj, this.femaleObj];
                        }
                    }
                }
            }
        });
    }

    parseCnp(cnp: string) {
        const sex = Number(cnp.substring(0, 1));
        const luna = Number(cnp.substring(3, 2));
        const zi = Number(cnp.substring(5, 2));
        let an = cnp.substr(1, 2);
        an = (sex === 1 || sex === 2 || sex === 7) ? '19' + an : '20' + an;
        const age = this.getAge(new Date(Number(an), luna, zi));
        const category = this.getCategory(age);
        if (sex === 1 || sex === 3 || sex === 5 || sex === 7) {
            this.maleObj['total']++;
            this.maleObj[category]++;
        } else {
            this.femaleObj['total']++;
            this.femaleObj[category]++;
        }
    }

    getAge(date: Date) {
        const today = new Date();
        const diff = today.getTime() - date.getTime();
        return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    }

    getCategory(age: number) {
        if (age >= 0 && age <= 12) {
            return 'Child';
        } else if (age > 12 && age <= 18) {
            return 'Adolescent'
        } else if (age > 18 && age <= 64) {
            return 'Adult';
        } else if (age > 64) {
            return 'Elder'
        }
    }

    customizeTooltip(arg: any) {
        return {
            text: arg.percentText + ' - ' + arg.valueText
        };
    }
}
