import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import DataSource from 'devextreme/data/data_source';

@Injectable()

export class EditPatientHistoryService extends iBisEditService {


    dsPatientHistoryList: any;

    constructor(public ds: DataService, public lg: iBisLanguageService) {
        super(ds.lg);
        this.validation = 'patientHistoryValidation';

    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getPatientHistoryList(data).then(() => {
                resolve();
            });
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => { });
    }

    reset(): void {
        this.model = {};
    }

    createDefault() {
        this.reset();
    }

    getPatientHistoryList(doctors: []) {
        return new Promise((resolve, reject) => {
            this.dsPatientHistoryList = new DataSource({
                store: this.ds.getFinishedAppointmentPatients(doctors),
                paginate: true,
                pageSize: 30
            });
            resolve();
        });
    }

}
