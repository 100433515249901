import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DataService } from '../../../../app/service/data.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
    selector: 'app-covid-form',
    templateUrl: './covid-form.component.html',
    styleUrls: ['./covid-form.component.scss']
})
export class CovidFormComponent implements OnInit, AfterViewInit {

    formData: any = {};
    dsDefault: any;
    dsRespiratory: any;

    signaturePadOptions: any = {
        'minWidth': 1,
        'maxWidth': 2,
        'canvasWidth': 400,
        'canvasHeight': 150,
        'backgroundColor': '#f0ebf8'
    };

    @ViewChild('signaturePad') signaturePad: SignaturePad;

    constructor(public ds: DataService) { }

    ngOnInit() {
        this.dsDefault = this.ds.static.defaultQuestionSet;
        this.dsRespiratory = this.ds.static.respiratoryQuestionSet;
        this.formData = { date: new Date() };
    }

    ngAfterViewInit() {
        this.signaturePad.set('minWidth', 1);
        this.signaturePad.clear();
    }

    onCnpChanged(ev: any) {
        const group = validationEngine.getGroupConfig('cnpValidation');
        const isValid = group.validate().isValid;
        if (isValid) {
            const sex = Number(ev.value.substring(0, 1));
            if (sex === 1 || sex === 3 || sex === 5 || sex === 7) {
                this.formData.gender = 'male';
            } else {
                this.formData.gender = 'female';
            }
        }
    }

    onBooleanChanged(ev: any, question: string) {
        if (ev.addedItems && ev.addedItems.length) {
            this.formData[question] = ev.addedItems[0].value;
        }
    }

    onDrawStart(ev: any) {
    }

    onDrawComplete(ev: any) {
        console.log(this.signaturePad.toData());
        console.log(this.signaturePad.toDataURL('image/jpeg', 0.5));
    }

    sendForm() {
        console.log(JSON.stringify(this.formData));
    }

    clearSignature() {
        this.signaturePad.clear();
    }



}