import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService } from '../../service/data.service';
import { DxSchedulerComponent, DxTextBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStore, EntityStoreOptions, EntityQuery } from '@dohu/ibis-entity';
import { HttpClient } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { Router } from '@angular/router';
import { AppointmentService } from '../../common/appointment/appointment.service';
import { CalendarService } from './calendar.service';
import { ConsultationService } from '../../common/consultation/consultation.service';
import { iBisAuthService } from '@dohu/ibis-auth';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { EditPatientService } from '../../common/patient/edit-patient.service';
import { EditPatientHistoryService } from '../../common/patient-history/patient-history.service';
import { iBisFileService } from '@dohu/ibis-common';
import { EditSendSmsService } from '../../common/send-sms/send-sms.service';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit, OnDestroy {
	resourceDocDS: CustomStore;
	dsSpecializations: CustomStore;
	dsScheduler: CustomStore;
	dsPatients: any;
	dsDoctorWorkingDays: any = [];
	dsClinicWorkingDays: any = [];

	dsDoctors: any;

	selectedDate: Date = new Date();
	currentDate: Date = new Date();

	sendDschedule: any;

	dataCellTemplate: any;
	timeout: any;
	specId: any;

	calendarTabIndex: number;

	isSendDoctorScheduleVisible: boolean;
	showBeginConsultCondition: boolean;

	schDocDS = [];
	doctorSelected = [];
	specSelected = [];
	files = [];

	@ViewChild('inputField') inputField: DxTextBoxComponent;
	@ViewChild('scheduler') scheduler: DxSchedulerComponent;

	doctorScheduleLoaded: Subject<any> = new Subject<any>();
	doctorScheduleSubscription: Subscription;
	listenerSubscription: Subscription;
	partySubscription: Subscription;

	constructor(private router: Router, public ds: DataService, public http: HttpClient, public pHistoryService: EditPatientHistoryService,
		public auth: iBisAuthService, public appService: AppointmentService, public edit: CalendarService, public file: iBisFileService,
		public consultService: ConsultationService, public patientService: EditPatientService, public sendSms: EditSendSmsService) { }

	ngOnInit() {
		if (!this.ds.auth.isAuth) { return; }
		if (this.ds.userLogin.userType === 1) {
			this.router.navigateByUrl('/admin/company-details');
			return;
		}
		this.calendarTabIndex = 0;

		$(() => {
			this.listenerSubscription = this.ds.listenerSubject.subscribe((res: any) => {
				if (res.listenerType === 'NotifyModel') {
					if (this.scheduler && this.scheduler.instance && this.scheduler.instance.getDataSource()) {
						this.scheduler.instance.getDataSource().reload();
					}
				}
			});
		});

		this.doctorScheduleSubscription = this.doctorScheduleLoaded.subscribe(
			(loaded: any) => {
				if (loaded && this.scheduler) {
					this.scheduler.instance.repaint();
				}
			});

		this.dataCellTemplate = this.dataCellTemplateEv.bind(this);

		this.isSendDoctorScheduleVisible = false;
		this.showBeginConsultCondition = false;
		if (!this.ds.currentClinic) {
			this.partySubscription = this.ds.isPartyLoaded.subscribe(() => {
				this.initScheduler();
				this.initData();
				this.initClinicWorkingSchedule();
			});
		} else {
			this.initScheduler();
			this.initData();
			this.initClinicWorkingSchedule();
		}

		this.sendDschedule = {};
	}

	ngOnDestroy() {
		if (this.partySubscription) {
			this.partySubscription.unsubscribe();
		}
	}

	initData() {
		this.ds.loadClinicDoctors().then((result: any) => {
			// doctor view
			if (this.ds.userLogin.userType === 2) {
				result.data = result.data.filter((x: any) => x.id === this.ds.userLogin.userId);
			}
			this.dsDoctors = result.data;

			result.data.forEach((element: any) => {
				element.text = element.fullName;
			});
			this.initDoctorWorkingSchedule(result.doctors);
			this.schDocDS = result.data;
			this.resourceDocDS = result.data;

			this.ds.loadClinicSpecs().then((spec: any) => {
				spec.data.forEach((element: any) => {
					element.text = element.description;
				});
				this.dsSpecializations = spec.data;
			});
		});
	}

	initScheduler() {
		if (this.ds.userLogin.userType === 2) {
			this.doctorSelected.push(this.ds.userLogin.userId);
			this.dsScheduler = this.ds.getAppointments(this.ds.userLogin.userId);
		} else {
			this.dsScheduler = this.ds.getAppointments();
		}
	}

	initDoctorWorkingSchedule(doctors = []) {
		this.ds.loadWorkingDays(doctors).then((result: any) => {
			this.dsDoctorWorkingDays = result.data;
			this.doctorScheduleLoaded.next(true);
		});
	}

	initClinicWorkingSchedule() {
		this.ds.loadWorkingDays().then((result: any) => {
			this.dsClinicWorkingDays = result.data;
		});
	}

	dataCellTemplateEv(cellData: any, index: any, container: any) {
		let sDate: any, eDate: any, doctor: any;
		if (cellData.groups) {
			sDate = cellData.startDate;
			eDate = cellData.endDate;
			doctor = cellData.groups.ap_userId;
			if (this.dsDoctorWorkingDays && this.dsDoctorWorkingDays.length) {
				const result = this.dsDoctorWorkingDays.filter((obj: any) => obj.userId === doctor && obj.weekDay === sDate.getDay())[0];
				if (result && result.startTime && result.endTime) {
					const startTime = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), sDate.getHours(), sDate.getMinutes(), 0);
					const endTime = new Date(eDate.getFullYear(), eDate.getMonth(), eDate.getDate(), eDate.getHours(), eDate.getMinutes(), 0);
					const doctorStartTime = result.startTime.split(':');
					const doctorEndTime = result.endTime.split(':');
					// tslint:disable-next-line: max-line-length
					const compStartTime = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), doctorStartTime[0], doctorStartTime[1], 0);
					const compEndTime = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), doctorEndTime[0], doctorEndTime[1], 0);

					if (this.ds.timeRangeOverlaps(startTime, endTime, compStartTime, compEndTime)) {
						container.classList.add('disable');
					}
				}
			}
		}
	}


	openAppointmentPopup(data?: any) {
		const obj: any = {};
		Object.assign(obj, data);
		obj.appointment = true;
		obj.userId = (this.doctorSelected && this.doctorSelected.length === 1) ? this.doctorSelected[0] : null;
		this.scheduler.instance.hideAppointmentTooltip();
		this.edit.getById(obj).then((result: any) => {
			this.appService.showPopup(result, result.userId ? true : false).then((res) => {
				this.initScheduler();
				this.appService.resetAppointmentsList();
			}, err => notify(err, 'error', 3000));
		});
	}

	editPatientData(data: any) {
		if (this.ds.userLogin.userType === 4) {
			this.scheduler.instance.hideAppointmentTooltip();
			this.patientService.showPopup(data, true).then((res) => {
				if (res) {
					this.initScheduler();
				}
			});
		}
	}

	onAppointmentDblClick(event: any) {
		event.cancel = true;
		const data = event.appointmentData;

		let type = null;
		switch (data.ap_statusId) {
			case 1:
			case 2:
			case 3:
				if (this.ds.userLogin.userType !== 2) {
					type = 2;
				}
				break;
			case 4:
				if (this.ds.userLogin.userType === 2) {
					type = 11;
				}
				break;
		}
		if (type) {
			this.scheduler.instance.repaint();
			this.appointmentStatus(data, type);
		}
	}

	onCellClick(event: any) {
		if (!this.timeout) {
			this.timeout = setTimeout(() => {
				this.timeout = null;
			}, 500);
		} else {
			if (this.ds.userLogin.userType !== 2) {
				// const doctorSchedule = this.dsDoctorWorkingDays.filter(obj => obj.userId === event.cellData.groups.ap_userId);
				// if (doctorSchedule && doctorSchedule.length !== 0) {
				this.openAppointmentPopup(event.cellData);
				// } else {
				//   notify('Setati un program de lucru pentru doctor inainte de a face o programare!', 'warning', 3000);
				// }
			}
		}
		event.cancel = true;
	}

	// de schimbat numele
	getAppointmentData(data: any) {
		const app = data.appointmentData;
		const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
		const selectedDate = new Date(new Date(app.startDate).setHours(0, 0, 0, 0));
		const notPastAppointment = currentDate <= selectedDate;
		// tslint:disable-next-line:max-line-length
		this.showBeginConsultCondition = (app.ap_statusId === 2 || app.ap_statusId === 1) && this.ds.userLogin.userType !== 2 && notPastAppointment;

		// for Month view
		if (data.event.currentTarget.classList.contains('dx-list-item')) {
			data.cancel = true;
		}

		const status = app.ap_statusId;

		if (data.cancel) {
			switch (status) {
				case 1:
				case 2:
				case 3: this.appointmentStatus(app, 2);
					break;
				case 4: this.appointmentStatus(app, 11);
			}
		}
	}

	getAppointmentColor(statusId: any) {
		for (let i = 0; i < this.ds.static.statusArray.length; i++) {
			if (statusId === this.ds.static.statusArray[i].id) {
				return this.ds.static.statusArray[i].color;
			}
		}
	}

	preventFormPopupOpening(ev: any) {
		if (ev) {
			ev.preventDefault();
			ev.stopPropagation();
		}
	}

	appointmentStatus(data: any, status: number, ev?: any) {
		if (ev) {
			ev.event.preventDefault();
			ev.event.stopPropagation();
		}
		switch (status) {
			// reprogrameaza
			case 2:
				this.openAppointmentPopup(data);
				break;
			// confirma consultatia
			case 3:
				this.ds.changeAppointmentStatus(data.ap_id, 3).then((result: any) => {
					this.initScheduler();
					this.scheduler.instance.hideAppointmentTooltip();
				});
				break;
			// incepe consultatia
			case 4:
				this.edit.getById(data).then((result: any) => {
					result.newConsultation = true;
					// result.docSpec = this.specId;
					result.docSpec = this.ds.userLogin.specializationId;
					result.quantities = [];
					this.consultService.showPopup(result, true).then((res) => {
						if (res === 'finishConsultation') {
							this.appointmentStatus(this.consultService.model, 5);
						}
						this.initScheduler();

					});
					this.scheduler.instance.hideAppointmentTooltip();
					this.ds.changeAppointmentStatus(result.id, 4);
				});
				break;
			// finalizeaza consultatie
			case 5:
				const toUpdate: any = {};
				if ((data.id && data.procedure === null) || (data.ap_id && data.ap_procedure === null)) {
					notify('Selectați cel puțin o procedură!', 'warning', 3000);
					return;
				}
				Object.assign(toUpdate, data);
				toUpdate.statusId = status;
				if (data.ModelName === 'Appointment') {
					toUpdate.diagnostic = toUpdate.diagnostic.join(';');
					toUpdate.procedure = toUpdate.procedure.join(';');
				}
				const appointmentId = data.id || data.ap_id;
				EntityStore.fromQuery(new EntityQuery('Appointment')).update(appointmentId, toUpdate).then(
					(result: any) => {
						this.scheduler.instance.hideAppointmentTooltip();
						this.consultService.createQuantityProcedureArr(data.ap_procedure || data.procedure, appointmentId).then((res) => {
							this.consultService.saveProcedureDiagnostic(toUpdate, res, true);
							this.initScheduler();
						});
					}, err => notify(err, 'error', 3000));
				break;
			// sterge consultatie
			case 10:
				confirm('Ești sigur că dorești ștergerea programării ?', 'Confirm').then(val => {
					if (val) {
						this.scheduler.instance.hideAppointmentTooltip();
						EntityStore.fromQuery(new EntityQuery('Appointment')).remove(data.ap_id).then(() => {
							notify('Programare ștearsă cu succes', 'success', 3000);
							this.initScheduler();
						}, err => notify(err, 'error', 3000));
					}
				});
				break;
			// modifica consultatia
			case 11:
				this.edit.getById(data).then((result: any) => {
					this.consultService.createQuantityProcedureArr(result.procedure, result.id).then((procs: any) => {
						// result.docSpec = this.specId;
						result.docSpec = this.ds.userLogin.specializationId;
						result.quantities = procs;
						this.consultService.showPopup(result, true).then((res) => {
							if (res === 'finishConsultation') {
								this.appointmentStatus(this.consultService.model, 5);
							}
							this.initScheduler();
						});
					});
				});
				break;
		}
	}

	finishConsultation(ev: any, data: any) {
		if (ev) {
			ev.event.preventDefault();
			ev.event.stopPropagation();
		}
		EntityStore.fromQuery(new EntityQuery('Appointment').eq('id', data.ap_id)).single().then((result) => {
			data.ap_procedure = result.procedure;
			data.ap_diagnostic = result.diagnostic;
			data.ModelName = 'AppointmentView';
			data.isSchedulerFinish = true;
			this.appointmentStatus(data, 5);
		});
	}

	setDayHour(type: string) {
		const currentDay = this.selectedDate.getDay();
		const clinicSch = this.dsClinicWorkingDays.find((x: any) => x.weekDay === currentDay);
		if (type === 'start') {
			return clinicSch && clinicSch.startTime ? Number(clinicSch.startTime.split(':')[0]) : 10;
		} else {
			return clinicSch && clinicSch.endTime ? Number(clinicSch.endTime.split(':')[0]) : 20;
		}
	}

	openSendDoctorSchedulePopup() {
		this.sendDschedule = {};
		this.isSendDoctorScheduleVisible = true;
	}

	openSendSmsPopup() {
		// this.sendSms.showPopup({ messageText: '' }, true);
	}

	onScheduleSent(event: any) {
		const validation = event.validationGroup.validate();
		if (validation.isValid) {
			// tslint:disable-next-line:max-line-length
			EntityStore.execute('SendDailyAppointment', {
				userId: this.sendDschedule.userId, partyId: this.ds.currentClinic.id,
				startDate: this.ds.getDateForDs(this.sendDschedule.startDate)
			})
				.then((result) => {
					if (result === 'OK') {
						this.scheduler.instance.repaint();
						this.isSendDoctorScheduleVisible = false;
						notify('Mail trimis cu succes!', 'success', 3000);
					} else if (result === 'NO_APPOINTMENTS') {
						notify('Doctorul nu are programari in ziua selectata!', 'warning', 3000);
					} else {
						notify('Mailul nu a putut fi trimis!', 'error', 3000);
					}
				});
		}
	}

	specSelectionChanged(event: any) {
		this.getDoctorsBySpec(this.specSelected);
	}

	doctorSelectionChanged(event: any) {
		if (this.doctorSelected.length) {
			this.ds.loadAppointments(this.doctorSelected).then((result: any) => {
				this.dsScheduler = result.data;
			});
			this.getDoctorsByIds(this.doctorSelected);
		} else {
			this.getDoctorsBySpec(this.specSelected);
			this.initScheduler();
		}
	}

	getDoctorsBySpec(specs: any) {
		this.ds.getDoctorsBySpec(specs, this.dsDoctors).then((result: any) => {
			result.data.forEach((element: any) => {
				element.text = element.fullName;
			});
			this.schDocDS = result.data;
			this.resourceDocDS = result.data;
			this.doctorSelected = [];
		});
	}

	getDoctorsByIds(doctorSelected: any) {
		this.ds.getDoctorsByIds(doctorSelected).then((result: any) => {
			result.data.forEach((element: any) => {
				element.text = element.fullName;
			});
			this.resourceDocDS = result.data;
		});
	}

	isWeekend(date: any) {
		date = new Date(date);
		const day = date.getDay();
		return day === 0;
	}

	downloadAppointment(data: any, ev: any) {
		if (ev) {
			ev.event.preventDefault();
			ev.event.stopPropagation();
		}
		const obj = { appointmentId: data.ap_id, userId: this.ds.userLogin.userId, doctorId: data.ap_userId };
		this.file.DownloadByPost(EntityStoreOptions.DefaultServer + 'DownloadAppointment', obj).then(
			() => { notify(this.ds.lg.format('FILES_DOWNLOADED', null), 'success', 2000); },
			() => { notify(this.ds.lg.format('DOWNLOAD_ERROR', null), 'error', 3000); });
	}

	sendPatientSms(data: any, ev: any) {
		if (ev) {
			ev.event.preventDefault();
			ev.event.stopPropagation();
		}
	}

	openPatientHistory() {
		this.pHistoryService.showPopup(this.doctorSelected, true).then((res) => {
		});
	}
}





