import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html'
})
export class PatientComponent implements OnInit {
  dsPatient: CustomStore;
  dsDoctors: CustomStore;
  dsHistoryPatient: CustomStore;
  constructor(private router: Router, public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsPatient = this.ds.getPatients();
    this.ds.loadUsers().then((result: any) => {
      this.dsDoctors = result.data;
    });
  }

  onPatientHistoryExpand(event: any) {
    event.component.collapseAll(-1);
  }

  onPatientHistoryInit(e: any, id: string) {
    this.ds.loadPatientAppointments(id).then((data: any) => {
      const ds = data.data;
      ds.forEach(element => {
        this.ds.loadPatientHistDiagnostics(id).then((result: any) => {
          element.dsHistDiagnostics = result.data.filter(obj => obj.ad_appointmentId === element.ap_id);
          this.ds.loadPatientHistProcedures(id).then((response: any) => {
            element.dsHistProcedures = response.data.filter(obj => obj.ap_appointmentId === element.ap_id);
          });
        });
      });
      this.dsHistoryPatient = ds;
    });
  }

  toolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      widget: 'dxCheckBox',
      options: {
        hint: 'Add user',
        text: 'Pacienți fără consultații',
        onValueChanged: (e: any) => {
          this.dsPatient = this.ds.getPatients(e.value);
        }
      },
      location: 'before'
    });
  }

}
