import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable()

export class ConsultationService extends iBisEditService {

    consultPopupInstance: any;
    filesTagBoxInstance: any;
    proceduresListInstance: any;

    dsHistoryPatient: CustomStore;
    dsPatientFiles: any;
    dsHistProcedures: any;
    dsHistDiagnostics: any;
    dsProceduresList: any;
    currentProcs: any = [];
    files = [];
    initialFiles = [];

    previewFile: any;

    constructor(public ds: DataService, public lg: iBisLanguageService) {
        super(lg);
        this.createDefault();
    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getFilteredProcs(data.docSpec);

            this.ds.getFileInfo(data.id).load().then((res: any) => {
                if (res && res.data) {
                    this.files = res.data;
                    this.initialFiles = res.data;
                    if (this.filesTagBoxInstance) {
                        this.filesTagBoxInstance.option('value', this.files);
                    }
                }
            });

            this.dsHistoryPatient = this.ds.getAppointmentsForPatient(data.patientId);
            this.ds.getPatientFiles(data.patientId).then((res: any) => {
                this.dsPatientFiles = res;
            });
            this.ds.loadPatientHistDiagnostics(data.patientId).then((result: any) => {
                this.dsHistDiagnostics = result.data;
            });
            this.ds.loadPatientHistProcedures(data.patientId).then((result: any) => {
                this.dsHistProcedures = result.data;
            });
            data.procedure = data.procedure && data.procedure.length ? data.procedure.split(';') : [];
            data.diagnostic = data.diagnostic && data.diagnostic.length ? data.diagnostic.split(';') : [];
            if (data.newConsultation) {
                this.createDefault();
            }
            resolve(data);
        });
    }

    createQuantityProcedureArr(procedures: any, appId: string) {
        return new Promise((resolve, reject) => {
            const results = [];
            if (typeof procedures === 'string') {
                procedures = procedures.split(';');
            }
            if (procedures === null || procedures === undefined) { procedures = []; }
            if (procedures && procedures.length) {
                this.ds.getProcedureQuantity(procedures, appId).then((res: any) => {
                    const toDisp = res.data || [];
                    if (toDisp && toDisp.length) {
                        toDisp.forEach((element: any) => {
                            results.push({ quantity: element.quantity, id: element.procedureId });
                        });
                    }
                    resolve(results);
                });
            } else {
                resolve([]);
            }
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            const appData: any = {};
            Object.assign(appData, this.model);

            const obj = {
                patientId: appData.patientId,
                userId: appData.userId,
                statusId: 4,
                startDate: appData.startDate,
                endDate: appData.endDate,
                description: appData.description,
                observation: appData.observation,
                diagnostic: appData.diagnostic.join(';'),
                procedure: appData.procedure.join(';'),
                notes: appData.notes,
                nrc: appData.nrc
            };
            EntityStore.fromQuery(new EntityQuery('Appointment').eq('id', this.model.id)).single().then((app: any) => {
                EntityStore.fromQuery(new EntityQuery('Appointment')).update(this.model.id, obj).then(result => {
                    notify('Date salvate cu succes', 'success', 3000);
                    const prevProcs = typeof app.procedure === 'string' ? app.procedure.split(';') : [];
                    this.saveProcedureDiagnostic(appData, prevProcs);
                }, err => notify(err, 'error', 3000));
            });
        });
    }

    createDefault() {
        if (this.proceduresListInstance) {
            this.proceduresListInstance.unselectAll();
            this.currentProcs = [];
            this.proceduresListInstance.option('selectedItems', []);
            this.proceduresListInstance.option('selectedItemKeys', []);
        }
        this.previewFile = null;
        this.clearConsultationFiles();
        this.reset();

        const obj: any = document.body;
        if (obj.removeEventListener) {
            obj.removeEventListener('DOMMouseScroll', this.mouseWheel, false);
            obj.removeEventListener('mousewheel', this.mouseWheel, false);
        }
    }

    reset(): void {
        this.model = { procedure: [], diagnostic: [] };
    }

    finishConsultation() {
        this.success('finishConsultation');
        this.popup.hide();
        notify('Consultație finalizată cu succes', 'success', 3000);
    }


    saveProcedureDiagnostic(data: any, previousProcs: any, finalSave = false) {
        const ap = EntityStore.fromQuery(new EntityQuery('AppointmentProcedure'));
        let proc = data.ModelName === 'AppointmentView' ? data.ap_procedure : data.procedure;
        if (typeof data.procedure === 'string') {
            proc = proc.split(';');
        }
        if (proc === null || proc === undefined) { proc = []; }

        if (!data.isSchedulerFinish) {
            const previousProcIds = this.getProcsIds(previousProcs);
            const toDelete = this.differencesInArrays(previousProcIds, proc);
            const toInsert = this.differencesInArrays(proc, previousProcIds);
            const toUpdate = this.matchesInArrays(previousProcIds, proc);

            if (toDelete && toDelete.length) {
                toDelete.forEach((element: any) => {
                    ap.remove(element);
                });
            }

            if (toUpdate && toUpdate.length) {
                toUpdate.forEach((element: any) => {
                    let obj = {};
                    const hasQuantity = this.dsProceduresList.find(x => x.id === element && x.hasQuantity === true);
                    if (hasQuantity) {
                        const getQuantity = this.model.quantities.find(x => x.id === element).quantity;
                        obj = { appointmentId: this.model.id, procedureId: element, quantity: getQuantity };
                    } else {
                        obj = { appointmentId: this.model.id, procedureId: element };
                    }
                    EntityStore.fromQuery(new EntityQuery('AppointmentProcedure')
                        .eq('appointmentId', this.model.id).eq('procedureId', element)).single().then((p: any) => {
                            ap.update(p.id, obj);
                        });
                });
            }

            if (toInsert && toInsert.length) {
                toInsert.forEach((element: any) => {
                    let obj = {};
                    const hasQuantity = this.dsProceduresList.find((x: any) => x.id === element && x.hasQuantity === true);
                    if (hasQuantity) {
                        const getQuantity = this.model.quantities.find((x: any) => x.id === element).quantity;
                        obj = { appointmentId: this.model.id, procedureId: element, quantity: getQuantity };
                    } else {
                        obj = { appointmentId: this.model.id, procedureId: element };
                    }
                    ap.insert(obj);
                });
            }
        }

        if (finalSave) {
            const appointmentId = this.model.id || data.ap_id;
            const ad = EntityStore.fromQuery(new EntityQuery('AppointmentDiagnostic'));
            let diag = data.ModelName === 'AppointmentView' ? data.ap_diagnostic : data.diagnostic;
            if (typeof diag === 'string' && diag.length > 0) {
                diag = diag.split(';');
            }
            if (diag === null || diag === undefined) { diag = []; }
            if (diag.length > 0) {
                for (let i = 0; i < diag.length; i++) {
                    ad.insert({ appointmentId: appointmentId, diagnosticId: diag[i], orderIndex: i });
                }
            }
            EntityStore.fromQuery(new EntityQuery('Appointment')).update(appointmentId, { diagnostic: '', procedure: '' });
        }
    }

    getProcsIds(procs: any) {
        if (procs && procs.length) {
            const procIds = [];
            for (let i = 0; i < procs.length; i++) {
                procIds.push(procs[i].id || procs[i]);
            }
            return procIds;
        }
        return [];
    }

    differencesInArrays(arr1: any, arr2: any) {
        const difference = arr1.filter((x: any) => !arr2.includes(x));
        return difference;
    }

    matchesInArrays(arr1: any, arr2: any) {
        const intersection = arr1.filter((element: any) => arr2.includes(element));
        return intersection;
    }

    getFilteredProcs(specId) {
        this.ds.loadProceduresBySpecId(specId).then((result: any) => {
            result.data.forEach((element) => {
                element.text = element.name;
            });
            this.dsProceduresList = result.data;
        });
    }

    clearConsultationFiles() {
        if (this.filesTagBoxInstance) {
            this.filesTagBoxInstance.option('value', null);
            this.files = [];
            this.initialFiles = [];
        }
    }

    mouseWheel(e: any) {
        // disabling
        e = e ? e : window.event;
        if (e.ctrlKey) {
            if (e.preventDefault) {
                e.preventDefault();
            } else { e.returnValue = false; }
            return false;
        }
    }
}
