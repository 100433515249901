import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../service/data.service';
import notify from 'devextreme/ui/notify';
import { EditSpecSelectionService } from './spec.selection.service';

@Component({
    selector: 'app-spec-selection',
    templateUrl: './spec-selection.component.html'
})
export class EditSpecSelectionComponent implements OnInit {

    constructor(public ds: DataService, public edit: EditSpecSelectionService) {
    }

    ngOnInit() { }


    onSaveSelectSpecialization(event: any, selection: any) {
        if (selection.length) {
            this.edit.model.isSaving = true;
            this.edit.model.specId = selection && selection.length ? selection[0] : '';
            this.edit.onSaveClose(event);
        } else {
            notify('Selectați o specializare!', 'warning', 3000);
        }
    }
}
