import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';

import * as events from 'devextreme/events';
import { DataService } from '../../../app/service/data.service';

@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements AfterViewInit, OnDestroy {

    @ViewChild(DxTreeViewComponent, { static: true })
    menu: DxTreeViewComponent;

    @Output()
    selectedItemChanged = new EventEmitter<string>();

    @Output()
    openMenu = new EventEmitter<any>();

    @Input()
    items: any[];

    @Input()
    set selectedItem(value: String) {
        if (this.menu && this.menu.instance) {
            this.menu.instance.selectItem(value);
            sessionStorage.setItem('navMenuItem', value.toString());
        }
    }

    private _compactMode = false;
    @Input()
    get compactMode() {
        return this._compactMode;
    }
    set compactMode(val) {
        this._compactMode = val;
        if (val && this.menu.instance) {
            this.menu.instance.collapseAll();
        }
    }

    constructor(private elementRef: ElementRef, public ds: DataService) { }

    updateSelection(event) {
        const nodeClass = 'dx-treeview-node';
        const selectedClass = 'dx-state-selected';
        const leafNodeClass = 'dx-treeview-node-is-leaf';
        const element: HTMLElement = event.element;

        const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);
        Array.from(rootNodes).forEach(node => {
            node.classList.remove(selectedClass);
        });

        let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);
        while (selectedNode && selectedNode.parentElement) {
            if (selectedNode.classList.contains(nodeClass)) {
                selectedNode.classList.add(selectedClass);
            }

            selectedNode = selectedNode.parentElement;
        }
    }

    onItemClick(event) {
        this.selectedItemChanged.emit(event);
    }

    onMenuInitialized(event) {
        event.component.option('deferRendering', false);
    }

    ngAfterViewInit() {
        events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
            this.openMenu.next(e);
        });
    }

    ngOnDestroy() {
        events.off(this.elementRef.nativeElement, 'dxclick');
    }
}

@NgModule({
    imports: [DxTreeViewModule],
    declarations: [NavigationMenuComponent],
    exports: [NavigationMenuComponent]
})
export class NavigationMenuModule { }
