import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisAuthService } from '@dohu/ibis-auth';
import notify from 'devextreme/ui/notify';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditPasswordService extends iBisEditService {

  constructor(public auth: iBisAuthService) {
    super(null);
    this.validation = 'editPasswordValidation';
  }

  createDefault() {
    return {};
  }
  getById(userId: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    const obj: any = {};
    Object.assign(obj, this.model);
    return new Promise((resolve, reject) => {
      EntityStore.execute('ChangePassword', obj, environment.authServer).then((serverResp) => {
        if (serverResp === 'OK') {
          resolve(this.model);
          notify('Parolă schimbată cu succes.', 'succes', 3000);
        } else {
          reject();
        }
      }, err => {
        notify(err, 'error', 3000);
      });
    });
  }

  reset(): void {
    throw new Error('Method not implemented.');
  }
}
