import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import notify from 'devextreme/ui/notify';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { EditOperationService } from './edit-operation.service';

@Component({
  selector: 'app-edit-operation',
  templateUrl: './edit-operation.component.html'
})
export class EditOperationComponent implements OnInit {

  dsPatients: any;
  dsDoctors: any;
  dsWorkingDays: any;
  dsOperationRoomList: any;

  disabledDates: any;
  onDateChanged: any;
  onDoctorChanged: any;
  onStartTimeChanged: any;
  onOperationRoomChanged: any;

  onClosePopup: any;

  constructor(public ds: DataService, public edit: EditOperationService) {
    this.disabledDates = this.disabledDatesEv.bind(this);
    this.onDateChanged = this.onDateChangedEv.bind(this);
    this.onDoctorChanged = this.onDoctorChangedEv.bind(this);
    this.onStartTimeChanged = this.onStartTimeChangedEv.bind(this);
    this.onOperationRoomChanged = this.onOperationRoomChangedEv.bind(this);
    this.onClosePopup = this.closePopupEv.bind(this);
  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.initData();
    this.dsOperationRoomList = this.ds.getOperatingRoom();
  }

  initData() {
    this.ds.loadClinicDoctors().then((result: any) => {
      // let filter;
      // load for search in lookup
      this.dsDoctors = result.data;
      this.ds.loadWorkingDays(result.doctors).then((r: any) => {
        this.dsWorkingDays = r.data;
      }, err => notify(err, 'error', 3000));
    });

    this.ds.loadPatients().then((result: any) => {
      // load for search in lookup
      this.dsPatients = result.data;
    }, err => notify(err, 'error', 3000));
  }

  closePopupEv() {
    this.edit.closePopup();
  }

  onInitAppForm(event: any) {
    this.edit.appFormInstance = event.component;
  }

  appointmentsListInit(event: any) {
    this.edit.appListInstance = event;
  }

  onDateChangedEv(event: any) {
    if (event.event) {
      this.edit.generateTimes(this.edit.model.userId || null, event.value, this.edit.model);
    }
  }

  onOperationRoomChangedEv(event: any) {
    if (event.event) {
      this.ds.loadOperationAppointmentsByDate(event.value, this.edit.model.startDate).then((apps: any) => {
        this.edit.resetAppointmentsList(apps.data);
      }, error => notify(error, 'error', 3000));
    }
  }

  onDoctorChangedEv(event: any) {
    if (event && event.value && this.edit.model.startDate) {
      this.edit.generateTimes(event.value, this.edit.model.startDate, this.edit.model);
    }
  }

  onAutocompleteInput(selectBoxInstance: any) {
    if (selectBoxInstance) {
      selectBoxInstance.opened = false;
    }
  }

  preventCharacters(ev: any) {
    if (isNaN(+ev.event.key) && ev.event.key !== ':') {
      ev.event.preventDefault();
    }
  }

  onStartTimeChangedEv(event: any) {
    if (event && event.value) {
      const date = new Date(this.edit.model.startDate);
      if (this.edit.model && this.edit.model.userId) {
        this.ds.loadOperationAppointmentsByDate(this.edit.model.operatingRoomId, date).then((appointment: any) => {
          this.edit.getWorkingSchedule(this.edit.model.userId, date).then((ws: any) => {
            const timeArr = this.edit.createTimeArrays(ws, appointment.data, date,
              this.edit.model, this.edit.model.id);
            this.edit.appStartTimes = timeArr.start;
            this.edit.appEndTimes = timeArr.end;
            this.edit.appStartTimesDoctor = timeArr.startDoctor;
            this.edit.appEndTimesDoctor = timeArr.endDoctor;
            this.edit.appEndTimes = this.edit.appEndTimes.filter(time => time > event.value);
            this.edit.appEndTimesDoctor = this.edit.appEndTimesDoctor.filter(time => time > event.value);
            this.edit.model.endTime = this.edit.returnOneTime(event.value, 2);
          }, error => notify(error, 'error', 3000));
        }, error => notify(error, 'error', 3000));
      }
    }
  }

  verifyEndTime = (options: any) => {
    let ok = true;
    if (this.edit.appListInstance) {
      // instanceof Date if needed
      const hasDataSource = this.edit.appListInstance.component.getDataSource();
      if (hasDataSource) {
        const existingAppointments = hasDataSource.items();
        if (existingAppointments && existingAppointments.length) {
          existingAppointments.forEach((element: any) => {
            if (this.edit.model.id !== element.ap_id) {
              if (!this.ds.timeRangeOverlaps(this.ds.returnTime(element.startDate), this.ds.returnTime(element.endDate),
                this.edit.model.startTime, options.value)) {
                options.rule.message = 'Programare existenta in intervalul de timp selectat!';
                ok = false;
              }
            }
          });
        }
      }
    }
    return ok;
  }

  verifyTimeLength = (options: any) => {
    if (options && options.value && options.value.length < 5) {
      options.rule.message = 'Format timp invalid!';
      return false;
    }
    return true;
  }

  disabledDatesEv(ev: any) {
    if (ev.view === 'month') {
      this.disabledDates = (data: any) => {
        return data.view === 'month' && this.isWeekend(data.date);
      };
    } else {
      this.disabledDates = undefined;
    }
  }

  isWeekend(date: any) {
    date = new Date(date);
    const day = date.getDay();
    return day === 0;
  }

  customPatientName(data: any) {
    return !data ? null : (data.lastName + ' ' + data.firstName);
  }

  checkTimes() {
    if (this.edit.model && this.edit.model.startTime && this.edit.model.endTime &&
      this.edit.appStartTimesDoctor && this.edit.appStartTimesDoctor.length) {
      const startTime = this.edit.appStartTimesDoctor.find((x: any) => x.text === this.edit.model.startTime);
      const endTime = this.edit.appEndTimesDoctor.find((x: any) => x.text === this.edit.model.endTime);
      if ((startTime && startTime.visible === false) || endTime && endTime.visible === false) {
        return 'block';
      } else {
        return 'none';
      }
    } else {
      return 'none';
    }
  }

}
