import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DxListComponent, DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';

@Component({
    selector: 'app-enumgeneral',
    templateUrl: './enumGeneral.component.html',
    styleUrls: ['./enumGeneral.component.scss']
})
export class EnumGeneralComponent implements OnInit {

    dsEnumType: any[];
    dsEnumValue: CustomStore;

    enumTypeData: any = {};
    onEnumSaveClick: any;

    isEnumTypeVisible: boolean;
    isSpecTypeVisible: boolean;

    @ViewChild('enumTypeList') enumTypeList: DxListComponent;
    @ViewChild('EnumTypeForm') EnumTypeForm: DxFormComponent;

    constructor(public ui: UIService, public ds: DataService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.initListAndGrid();
        this.isEnumTypeVisible = false;
        this.isSpecTypeVisible = false;
        this.onEnumSaveClick = this.onEnumSaveClickEv.bind(this);
    }

    enumTypeInit(e: any) {
        e.component.selectItem(1);
    }

    onEnumTypeChanged(e: any) {
        if (e.addedItems && e.addedItems.length) {
            this.isSpecTypeVisible = e.addedItems[0].code === 'SPC';
        }
    }

    enumTypeClick(e: any) {
        if (e.itemData.id && e.itemData.id !== 0) {
            this.dsEnumValue = this.ds.getEnumValueById(e.itemData.id);
        }
        else {
            this.isEnumTypeVisible = true;
        }
    }

    onCellPrepared(e: any) {
        if (e.rowType === 'data' && e.column.command === 'expand' && !this.isSpecTypeVisible) {
            e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
        }
    }

    onCellClick(e: any) {
        if (e.rowType === 'data') {
            if (e.cellElement.classList[0] === 'dx-command-expand' && !this.isSpecTypeVisible) {
                e.component.collapseAll(-1);
            }
        }
    }

    onProcedureContentReady(e: any, specId: string) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getProceduresBySpecId(specId));
        }
    }

    onRowProcInserting(event: any, specId: string) {
        event.data.specializationId = specId;
    }

    onRowInserting(event: any) {
        event.data.typeId = this.enumTypeList.selectedItems[0].id;
    }

    onInitNewRowProc(event: any) {
        event.data.hasQuantity = false;
    }

    onEnumSaveClickEv(ev) {
        const validation = ev.validationGroup.validate();
        if (!validation.isValid) {
            notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
            return;
        }
        this.ds.isLoadPanelVisible = true;
        this.ds.insertEnumType(this.enumTypeData).then((res) => {
            this.ds.isLoadPanelVisible = false;
            notify('Configurare adaugată cu success.', 'success', 3000);
            this.initListAndGrid();
            this.enumTypeList.instance.selectItem(1);
            this.isEnumTypeVisible = false;
            this.EnumTypeForm.instance.resetValues();
        }, error => {
            this.ds.isLoadPanelVisible = false;
            notify(error, 'error', 3000);
        });
    }

    initListAndGrid() {
        this.ds.getEnumType().load().then(data => {
            if (data && data.data.length > 0) {
                this.dsEnumType = data.data;
                this.dsEnumValue = this.ds.getEnumValueById(data.data[0].id);
                this.dsEnumType.unshift({ id: 0, description: 'Adaugă +' });
            }
        });
    }

    toolbarPreparing(event: any, enumGeneral = false) {
        if (enumGeneral) {
            this.ui.prepareToolbar(event, true);
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: { hint: 'Adaugă', icon: 'plus', onClick: (e: any) => { event.component.addRow(); } },
                location: 'after'
            });
        }
    }

}

