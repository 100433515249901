import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { iBisAuthService, iBisConfirmInviteComponent, iBisResetPasswordComponent, iBisLoginComponent } from '@dohu/ibis-auth';
import { CalendarComponent } from './view/calendar/calendar.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { RegisterComponent } from './view/register/register.component';
import { PatientComponent } from './view/admin/patient/patient.component';
import { UserComponent } from './view/admin/user/user.component';
import { MedicalStuffComponent } from './view/admin/medical-stuff/stuff.component';
import { CompanyDetailsComponent } from './view/admin/company-details/company-details.component';
import { AfterLoginComponent } from './view/after-login/after-login.component';
import { MaintenanceComponent } from './view/admin/maintenance/maintenance.component';
import { SmsQueueComponent } from './view/admin/sms-queue/sms-queue.component';
import { CdsFormComponent } from './view/forms/cds-form/cds-form.component';
import { FormComponent } from './view/forms/forms.component';
import { CovidFormComponent } from './view/forms/covid-form/covid-form.component';
import { TermsAndConditionsComponent } from './view/terms-and-conditions/terms-and-conditions.component';
import { SpecializationComponent } from './view/admin/specialization/specialization.component';

export const routes: Routes = [
	{ path: '', redirectTo: 'after-login', pathMatch: 'full' },
	{ path: 'login', component: iBisLoginComponent, data: [{ returnUrl: '/after-login', showRegister: true }] },
	{ path: 'after-login', component: AfterLoginComponent },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'calendar', component: CalendarComponent, canActivate: [iBisAuthService] },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'register', component: RegisterComponent },
	{
		path: 'confirmInvite',
		component: iBisConfirmInviteComponent,
		data: [{ returnUrl: '/login' }]
	},
	{
		path: 'resetPassword',
		component: iBisResetPasswordComponent,
		data: [{ title: 'Resetează parola', isReset: true, returnUrl: '/login' }]
	}, {
		path: 'admin', children: [
			{ path: 'company-details', component: CompanyDetailsComponent, canActivate: [iBisAuthService] },
			{ path: 'specialization', component: SpecializationComponent, canActivate: [iBisAuthService] },
			{ path: 'patients', component: PatientComponent, canActivate: [iBisAuthService] },
			{ path: 'users', component: UserComponent, canActivate: [iBisAuthService] },
			{ path: 'maintenance', component: MaintenanceComponent, canActivate: [iBisAuthService] },
			{ path: 'medicalstuff', component: MedicalStuffComponent, canActivate: [iBisAuthService] },
			{ path: 'sms', component: SmsQueueComponent, canActivate: [iBisAuthService] }

		]
	}, {
		path: 'terms-and-conditions', component: TermsAndConditionsComponent,
	}, {
		path: 'form', component: FormComponent,
		children: [
			{ path: 'cds', component: CdsFormComponent },
			{ path: 'covid', component: CovidFormComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
	providers: [iBisAuthService],
	exports: [RouterModule]
})
export class AppRoutingModule { }
