import { Injectable, EventEmitter } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EntityStore, EntityQuery, EntityStoreOptions, EntityConditionGroup } from '@dohu/ibis-entity';
import { environment } from '../../environments/environment';
import { loadMessages } from 'devextreme/localization';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as nav from '../../assets/navigation.json';
import * as sd from '../../assets/static.json';

import { Subject } from 'rxjs/internal/Subject';
import { ParticipantResponse } from 'ng-chat';
import { DomSanitizer } from '@angular/platform-browser';


declare var $: any;

@Injectable()
export class DataService {
	appName: string;
	menuItems: any[];
	device: any;
	isLoginModalVisible: boolean;
	currentRole: any = [];
	dsClinic: any = [];
	notifyData: any = [];
	userLogin: any;
	previousUrl: any;
	logoPath: any;
	static: any;
	currentClinic: any;

	isLoadPanelVisible: boolean;
	hasRoles: boolean;
	isNotifyVisible: boolean;

	public connection: any;
	public proxy: any;
	public listenerSubject: Subject<any> = new Subject<any>();

	public logoSubject: Subject<any> = new Subject<any>();
	public isPartyLoaded = new EventEmitter<any>();

	environment: { saasServer: any; authServer: any; };

	constructor(public auth: iBisAuthService, public router: Router, public http: Http,
		public httpClient: HttpClient, public lg: iBisLanguageService, public sanitizer: DomSanitizer) {
		this.isLoadPanelVisible = false;
		this.isNotifyVisible = false;
		this.static = <any>sd;
		this.environment = { saasServer: environment.saasServer, authServer: environment.authServer };
		this.appName = 'MedAsist';

		setTimeout(() => {
			this.menuItems = this.getAdminItems();
			if (sessionStorage.getItem('currentClinic')) {
				this.currentClinic = JSON.parse(sessionStorage.getItem('currentClinic'));
				this.initLogo();
			}
		}, 0);

		loadMessages({
			en: { 'Yes': 'Da', 'No': 'Nu' }
		});

		EntityStoreOptions.OnError = (jqXHR) => {
			if (jqXHR.status === 401) {
				notify('Authorization has expired. Retry to login.', 'error', 3000);
				this.auth.user.isAuth = false;
				this.isLoginModalVisible = true;
				this.hasRoles = false;
				this.currentClinic = null;
				this.eraseCookie('currentUser');
				this.eraseCookie('applicationAccess');
				sessionStorage.removeItem('currentClinic');
				sessionStorage.removeItem('currentUser' + this.appName);
				sessionStorage.removeItem('currentCompany' + this.appName);
				sessionStorage.removeItem('navMenuItem');
				this.router.navigateByUrl('/login');
			}
		};
		if (sessionStorage.getItem('currentUser' + this.appName)) {
			this.hasRoles = true;
			this.userLogin = JSON.parse(sessionStorage.getItem('currentUser' + this.appName));
			// this.initSignalR(this.userLogin);
		}
	}

	static formatDate(date: Date) {
		if (date == null || date === undefined) {
			return '';
		}
		date = new Date(date);
		return EntityStore.toD2(date.getDate()) + '.' +
			EntityStore.toD2((date.getMonth() + 1)) + '.' + date.getFullYear();
	}

	static formatDateTime(date: Date) {
		if (date == null || date === undefined) {
			return '';
		}
		date = new Date(date);
		return EntityStore.toD2(date.getDate()) + '.' + EntityStore.toD2((date.getMonth() + 1)) + '.' +
			date.getFullYear() + ' ' + date.getHours() + ':' + EntityStore.toD2(date.getMinutes());
	}

	public getDate(rowData: any) {
		const col = this as any;
		return DataService.formatDate(rowData[col.dataField]);
	}

	public getDateTime(rowData: any): string {
		const col = this as any;
		return DataService.formatDateTime(rowData[col.dataField]);
	}

	public getDateForDs(date: any) {
		return DataService.formatDate(date);
	}

	public eraseCookie(name: string) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	public getFile(url: string) {
		let headers = new HttpHeaders();
		for (const key in EntityStoreOptions.Headers) {
			if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
				headers = headers.append(key, EntityStoreOptions.Headers[key]);
			}
		}
		this.httpClient.get(url, { responseType: 'blob', observe: 'response' as 'body', headers: headers }).subscribe((res: any) => {
			const header = res.headers.get('Content-Disposition');
			let fileName;
			if (header) {
				const startIndex = header.indexOf('filename=') + 9;
				const endIndex = header.length;
				fileName = header.substring(startIndex, endIndex);
			} else {
				notify('Descărcarea a eșuat', 'error', 3000);
				return;
			}

			const blob = new Blob([res.body], { type: res.type });
			const fileUrl = window.URL.createObjectURL(blob);
			const fileLink = document.createElement('a');
			fileLink.href = fileUrl;
			fileLink.download = fileName;
			// fileLink.click();
			fileLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window })); // for mozilla
			// const pwa = window.open(fileUrl);
			// if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
			//     alert( 'Please disable your Pop-up blocker and try again.');
			// }
		}, err => {
			console.log(err);
		});
	}

	public initSignalR(data: any) {
		$(() => {
			this.connection = $.hubConnection(environment.signalR);
			this.connection.qs = { Bearer: data.token, appId: this.auth.companyId };
			// this.connection.accessToken = data.token;
			this.proxy = this.connection.createHubProxy('SAASHub');
			this.proxy.on('generatedUserId', (userId: any) => {
				// With the userId set the chat will be rendered
				this.listenerSubject.next({ listenerType: 'generatedUserId', listenerRes: userId });
			}, (error: any) => console.log(error));

			this.proxy.on('messageReceived', (participant: any, message: any) => {
				// Handle the received message to ng-chat
				this.listenerSubject.next({ listenerType: 'messageReceived', listenerRes: { participant: participant, message: message } });
			}, (error: any) => console.log(error));

			this.proxy.on('friendsListChanged', (participantsResponse: Array<ParticipantResponse>) => {
				// Handle the received response to ng-chat
				this.listenerSubject.next({ listenerType: 'friendsListChanged', listenerRes: participantsResponse });
			}, (error: any) => console.log(error));

			this.proxy.on('NotifyModel', (model: any) => {
				this.listenerSubject.next({ listenerType: 'NotifyModel', listenerRes: model });
			}, (error: any) => console.log(error));

			this.connection.start({ jsonp: true })
				.done(() => {
					this.proxy.invoke('RegisterModel', this.auth.companyId, 'Appointment').then(() => {
						console.log('SignalR is live!');
					}, (error: any) => console.log(error));
				})
				.fail(() => { console.log('Could not connect'); });

			this.connection.disconnected(() => {
				if (this.auth.isAuth) {
					this.connection.start({ jsonp: true })
						.done(() => {
							this.proxy.invoke('RegisterModel', this.auth.companyId, 'Appointment').then(() => {
								console.log('SignalR is live!');
							}, (error: any) => console.log(error));
						})
						.fail(() => { console.log('Could not connect'); });
				}
			});
		});
	}

	getHighestTypeIdOrNull(roles: string[]) {
		let role = '';
		if (roles.indexOf('admin') > -1) {
			role = 'admin';
		} else if (roles.indexOf('asistent') > -1) {
			role = 'asistent';
		} else if (roles.indexOf('doctor') > -1) {
			role = 'doctor';
		}
		if (role !== '') {
			const us = this.static.userTypes.find((ut: any) => ut.type === role);
			return us ? us.id : null;
		} else {
			return null;
		}
	}

	changeAppointmentStatus(id: string, statusId: number) {
		return EntityStore.fromQuery(new EntityQuery('Appointment')).update(id, { statusId: statusId });
	}

	getDiagnostics(): CustomStore {
		const cso = EntityStore.store(new EntityQuery('Diagnostic').addOrderBy(['code']), false, 'id');
		return new CustomStore(cso);
	}

	getWorkingSchedule(): CustomStore {
		const cso = EntityStore.store(new EntityQuery('WorkingSchedule').addOrderBy(['weekDay']), false, 'id');
		return new CustomStore(cso);
	}

	addEditWorkingSchedule(obj: any, ws?: any) {
		const s = EntityStore.fromQuery(new EntityQuery('WorkingSchedule'));
		if (ws && ws.id) {
			return s.update(ws.id, obj);
		} else {
			return s.insert(obj);
		}
	}

	getWorkingDays(partyId: string, userId?: string) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', partyId).addOrderBy(['weekDay']);
		q.eq('userId', userId ? userId : null);
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	getClinicWorkingSchedule(partyId: string, weekDay: number) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', partyId).eq('userId', null).eq('weekDay', weekDay);
		return EntityStore.fromQuery(q, false, 'id').single();
	}

	loadWorkingDays(doctors = []) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', this.currentClinic.id);
		if (doctors && doctors.length) {
			// doctor schedule
			q.in('userId', doctors);
		} else {
			// clinic schedule
			q.eq('userId', null);
		}
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	loadDiagnostics() {
		const q = new EntityQuery('Diagnostic').addOrderBy(['code']);
		q.eq('isCustom', false);
		q.eq('isCustom', null);
		q.conditionGroups.useOr = true;
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	setNewCustomItem(model: string, obj: any) {
		EntityStore.fromQuery(new EntityQuery(model)).insert(obj);
	}

	loadPatientHistDiagnostics(patientId: string) {
		return EntityStore.fromQuery(new EntityQuery('AppointmentDiagnosticView').eq('apt_patientId', patientId), false, 'ad_id').load();
	}

	loadPatientHistProcedures(patientId: string) {
		return EntityStore.fromQuery(new EntityQuery('AppointmentProcedureView').eq('apt_patientId', patientId), false, 'ap_id').load();
	}

	getProcedureQuantity(proc: string[], appointId: string) {
		return EntityStore.fromQuery(new EntityQuery('AppointmentProcedure').in('procedureId', proc)
			.eq('appointmentId', appointId), false, 'id').load();
	}

	getRolesForUser(obj: any) {
		return EntityStore.execute('GetRolesForUser', obj, environment.DefaultUrlServer);
	}

	getUsersForRole(obj: any) {
		return EntityStore.execute('GetUsersForRole', obj, environment.DefaultUrlServer);
	}

	getAppRoles() {
		const q = new EntityQuery('UserLoginRole').addOrderBy(['name']);
		q.link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
			.linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
		return new CustomStore(EntityStore.store(q, false, 'id', environment.saasServer));

	}

	redirectUser() {
		if (this.userLogin.userType === 1) {
			if (this.router.url.indexOf('/admin') > -1) {
				this.redirectTo(this.router.url);
			} else {
				this.router.navigateByUrl('admin/company-details');
			}

		} else {
			if (this.router.url.indexOf('/calendar') === -1) {
				this.router.navigateByUrl('calendar');
			} else {
				this.redirectTo('calendar');
			}
		}
	}

	redirectTo(uri: string) {
		this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() =>
			this.router.navigateByUrl(uri));
	}

	getProcedures(): CustomStore {
		const cso = EntityStore.store(new EntityQuery('Procedure').addOrderBy(['code']), false, 'id');
		return new CustomStore(cso);
	}

	getSpecializations(): CustomStore {
		const cso = EntityStore.store(new EntityQuery('Specialization'), false, 'id');
		return new CustomStore(cso);
	}

	getProceduresBySpecId(id: string): CustomStore {
		const q = new EntityQuery('Procedure').eq('specializationId', id).addOrderBy(['name']);
		return new CustomStore(EntityStore.store(q, false, 'id'));
	}

	getSpecUser(specId: string, partyId?: string) {
		const q = new EntityQuery('UserSpecialization').eq('specializationId', specId).eq('partyId', partyId);
		q.distinct = true;
		q.fields.push('userId');
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	checkForUserSpecialization(partyId: string, userId: string) {
		const q = new EntityQuery('UserSpecialization').eq('partyId', partyId).eq('userId', userId);
		return EntityStore.fromQuery(q).totalCount();
	}

	getDoctorSpecializations(userId: string, partyId?: string) {
		const pId = partyId || this.currentClinic.id;
		const q = new EntityQuery('Specialization')
			.link('id', 'specializationId', new EntityQuery('UserSpecialization').eq('userId', userId).eq('partyId', partyId));
		q.distinct = true;
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	setDocSpec(userId: string, specId: string, partyId: string) {
		return EntityStore.fromQuery(new EntityQuery('UserSpecialization')).insert(
			{ userId: userId, specializationId: specId, partyId: partyId });
	}

	getPartyUser(userId: string, partyId: string, roleId: string) {
		const q = new EntityQuery('PartyUser').eq('userId', userId).eq('partyId', partyId).eq('roleId', roleId);
		return EntityStore.fromQuery(q).single();
	}

	setUserParty(userId: string, partyId: string, roleId: string) {
		return EntityStore.fromQuery(new EntityQuery('PartyUser')).insert({ userId: userId, partyId: partyId, roleId: roleId });
	}

	removeUserParty(userId: string, partyId: string, roleData: any) {
		return new Promise((resolve: any) => {
			const isDoctor = roleData.name === 'doctor';
			const pu = new EntityQuery('PartyUser').eq('partyId', partyId).eq('userId', userId).eq('roleId', roleData.id);
			const us = new EntityQuery('UserSpecialization').eq('partyId', partyId).eq('userId', userId);
			if (isDoctor) {
				EntityStore.removeByQuery(us).then(() => {
					EntityStore.removeByQuery(pu).then(() => {
						resolve(isDoctor ? 'OK_DOCTOR' : 'OK');
					}, error => resolve('ERROR'));
				}, error => resolve('ERROR'));
			} else {
				EntityStore.removeByQuery(pu).then(() => {
					resolve('OK');
				}, error => resolve('ERROR'));
			}
		});
	}

	removeUserSpecialization(userId: string, partyId: string, specId: string) {
		const q = new EntityQuery('UserSpecialization').eq('userId', userId).eq('partyId', partyId).eq('specializationId', specId);
		return EntityStore.removeByQuery(q);
	}

	loadClinicSpecs() {
		const q = new EntityQuery('Specialization');
		const qps = new EntityQuery('PartySpecialization').eq('partyId', this.currentClinic.id);
		const qus = new EntityQuery('UserSpecialization').eq('partyId', this.currentClinic.id);
		q.link('id', 'specializationId', qps);
		q.link('id', 'specializationId', qus);
		q.distinct = true;
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	getDoctorsBySpec(ids: string[], dsDoctors: any[]) {
		return new Promise((resolve, reject) => {
			const q = new EntityQuery('UserSpecialization').eq('partyId', this.currentClinic.id);
			if (ids.length > 0) {
				q.in('specializationId', ids);
			}
			EntityStore.fromQuery(q, true, 'id').load().then((result: any) => {
				const arr = [];
				for (const sp of result.data) {
					const temp = dsDoctors.find(x => x.id === sp.userId);
					if (temp) {
						arr.push(temp);
					}
				}

				let filterDoctors = arr.filter((id: any, index: any, array: any) => array.indexOf(id) === index);
				filterDoctors = filterDoctors.sort((a, b) => a.fullName < b.fullName ? -1 : 1);

				resolve({ data: filterDoctors });
			});
		});
	}

	loadPatients() {
		const q = new EntityQuery('Patient').addOrderBy(['lastName']);
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	getPatientsCNP() {
		const q = new EntityQuery('Patient').neq('cnp', null).addOrderBy(['cnp']);
		q.fields.push('cnp');
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	getPatients(filterPatients?: boolean): CustomStore {
		const q = new EntityQuery('Patient').addOrderBy(['lastName']);
		if (filterPatients) {
			const finishedApp = new EntityQuery('Appointment').eq('statusId', 5);
			const pwConsultations = new EntityQuery('Patient').addOrderBy(['firstName', 'lastName']);
			pwConsultations.link('id', 'patientId', finishedApp);
			pwConsultations.distinct = true;
			q.except(pwConsultations);
		}
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	checkForPatientCnp(cnp: string) {
		return EntityStore.fromQuery(new EntityQuery('Patient').eq('cnp', cnp), false, 'id').load();
	}

	addEditPatient(obj: any) {
		return new Promise((resolve, reject) => {
			const q = EntityStore.fromQuery(new EntityQuery('Patient'));
			if (obj.cnp) {
				this.checkForPatientCnp(obj.cnp).then((us: any) => {
					// tslint:disable-next-line: triple-equals
					if (us && (us.totalCount == 0 || (us.data && us.totalCount == 1 && us.data[0].id == obj.id))) {
						const qt = obj.id ? q.update(obj.id, obj) : q.insert(obj);
						qt.then((res) => {
							if (obj.id) {
								notify('Datele pacientului au fost modificate cu succes', 'success', 3000);
							} else {
								notify('Pacient adaugat cu succes!', 'success', 3000);
							}
							resolve(res);
						});
					} else {
						notify('CNP exista deja in baza de date!', 'warning', 3000);
					}
				});
			} else {
				const qt = obj.id ? q.update(obj.id, obj) : q.insert(obj);
				qt.then((res) => {
					if (obj.id) {
						notify('Datele pacientului au fost modificate cu succes', 'success', 3000);
					} else {
						notify('Pacient adaugat cu succes!', 'success', 3000);
					}
					resolve(res);
				});
			}
		});
	}

	getSocietyData() {
		const q = new EntityQuery('RegisterRequest').eq('deployId', environment.deployId);
		return EntityStore.fromQuery(q, false, 'id', environment.saasServer).load();
	}

	getEnumValueAttr(parentId: string) {
		const ev = new EntityQuery('EnumValue').eq('name', 'MCA');
		const evAttr = new EntityQuery('EnumValueAttr').eq('parentId', parentId).link('parentId', 'id', ev);
		return EntityStore.fromQuery(evAttr, false, 'id', environment.saasServer).load();
	}

	getRegisterModelAttr(parentId: string) {
		const q = new EntityQuery('RegisterRequestAttr').eq('parentId', parentId);
		return EntityStore.fromQuery(q, false, 'id', environment.saasServer).load();
	}

	addEditRegisterRequestAttr(obj: any, id?: string) {
		const op = EntityStore.fromQuery(new EntityQuery('RegisterRequestAttr'), false, 'id', environment.saasServer);
		if (id) {
			return op.update(id, obj);
		} else {
			return op.insert(obj);
		}
	}

	editCompanyName(name: string, id: string) {
		return EntityStore.fromQuery(new EntityQuery('Application'), false, 'id', environment.saasServer).update(id, { name: name });
	}


	loadUsers() {
		const q = new EntityQuery('UserLogin').eq('isActiv', true);
		q.link('id', 'userId', new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId));
		q.distinct = true;
		return EntityStore.fromQuery(q, false, 'id', environment.saasServer).load();
	}

	getUsers(): CustomStore {
		const q = new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).addOrderBy(['u.fullName']);
		const cso = EntityStore.store(q, false, 'u_id', environment.saasServer);
		return new CustomStore(cso);
	}

	loadClinicDoctors(): Promise<any> {
		return new Promise((resolve: any, reject: any) => {
			const q = new EntityQuery('UserLoginRole').eq('name', 'doctor').addOrderBy(['name']);
			q.link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
				.linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
			EntityStore.fromQuery(q, false, 'id', environment.saasServer).single().then((role: any) => {
				this.getUsersForRole({ partyId: this.currentClinic.id, roleId: role.id }).then((doctors: any) => {
					if (doctors === 'NO_USERS') {
						reject();
						return;
					}
					const dcs = [];
					const us = new EntityQuery('UserSpecialization').eq('partyId', this.currentClinic.id);
					us.fields.push('userId');
					EntityStore.fromQuery(us).load().then((specUsers: any) => {
						if (specUsers && specUsers.data && specUsers.data.length) {
							doctors = doctors.filter((a: any) => {
								return specUsers.data.filter((b: any) => {
									return a.id === b.userId;
								}).length !== 0;
							});
						}
						for (let i = 0; i < doctors.length; i++) {
							dcs.push(doctors[i].id);
						}
						resolve({ data: doctors, doctors: dcs });
					}, error => reject());
				});
			}, error => reject());
		});
	}


	loadProceduresBySpecId(id: any) {
		const q = new EntityQuery('Procedure').eq('specializationId', id).addOrderBy(['name']);
		return EntityStore.fromQuery(q, false, 'id').load();
	}

	loadDoctorAppointmentsByDate(doctorId: string, date: any) {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		const q = new EntityQuery('AppointmentView').eq('ap_userId', doctorId).addOrderBy(['startDate']);
		q.eq('ap_operatingRoomId', null).eq('ap_partyId', this.currentClinic.id);
		const nextDay = new Date(date);
		nextDay.setDate(date.getDate() + 1);
		q.gte('startDate', EntityStore.toDateTimeFilter(date));
		q.lt('startDate', EntityStore.toDateTimeFilter(nextDay));
		return EntityStore.fromQuery(q, false, 'ap_id').load();
	}

	loadOperationAppointmentsByDate(operationRoomId: string, date: any) {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		const q = new EntityQuery('AppointmentView')
			.eq('ap_partyId', this.currentClinic.id).eq('ap_operatingRoomId', operationRoomId).addOrderBy(['startDate']);
		const nextDay = new Date(date);
		nextDay.setDate(date.getDate() + 1);
		q.gte('startDate', EntityStore.toDateTimeFilter(date));
		q.lt('startDate', EntityStore.toDateTimeFilter(nextDay));
		return EntityStore.fromQuery(q, false, 'ap_id').load();
	}

	returnTime(time: any) {
		if (time) {
			const h = ('0' + new Date(time).getHours()).slice(-2);
			const m = ('0' + new Date(time).getMinutes()).slice(-2);
			return h + ':' + m;
		}
	}

	loadAppointments(ids: string[]) {
		const q = new EntityQuery('AppointmentView').addOrderBy(['startDate']);
		q.eq('ap.operatingRoomId', null).eq('ap.partyId', this.currentClinic.id);
		if (ids.length > 0) {
			q.in('ap.userId', ids);
		}
		return EntityStore.fromQuery(q, false, 'ap_id').load();
	}

	getAppointments(doctor?: string): CustomStore {
		const q = new EntityQuery('AppointmentView').addOrderBy(['startDate']);
		q.eq('ap.operatingRoomId', null).eq('ap.partyId', this.currentClinic.id);
		if (doctor) { q.eq('ap.userId', doctor); }
		const cso = EntityStore.store(q, false, 'ap_id');
		return new CustomStore(cso);
	}

	getOperationAppointments(doctors: any = []) {
		const q = new EntityQuery('AppointmentView').neq('ap.operatingRoomId', null).addOrderBy(['startDate']);
		q.link('ap.operatingRoomId', 'id', new EntityQuery('OperatingRoom').eq('partyId', this.currentClinic.id));
		q.distinct = true;
		const s = EntityStore.store(q, false, 'ap_id');
		s.load = (options: any) => {
			const qq = q.clone();
			return EntityStore.execute('FindValues', qq).then((res: any) => {
				for (const app of res.Items) {
					const dctName = doctors.find((x: any) => x.id === app.ap_userId);
					app.doctorName = dctName ? dctName.fullName : '-';
				}
				return { data: res.Items ? res.Items : [], totalCount: res.Count };
			});
		};
		return new CustomStore(s);
	}

	getFinishedAppointmentPatients(ids: string[]) {
		const finishedApp = new EntityQuery('Appointment').eq('statusId', 5);
		if (ids.length > 0) {
			finishedApp.in('userId', ids);
		}
		const q = new EntityQuery('Patient').addOrderBy(['firstName', 'lastName']);
		q.link('id', 'patientId', finishedApp);
		q.distinct = true;
		const s = EntityStore.store(q, false, 'id');
		s.load = (options: any) => {
			const qq = q.clone();
			qq.offset = options.skip || options.userData.skip || 0;
			qq.limit = options.take || options.userData.take || 0;

			if (options.filter && options.filter.length) {
				if (options.filter[1] === 'or') {
					const value = options.filter[0][2];
					const splitValue = value.split(' ');
					const group1 = new EntityConditionGroup();
					const group2 = new EntityConditionGroup();
					const group3 = new EntityConditionGroup();
					const group4 = new EntityConditionGroup();
					// case 1: firstName + lastName or reverse order
					if (splitValue && splitValue.length > 1) {
						group1.addCondition('firstName', 7, '%' + splitValue[0] + '%', false, false);
						group1.addCondition('lastName', 7, '%' + splitValue[1] + '%', false, false);
						group2.addCondition('firstName', 7, '%' + splitValue[1] + '%', false, false);
						group2.addCondition('lastName', 7, '%' + splitValue[0] + '%', false, false);
						qq.conditionGroups.groups.push(group1);
						qq.conditionGroups.groups.push(group2);
					}
					// case 2: just firstName or lastName
					group3.addCondition('firstName', 7, '%' + value + '%', false, false);
					group3.addCondition('lastName', 7, '%' + value + '%', false, false);
					group3.useOr = true;
					qq.conditionGroups.groups.push(group3);

					// case 3: when 2 firstName are present + lastName or reverse order
					if (splitValue && splitValue.length === 3) {
						const subGroupStart = new EntityConditionGroup();
						subGroupStart.addCondition('firstName', 7, '%' + splitValue[0] + ' ' + splitValue[1] + '%', false, false);
						subGroupStart.addCondition('lastName', 7, '%' + splitValue[2] + '%', false, false);
						const subGroupEnd = new EntityConditionGroup();
						subGroupEnd.addCondition('firstName', 7, '%' + splitValue[1] + ' ' + splitValue[2] + '%', false, false);
						subGroupEnd.addCondition('lastName', 7, '%' + splitValue[0] + '%', false, false);
						group4.groups.push(subGroupStart);
						group4.groups.push(subGroupEnd);
						group4.useOr = true;
						qq.conditionGroups.groups.push(group4);
					}
				}
				qq.conditionGroups.useOr = true;
			}

			return EntityStore.execute('FindValues', qq).then((res: any) => {
				return { data: res.Items ? res.Items : [], totalCount: res.Count };
			});
		};
		return new CustomStore(s);
	}

	getAppointmentsForPatient(patientId: string): CustomStore {
		const q = new EntityQuery('AppointmentView').eq('ap.patientId', patientId)
			.eq('ap.statusId', 5).addOrderByDesc(['startDate']);
		return new CustomStore(EntityStore.store(q, false, 'ap_id'));
	}

	loadPatientAppointments(patient: string) {
		const q = new EntityQuery('AppointmentView').eq('ap.patientId', patient).eq('ap.statusId', 5).addOrderByDesc(['startDate']);
		return EntityStore.fromQuery(q, false, 'ap_id').load();
	}

	getAppointmentProc(apId: string) {
		return EntityStore.fromQuery(new EntityQuery('AppointmentProcedureView').eq('ap.appointmentId', apId), false, 'ap_id').load();
	}

	getAppointmentDiagnostic(apId: string) {
		return EntityStore.fromQuery(new EntityQuery('AppointmentDiagnosticView').eq('ad.appointmentId', apId), false, 'ad_id').load();
	}

	getDoctorsByIds(ids: string[]) {
		const q = new EntityQuery('UserLogin').eq('isActiv', true).addOrderBy(['fullName']);
		if (ids.length > 0) {
			q.in('id', ids);
		}
		return EntityStore.fromQuery(q, false, 'id', environment.saasServer).load();
	}

	getOperatingRoomById(ids: string[]) {
		const q = new EntityQuery('OperatingRoom').addOrderBy(['name']);
		q.eq('partyId', this.currentClinic.id);
		if (ids.length > 0) {
			q.in('id', ids);
		}
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	getOperatingRoom(id?: string) {
		const q = new EntityQuery('OperatingRoom').addOrderBy(['name']);
		q.eq('partyId', id ? id : this.currentClinic.id);
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	getEnumValue(typeKey: any): CustomStore {
		const cso = EntityStore.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', typeKey)
			.addOrderBy(['code']), false, 'id');
		return new CustomStore(cso);
	}

	getEnumValueById(typeId: any): CustomStore {
		const cso = EntityStore.store(new EntityQuery('EnumValue').eq('typeId', typeId).addOrderBy(['code']), false, 'id');
		return new CustomStore(cso);
	}

	getEnumType(): CustomStore {
		const cso = EntityStore.store(new EntityQuery('EnumType').addOrderBy(['code']), false, 'id');
		return new CustomStore(cso);
	}

	insertEnumType(obj: any) {
		return EntityStore.fromQuery(new EntityQuery('EnumType')).insert(obj);
	}

	isUsernameAdmin() {
		return this.userLogin.name === 'admin';
	}

	getFileInfo(entityId: any): CustomStore {
		const q = new EntityQuery('FileInfo').eq('entityId', entityId).addOrderBy(['name']);
		const cso = EntityStore.store(q, false, 'id', environment.saasServer);
		return new CustomStore(cso);
	}

	getPatientFiles(patientId: any) {
		return new Promise((resolve) => {
			const appointments = new EntityQuery('Appointment').eq('patientId', patientId);
			appointments.fields.push('id');
			EntityStore.fromQuery(appointments).load().then((app: any) => {
				const appIds = [];
				if (app && app.data && app.data.length > 0) {
					app.data.forEach((e: any) => { appIds.push(e.id); });
					const q = new EntityQuery('FileInfo').in('entityId', appIds).addOrderByDesc(['uploadTime']);
					EntityStore.fromQuery(q, false, 'id', environment.saasServer).load().then((res: any) => {
						resolve(res.data);
					}, err => notify(err, 'error', 3000));
				} else {
					resolve([]);
				}
			}, err => notify(err, 'error', 3000));
		});
	}

	addEditParty(obj: any, id?: string) {
		const s = EntityStore.fromQuery(new EntityQuery('Party'));
		if (id) {
			return s.update(id, obj);
		} else {
			obj.isActiv = true;
			return s.insert(obj);
		}
	}

	getParty() {
		const q = new EntityQuery('Party').addOrderBy(['name']);
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	getPartyUsers(adminRole?: string) {
		const q = new EntityQuery('Party').addOrderBy(['name']);
		const pu = new EntityQuery('PartyUser').eq('userId', this.userLogin.userId);
		if (adminRole) {
			pu.eq('roleId', adminRole);
		}
		q.link('id', 'partyId', pu);
		q.distinct = true;
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}


	getPartyUserById(partyId: string, roleId: string) {
		const q = new EntityQuery('PartyUser').eq('partyId', partyId).eq('roleId', roleId);
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	initPartyUser(login?: boolean) {
		this.getPartyUsers().load().then(data => {
			this.dsClinic = [];
			if (data.data && data.data.length > 0) {
				if (!this.currentClinic) {
					this.currentClinic = { id: data.data[0].id, text: data.data[0].name };
					sessionStorage.setItem('currentClinic', JSON.stringify(this.currentClinic));
				}
				for (const pc of data.data) {
					this.dsClinic.push({ id: pc.id, text: pc.name });
				}
				this.dsClinic = this.dsClinic.filter((y: any) => y.id !== this.currentClinic.id);

				if (login) {
					this.initMaitenanceNotify(this.currentClinic.id);
				}
				this.isPartyLoaded.emit();
			}
		});
	}

	getPartySpecialization(partyId: string) {
		const q = new EntityQuery('PartySpecialization').eq('partyId', partyId);
		q.fields.push('specializationId');
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	getSpecializationByParty(partyId: string) {
		const q = new EntityQuery('Specialization').linkEq('id', 'specializationId', 'PartySpecialization', 'partyId', partyId);
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	geographicAreas(typeId: number, field?: string): CustomStore {
		const q = new EntityQuery('GeographicArea').addOrderBy(['name']);
		if (typeId) {
			q.eq('typeId', typeId);
			q.fields.push('id', 'name');
		}
		const cso = EntityStore.store(q, false, field);
		return new CustomStore(cso);
	}

	getLocalitiesByCountyId(countyId: string) {
		const q = new EntityQuery('GeographicArea').eq('typeId', 1).eq('parentId', countyId).addOrderBy(['name']);
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	getCountyId(localityId: string) {
		const q = new EntityQuery('GeographicArea').eq('id', localityId);
		q.fields.push('parentId');
		return EntityStore.fromQuery(q, false, 'id').single();
	}

	getMaintenance() {
		const clinic = JSON.parse(sessionStorage.getItem('currentClinic'));
		const q = new EntityQuery('Maintenance').eq('partyId', clinic.id || null).addOrderByDesc(['fromDate']);
		const cso = EntityStore.store(q, false, 'id');
		return new CustomStore(cso);
	}

	resetValidation(component: any) {
		if (component) {
			component.reset();
			component.option('isValid', true);
		}
	}

	checkRequired = (options: any) => {
		if (options.value !== null) {
			return true;
		}
		return false;
	}


	checkCNP = (options: any) => {
		let m: any;
		if (options.value && options.value.length !== 0) {
			if (m = /^([1-8])(0[0-9]|[0-9][0-9])(0[1-9]|1[0-2])(\d{2})(\d{2})(\d{3})(\d)$/.exec(options.value)) {
				const ll = parseInt(m[3], 10);
				const zz = parseInt(m[4], 10);
				switch (ll) {
					case 2: if (zz > 29) { return false; } break;
					case 3:
					case 4:
					case 6:
					case 9:
					case 11:
						if (zz > 30) {
							return false;
						} break;
					default:
						if (zz > 31) {
							return false;
						}
				}
				const jj = parseInt(m[5], 10);
				if (jj < 0 || (jj > 46 && jj < 51) || jj > 52) {
					return false;
				}
				const nnn = parseInt(m[6], 10);
				if (nnn < 0) {
					return false;
				}
				const c = parseInt(m[7], 10);
				const constanta = '279146358279';
				let suma = 0;

				for (let i = 0; i < constanta.length; i++) {
					suma = suma + parseInt(m[0].charAt(i), 10) * parseInt(constanta.charAt(i), 10);
				}
				const rest = suma % 11;
				return (((rest < 10) && (rest === c)) || ((rest === 10) && (c === 1))) ? true : false;
			}
			return false;
		}
		return true;
	}

	checkCUI = (options: any) => {
		if (options.value && options.value.length !== 0) {
			let c = options.value.toUpperCase();
			const hasRo = c.indexOf('RO') > -1;
			if (hasRo) {
				c = c.substr(2, c.length);
			}
			c = c.trim();
			if (c.length < 2 || c.length > 10) { return false; }
			const cifraControl = c.substr(-1);
			let cui = c.substr(0, c.length - 1);

			while (cui.length !== 9) {
				cui = '0'.concat(cui);
			}

			let suma = cui[0] * 7 + cui[1] * 5 + cui[2] * 3 + cui[3] * 2 + cui[4] * 1 + cui[5] * 7 + cui[6] * 5 + cui[7] * 3 + cui[8] * 2;
			suma = suma * 10;
			let rest = suma % 11;
			if (rest === 10) {
				rest = 0;
			}

			// tslint:disable-next-line: triple-equals
			if (rest == cifraControl) {
				return true;
			}
			return false;
		}
		return true;
	}

	timeRangeOverlaps(a_start, a_end, b_start, b_end) {
		if ((a_start <= b_start && b_start < a_end)
			||
			(a_start < b_end && b_end <= a_end)
			||
			(b_start < a_start && a_end < b_end)) {
			return false;
		}
		return true;
	}

	uploadFile(fileData: any, appointment: any, directly?: boolean) {
		return new Promise((promise) => {
			let appId;
			if (directly) {
				appId = appointment.ap_id;
			} else {
				appId = appointment.id;
			}

			const file: File = fileData;
			const formData: FormData = new FormData();
			formData.append('uploadFile', file, appId + '_' + file.name);

			const currentUser = JSON.parse(sessionStorage.getItem('currentUser' + this.appName));
			// tslint:disable-next-line: deprecation
			const headers = new Headers();
			headers.set('Authorization', 'Bearer ' + currentUser.token);
			// tslint:disable-next-line: deprecation
			const options = new RequestOptions({ headers: headers });
			const apiUrl1 = environment.saasServer + 'UploadFile';
			this.http.post(apiUrl1, formData, options).subscribe(
				data => {
					promise(data);
				},
				error => notify(error, 'error', 3000)
			);
		});
	}

	uploadLogo(fileData: any) {
		return new Promise((promise) => {
			const file: File = fileData;
			const formData: FormData = new FormData();
			const currentCompany = JSON.parse(sessionStorage.getItem('currentCompany' + this.appName));
			formData.append('uploadFile', file, currentCompany.id + '_' + file.name);

			const currentUser = JSON.parse(sessionStorage.getItem('currentUser' + this.appName));
			// tslint:disable-next-line: deprecation
			const headers = new Headers();
			headers.set('Authorization', 'Bearer ' + currentUser.token);
			// tslint:disable-next-line: deprecation
			const options = new RequestOptions({ headers: headers });
			const apiUrl1 = environment.saasServer + 'UploadFile';
			this.http.post(apiUrl1, formData, options).subscribe(
				data => {
					promise(data);
				},
				error => console.log(error)
			);
		});
	}

	initLogo(replace?: boolean) {
		if (!this.logoPath || replace) {
			this.getLogoFileInfo().then((logoData: any) => {
				if (logoData) {
					this.showLogo(logoData.id).then((path: any) => {
						const sanitizedPath = this.sanitizer.bypassSecurityTrustResourceUrl(path);
						this.logoPath = sanitizedPath;
						this.logoSubject.next(true);
					});
				}
			}, error => { });
		}
	}

	initMaitenanceNotify(partyId: string) {
		return new Promise((resolve: any) => {
			const q = new EntityQuery('Maintenance').eq('partyId', partyId);
			EntityStore.fromQuery(q).load().then((res: any) => {
				if (res && res.data && res.data.length) {
					const today = new Date().getTime();
					const oneDay = 1000 * 60 * 60 * 24;
					this.notifyData = [];
					for (let i = 0; i < res.data.length; i++) {
						const thruDay = new Date(res.data[i].thruDate).getTime();
						const diffDays = Math.round((thruDay - today) / oneDay);
						if (diffDays <= res.data[i].interval) {
							res.data[i].thruDate = DataService.formatDate(res.data[i].thruDate);
							res.data[i].isExpired = diffDays < 0;
							this.notifyData.push(res.data[i]);
						}
						if (this.notifyData.length && this.userLogin.userType !== 2) {
							this.isNotifyVisible = true;
						}
					}
				}
			});
		});
	}

	deleteInitialLogo() {
		return new Promise((promise) => {
			this.getLogoFileInfo().then((logoData: any) => {
				if (logoData) {
					const q = new EntityQuery('FileInfo');
					EntityStore.fromQuery(q, false, 'id', environment.saasServer).remove(logoData.id).then(() => {
						promise(null);
					});
				} else {
					promise(null);
				}
			}, error => { });
		});
	}

	getSmsQueue(): CustomStore {
		const q = new EntityQuery('SmsQueue').eq('appId', this.auth.companyId).addOrderByDesc(['created']);
		const cso = EntityStore.store(q, false, 'id', environment.saasServer);
		return new CustomStore(cso);
	}

	getLogoFileInfo() {
		return new Promise<any>((resolve, reject) => {
			const currentCompany = JSON.parse(sessionStorage.getItem('currentCompany' + this.appName));
			if (currentCompany && currentCompany.id) {
				const q = new EntityQuery('FileInfo').eq('entityId', currentCompany.id).addOrderBy(['name']);
				resolve(EntityStore.fromQuery(q, false, 'id', environment.saasServer).single());
			}
			reject(null);
		})
	}

	showLogo(fileId: string) {
		return new Promise((promise) => {
			const currentCompany = JSON.parse(sessionStorage.getItem('currentCompany' + this.appName));
			const apiUrl = environment.saasServer + 'Download/' + fileId;
			let headers = new HttpHeaders();
			for (const key in EntityStoreOptions.Headers) {
				if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
					headers = headers.append(key, EntityStoreOptions.Headers[key]);
				}
			}
			this.httpClient.get(apiUrl, { responseType: 'blob', observe: 'response' as 'body', headers: headers }).subscribe((res: any) => {
				const header = res.headers.get('Content-Disposition');
				let fileName;
				if (header) {
					const startIndex = header.indexOf('filename=') + 9;
					const endIndex = header.length;
					fileName = header.substring(startIndex, endIndex);
				}
				let blob: any, fileUrl: any;
				blob = new Blob([res.body], { type: 'image/png' });
				fileUrl = URL.createObjectURL(blob);
				promise(fileUrl);
			});
		});
	}

	getAdminItems(breadcrumb = false) {
		const navItems: any = <any>nav;
		const selectedItem = sessionStorage.getItem('navMenuItem');
		if (selectedItem && !breadcrumb) {
			const menuItem = navItems.menuItems.find((x: any) => {
				if (x.items) {
					return x.items.some((y: any) => {
						return y.path === selectedItem;
					});
				}
			});
			if (menuItem) {
				const item = menuItem.items.find((y: any) => y.path === selectedItem);
				if (item) {
					item.selected = true;
				}
			}
		}
		return this.clearSelectionOnLogin(navItems);
	}

	clearSelectionOnLogin(navItems: any) {
		if (this.router.url === '/login') {
			navItems.menuItems.forEach((el: any) => {
				if (el.items) {
					el.items.forEach((eli: any) => {
						eli.selected = false;
						if (eli.path === '/admin/company-details') {
							eli.selected = true;
						}
					});
				}
			});
		}
		return navItems.menuItems;
	}


	deletePatientFiles(filesData: any) {
		return new Promise((resolve: any) => {
			const filesToDelete = filesData.initialFiles.filter((initialFile: any) => {
				return filesData.files.filter((afterSaveFile: any) => {
					return initialFile.id === afterSaveFile.id;
				}).length === 0;
			});
			if (filesToDelete && filesToDelete.length) {
				filesToDelete.forEach((file: any, index: number) => {
					const q = new EntityQuery('FileInfo');
					EntityStore.fromQuery(q, false, 'id', environment.saasServer).remove(file.id).then(() => {
						if (filesToDelete.length === index + 1) {
							resolve();
							return;
						}
					});
				});
			}
			resolve();
		});
	}

	savePatientFiles(data: any, filesData: any) {
		return new Promise((resolve: any) => {
			if (data && (filesData.files && filesData.files.length) || filesData.initialFiles && filesData.initialFiles.length) {
				if (data && data.id && filesData.initialFiles && filesData.initialFiles.length) {
					this.deletePatientFiles(filesData).then(() => {
						this.insertPatientFiles(data, filesData).then(() => {
							resolve();
						});
					});
				} else {
					this.insertPatientFiles(data, filesData).then(() => {
						resolve();
					});
				}
			} else {
				resolve();
			}
		});
	}

	insertPatientFiles(data: any, filesData: any) {
		return new Promise((resolve: any) => {
			filesData.files.forEach((file: any, index: number) => {
				if (file && file.id === null) {
					this.uploadFile(file, data).then(() => {
					});
				}
				if (filesData.files.length === index + 1) {
					resolve();
				}
			});
			if (filesData.files.length === 0) {
				resolve();
			}
		});
	}

	setDsCompanies(exceptId: string) {
		let apps = this.auth.readCookie('applicationAccess');
		if (apps && apps.length) {
			apps = apps.filter((x: any) => x.id !== exceptId);
			const arr = [];
			for (let i = 0; i < apps.length; i++) {
				arr.push({ id: apps[i].id, text: apps[i].name, icon: '', type: '' });
			}
			sessionStorage.setItem('dsCompanies', JSON.stringify(arr));
		}
	}

	checkIfAppAdmin() {
		const qView = new EntityQuery('ApplicationRoleView').eq('r_name', 'admin');
		const q = new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId).eq('userId', this.userLogin.userId);
		qView.link('ar.id', 'id', q);
		return EntityStore.fromQuery(qView, false, 'id', environment.saasServer).totalCount();
	}

	setUser(data: any) {
		const typeId = this.getHighestTypeIdOrNull(data.roles[0]);
		const role = this.static.userTypes.find((ut: any) => ut.id === typeId).name;

		if (!typeId) {
			notify('Utilizatorul introdus nu are drepturi in aplicatie.', 'error', 3000);
			return;
		}
		this.userLogin = {
			name: data.name,
			specializationId: data.specId || null,
			userType: data.userType || typeId,
			userId: data.id || data.userId,
			token: data.token,
			isAuth: true,
			role: data.role || role,
			roles: data.roles,
			appId: data.appId
		};
		sessionStorage.setItem('currentUser' + this.appName, JSON.stringify(this.userLogin));
		if (!this.connection || (this.connection && this.connection.state !== 1)) {
			// this.initSignalR(this.userLogin);
		}
		this.eraseCookie('currentUser');
		// this.eraseCookie('applicationAccess');
		this.hasRoles = true;
		this.initPartyUser(true);
		this.redirectUser();
		this.initLogo();
	}

}

