import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';

@Component({
    selector: 'app-sms-queue',
    templateUrl: './sms-queue.component.html'
})
export class SmsQueueComponent implements OnInit {
    dsSmsQueue: CustomStore;

    constructor(public ds: DataService, public ui: UIService) { }

    ngOnInit() {
        this.dsSmsQueue = this.ds.getSmsQueue();
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                text: 'Trimite SMS', icon: 'message', onClick: (e: any) => {
                    //  event.component.addRow();
                }
            },
            location: 'after'
        });

        event.toolbarOptions.items.push({
            template: 'smsText',
            location: 'before'
        });

        event.toolbarOptions.items.push({
            widget: 'dxSwitch',
            options: {
                width: '100', switchedOffText: 'Dezactivată', switchedOnText: 'Activată', onOptionChanged: (e: any) => {
                    console.log(e);
                }
            },
            location: 'before'
        });


    }
}

