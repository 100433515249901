import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { EntityStore, EntityQuery, EntityConditionGroup } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import validationEngine from 'devextreme/ui/validation_engine';
import notify from 'devextreme/ui/notify';

@Injectable()

export class AppointmentService extends iBisEditService {

    oldAppData: any = {};
    appStartTimes = [];
    appEndTimes = [];
    appStartTimesDoctor = [];
    appEndTimesDoctor = [];

    appFormInstance: any;
    appListInstance: any;
    patientInstance: any;
    filesTagBoxInstance: any;

    files: any = [];
    initialFiles: any = [];
    todayAppointments: any;

    constructor(public ds: DataService, public lg: iBisLanguageService) {
        super(ds.lg);
        this.validation = 'appValidation';
        this.customValidation = 'customAppValidation';
    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (data && data.userId) {
                this.generateTimes(data.userId, data.startDate, data);
                Object.assign(this.oldAppData, data);
                if (data.id) {
                    this.ds.getFileInfo(data.id).load().then((res: any) => {
                        if (res && res.data && res.data.length) {
                            this.files = res.data;
                            this.initialFiles = res.data;
                            if (this.filesTagBoxInstance) {
                                this.filesTagBoxInstance.option('value', this.files);
                            }
                        }
                    }, err => notify(err, 'error', 3000));
                } else {
                    this.clearAppointmentFiles();
                }
            }
            resolve(data);
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            let obj: any;

            const date = new Date(this.model.startDate);
            const st = this.model.startTime.split(':');
            const et = this.model.endTime.split(':');
            this.model.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), st[0], st[1]);
            this.model.endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), et[0], et[1]);

            obj = {
                partyId: this.ds.currentClinic.id,
                patientId: this.model.patientId || null,
                userId: this.model.userId,
                statusId: this.isSameTime(this.oldAppData) ? (this.model.statusId || 1) : 2,
                startDate: this.model.startDate,
                endDate: this.model.endDate,
                description: this.model.description
            };
            const s = EntityStore.fromQuery(new EntityQuery('Appointment'));
            const op = this.model.id ? s.update(this.model.id, obj) : s.insert(obj);
            op.then((result) => {
                resolve(result);
                obj.id = this.model.id || result.id;
                this.ds.savePatientFiles(obj, { files: this.files, initialFiles: this.initialFiles });
            }, err => notify(err, 'error', 3000));
        });
    }

    reset(): void {
        this.model = {};
    }

    resetAppointmentsList(data?: any) {
        // if (this.appListInstance) {
        //     this.appListInstance.component.option('dataSource', data || []);
        // }
        this.todayAppointments = data;
    }

    isSameTime(oldData: any) {
        if (Object.keys(oldData).length === 0) {
            return true;
        }
        if (!oldData.id) {
            return true;
        }
        return (this.model.startDate.getTime() === new Date(oldData.startDate).getTime()) &&
            (this.model.endDate.getTime() === new Date(oldData.endDate).getTime());
    }

    generateTimes(doctor: any, date: Date, appointmentData: any, currentApp?: any) {
        date = new Date(date);
        this.getWorkingSchedule(doctor, date).then((ws: any) => {
            this.ds.loadDoctorAppointmentsByDate(doctor, date).then((appointment: any) => {
                if (appointment.data) {
                    for (const element of appointment.data) {
                        element.text = this.ds.returnTime(element.startDate) + ' - ' +
                            this.ds.returnTime(element.endDate) + ' ' + element.patientName;
                    }
                }
                const timeArr = this.createTimeArrays(ws, appointment.data, date, appointmentData, currentApp || appointmentData.id);
                this.appStartTimes = timeArr.start;
                this.appEndTimes = timeArr.end;
                this.appStartTimesDoctor = timeArr.startDoctor;
                this.appEndTimesDoctor = timeArr.endDoctor;
                this.resetAppointmentsList(appointment.data);
            });
        }, error => notify(error, 'error', 3000));
    }

    getWorkingSchedule(doctorId: string, date: Date) {
        return new Promise((resolve: any) => {
            const q = new EntityQuery('WorkingSchedule').eq('partyId', this.ds.currentClinic.id).eq('weekDay', date.getDay());
            const group = new EntityConditionGroup();
            group.eq('userId', doctorId).eq('userId', null);
            group.useOr = true;
            q.conditionGroups.groups.push(group);
            EntityStore.fromQuery(q).load().then((ws: any) => {
                if (ws && ws.data) {
                    const defaultTime = { startTime: '08:00', endTime: '18:00' };
                    switch (ws.data.length) {
                        // fara program
                        case 0:
                            resolve({ dws: defaultTime, cws: defaultTime });
                            break;
                        case 1:
                            // program doctor sau clinica
                            const isClinic = ws.data[0].userId === null;
                            const schedule = { startTime: ws.data[0].startTime || '08:00', endTime: ws.data[0].endTime || '18:00' };
                            resolve({ dws: isClinic ? defaultTime : schedule, cws: isClinic ? schedule : defaultTime });
                            break;

                        case 2:
                            // program doctor si clinica
                            const dIndex = ws.data.findIndex((x: any) => x.userId !== null);
                            const cIndex = ws.data.findIndex((x: any) => x.userId === null);
                            resolve({
                                dws: { startTime: ws.data[dIndex].startTime || '08:00', endTime: ws.data[dIndex].endTime || '18:00' },
                                cws: { startTime: ws.data[cIndex].startTime || '08:00', endTime: ws.data[cIndex].endTime || '18:00' }
                            });
                    }
                }
                console.log(ws.data);
            });
        });
    }

    createTimeArrays(workingSchedule: any, appointment: any, date: any, appointmentData: any, currentApp?: any) {
        let i = 0;
        date = new Date(date);
        const dsStartTimes = [];
        let dsEndTimes = [];
        const dsStartTimesDoctor = [];
        let dsEndTimesDoctor = [];
        let startTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i);
        let endTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i);


        while (startTimeDate < workingSchedule.cws.endTime) {
            startTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i);
            endTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i + 2);

            if (appointment) {
                let availableTime = new Date(date).setHours(parseInt(startTimeDate.split(':')[0], 10));
                availableTime = new Date(availableTime).setMinutes(parseInt(startTimeDate.split(':')[1], 10));

                let availableEndTime = new Date(date).setHours(parseInt(endTimeDate.split(':')[0], 10));
                availableEndTime = new Date(availableEndTime).setMinutes(parseInt(endTimeDate.split(':')[1], 10));
                // gasire timpi pentru appointments
                const r = appointment.find((x) =>
                    (new Date(availableTime) <= new Date(x.startDate)) && (new Date(x.startDate) < new Date(availableEndTime)) ||
                    (new Date(availableTime) < new Date(x.endDate)) && (new Date(x.endDate) <= new Date(availableEndTime)) ||
                    (new Date(x.startDate) < new Date(availableTime)) && (new Date(availableEndTime) < new Date(x.endDate)));

                const disableStartTime = (workingSchedule.dws.startTime > startTimeDate) || (workingSchedule.dws.endTime < startTimeDate);
                const disableEndTime = (workingSchedule.dws.startTime > endTimeDate) || (workingSchedule.dws.endTime < endTimeDate);
                if (!r) {
                    dsStartTimes.push(startTimeDate);
                    dsEndTimes.push(endTimeDate);
                    dsStartTimesDoctor.push({ text: startTimeDate, visible: !disableStartTime });
                    dsEndTimesDoctor.push({ text: endTimeDate, visible: !disableEndTime });
                }
                // cand deschide appointmentul actual
                if (r && currentApp && r.ap_id === currentApp) {
                    dsStartTimes.push(startTimeDate);
                    dsEndTimes.push(endTimeDate);

                    dsStartTimesDoctor.push({ text: startTimeDate, visible: !disableStartTime });
                    dsEndTimesDoctor.push({ text: endTimeDate, visible: !disableEndTime });
                }
            }
            i++;
        }
        // cand deschide appointment din scheduler, endtimes sa nu includa timpii inainte de starttime-ul selectat
        if ((appointmentData.startTime)) {
            dsEndTimes = dsEndTimes.filter(time => time > appointmentData.startTime);
            dsEndTimesDoctor = dsEndTimesDoctor.filter(time => time > appointmentData.startTime);
        }

        dsStartTimes.splice(-2, 2);
        dsEndTimes.splice(-2, 2);
        dsStartTimesDoctor.splice(-2, 2);
        dsEndTimesDoctor.splice(-2, 2);

        return { start: dsStartTimes, end: dsEndTimes, startDoctor: dsStartTimesDoctor, endDoctor: dsEndTimesDoctor };
    }

    returnOneTime(time: any, increment: number) {
        const st = time.split(':');
        if (st.length < 2) {
            console.log('Data type is not valid');
            return '00:00';
        }
        let startTime = (st[0] * 60 + Number(st[1]));
        startTime = startTime + increment * 10;
        const hours = Math.floor(startTime / 60);
        const minutes = (startTime % 60);

        return ('0' + (hours % 24)).slice(-2) + ':' + ('0' + minutes).slice(-2);
    }

    addMinutesToDate(date: Date, minutes: number) {
        return new Date(date.getTime() + minutes * 60000);
    }

    createDefault() {
        const selectedDate = this.model && this.model.startDate ? this.model.startDate : null;
        if (this.appFormInstance) {
            this.appFormInstance.resetValues();
        }
        this.clearAppointmentFiles();
        const group = validationEngine.getGroupConfig(this.customValidation);
        if (group) {
            group.reset();
        }
        this.appStartTimes = [];
        this.appEndTimes = [];
        this.appEndTimesDoctor = [];
        this.appStartTimesDoctor = [];
        this.oldAppData = {};
        return { patientId: null, startTime: null, endTime: null, startDate: selectedDate };
    }

    clearAppointmentFiles() {
        if (this.filesTagBoxInstance) {
            this.filesTagBoxInstance.option('value', null);
            this.files = [];
            this.initialFiles = [];
        }
    }
}

