import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { iBisAuthService } from '@dohu/ibis-auth';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { EditRoleSelectionService } from '../../common/role-selection/role-selection.service';
import { EditSpecSelectionService } from '../../common/spec-selection/spec.selection.service';
import { DataService } from '../../../app/service/data.service';

@Component({
	selector: 'app-after-login',
	templateUrl: './after-login.component.html',
	styleUrls: ['./after-login.component.scss']

})
export class AfterLoginComponent implements AfterViewInit {
	userData: any = {};
	clinicList: any = [];

	constructor(private auth: iBisAuthService, public ds: DataService, public router: Router,
		public editRole: EditRoleSelectionService, public editSpec: EditSpecSelectionService) {
	}
	ngAfterViewInit() {
		if (!this.auth.isAuth) {
			this.router.navigateByUrl('/login');
			return;
		}
		this.ds.isLoginModalVisible = false;
		Object.assign(this.userData, this.auth.user);
		const appSelected = this.auth.readCookie('appSelected');
		this.checkSelectedCompany(appSelected).then(() => {
			this.checkInitApp(this.userData.id).then((response: any) => {
				if (response === 'FIRST_CONFIGURATION') {
					this.initFirstConfiguration(this.userData);
				} else if (response === 'NO_CLINIC_ALLOCATION') {
					notify('Nu sunteți asociat pe o clinică, vă rugăm să contactați un administrator.', 'error', 5000);
					this.clearStorage();
				} else if (response.length === 1) {
					this.onClinicClick(response[0]);
				} else if (response.length > 1) {
					this.clinicList = [];
					for (let i = 0; i < response.length; i++) {
						this.clinicList.push(response[i]);
					}
				}
			});
		});
	}

	checkSelectedCompany(appSelected?: any) {
		return new Promise((resolve: any) => {
			const wrongSel = { id: this.auth.companyId, text: this.auth.companyName, icon: '', type: '' };
			if (!appSelected || this.auth.companyId === appSelected.id) {
				sessionStorage.setItem('dsCompanies', JSON.stringify(this.auth.dsCompanies));
				resolve();
			} else {
				this.auth.changeCompany({ id: appSelected.id, text: appSelected.name }, false).then(result => {
					const apps = this.auth.dsCompanies.filter((x: any) => x.id !== this.auth.companyId);
					apps.push(wrongSel);
					sessionStorage.setItem('dsCompanies', JSON.stringify(apps));
					resolve();
				});
			}
		});
	}

	setClearClinicSelection(data?: any) {
		if (data) {
			this.ds.currentClinic = { id: data.id, text: data.name };
			sessionStorage.setItem('currentClinic', JSON.stringify(this.ds.currentClinic));
		} else {
			this.ds.currentClinic = null;
			sessionStorage.removeItem('currentClinic');
		}
	}

	checkForDoctorSpecializations(data: any) {
		return new Promise((resolve: any) => {
			const doctorRole = data.roles.find((x: any) => x.name === 'doctor');
			if (doctorRole) {
				this.ds.checkForUserSpecialization(data.partyId, data.userId).then((count: number) => {
					if (count === 0) {
						resolve(data.roles.length > 1 ? 'REMOVE_ROLE' : 'ONLY_DOCTOR');
					} else {
						resolve('OK');
					}
				});
			} else {
				resolve('OK');
			}
		});
	}

	onClinicClick(data: any) {
		this.setClearClinicSelection(data);
		this.ds.getRolesForUser({ userId: this.userData.id, partyId: data.id }).then((userRoles: any) => {
			this.checkForDoctorSpecializations({
				roles: userRoles, userId: this.userData.id,
				partyId: data.id
			}).then((response: string) => {
				if (response === 'ONLY_DOCTOR') {
					notify('Nu aveți atribuită nicio specializare pe clinica selectată.' +
						'Va rugăm contactați un administrator', 'error', 5000);
					this.clearStorage();
					return;
				} else if (response === 'REMOVE_ROLE') {
					const removeIndex = userRoles.map((item: any) => {
						return item.name;
					}).indexOf('doctor');
					userRoles.splice(removeIndex, 1);
				}

				if (userRoles.length > 1) {
					const stringRoles = [];
					userRoles.forEach((el: any) => {
						stringRoles.push(el.name);
					});
					this.userData.roles = stringRoles;
					this.editRole.showPopup(this.userData, true).then((res: any) => {
						if (res && res.isSaving) {
							if (res.userType === 2) {
								this.ds.getDoctorSpecializations(res.id, data.id).then((specs: any) => {
									if (specs && specs.data && specs.data.length > 1) {
										res.specs = specs.data;
										this.editSpec.showPopup(res, true).then((sel: any) => {
											if (sel && sel.isSaving) {
												this.ds.setUser(sel);
											} else { this.clearStorage(); }
										});
									} else if (specs && specs.data && specs.data.length === 1) {
										res.specId = specs.data && specs.data[0].id;
										this.ds.setUser(res);
									}
								});
							} else {
								this.ds.setUser(res);
							}
						} else { this.clearStorage(); }
					});
				} else {
					this.userData.roles = [userRoles[0].name];
					if (userRoles[0].name === 'doctor') {
						this.ds.getDoctorSpecializations(this.userData.id, data.id).then((specs: any) => {
							if (specs && specs.data && specs.data.length > 1) {
								this.userData.specs = specs.data;
								this.editSpec.showPopup(this.userData, true).then((sel: any) => {
									if (sel && sel.isSaving) {
										this.ds.setUser(sel);
									} else { this.clearStorage(); }
								});
							} else if (specs && specs.data && specs.data.length === 1) {
								this.userData.specId = specs.data && specs.data[0].id;
								this.ds.setUser(this.userData);
							}
						});
					} else {
						this.ds.setUser(this.userData);
					}
				}
			});
		});
	}

	checkInitApp(userId: string) {
		return new Promise((resolve, reject) => {
			EntityStore.fromQuery(new EntityQuery('Party')).totalCount().then((number: any) => {
				if (number === 0) {
					resolve('FIRST_CONFIGURATION');
				} else if (number > 0) {
					const q = new EntityQuery('Party').addOrderBy(['name']);
					q.link('id', 'partyId', new EntityQuery('PartyUser').eq('userId', userId));
					q.distinct = true;
					EntityStore.fromQuery(q).load().then((userClinics: any) => {
						if (userClinics && userClinics.data && userClinics.data.length === 0) {
							resolve('NO_CLINIC_ALLOCATION');
						} else if (userClinics && userClinics.data && userClinics.data.length > 0) {
							resolve(userClinics.data);
						}
					});
				}
			});
		});
	}

	initFirstConfiguration(data: any) {
		this.ds.userLogin = {
			name: data.name,
			userType: 1,
			userId: data.id,
			token: data.token,
			isAuth: true,
			role: 'admin',
			roles: data.roles,
			appId: data.appId
		};
		sessionStorage.setItem('currentUser' + this.ds.appName, JSON.stringify(this.ds.userLogin));
		// this.ds.initSignalR(this.ds.userLogin);
		this.ds.hasRoles = true;
		this.ds.initLogo();
		this.router.navigateByUrl('admin/company-details');
	}

	clearStorage() {
		this.auth.logout().then(() => {
			this.ds.hasRoles = false;
			// this.ds.isLoginModalVisible = true;
			this.ds.userLogin = {};
			this.setClearClinicSelection();
			sessionStorage.removeItem('currentUser' + this.ds.appName);
			sessionStorage.removeItem('currentCompany' + this.ds.appName);
			sessionStorage.removeItem('navMenuItem');
			this.router.navigateByUrl('/login');
		});
	}
}
