// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  DefaultUrlServer: 'https://jvtl1wrxs9.execute-api.eu-central-1.amazonaws.com/mca/',
  deployId: 'ea91e787-6358-448f-9fc8-558f021e2ac0',
  filePath: 'https://jvtl1wrxs9.execute-api.eu-central-1.amazonaws.com/mca_file/',
  authServer: 'https://jvtl1wrxs9.execute-api.eu-central-1.amazonaws.com/auth/',
  saasServer: 'https://jvtl1wrxs9.execute-api.eu-central-1.amazonaws.com/saas/',
  signalR: 'https://jvtl1wrxs9.execute-api.eu-central-1.amazonaws.com/saas_hub'
};
