import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../app/service/data.service';

@Component({
    selector: 'app-show-notification',
    templateUrl: './show-notification.component.html',
    styleUrls: ['./show-notification.component.scss']
})
export class ShowNotificationComponent implements OnInit {
    constructor(public ds: DataService) { }

    ngOnInit() { }

    closePopup = (ev: any) => {
        this.ds.isNotifyVisible = false;
        this.ds.notifyData = [];
    }
}
