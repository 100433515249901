import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { iBisAuthService, iBisEditUserService } from '@dohu/ibis-auth';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  templateUrl: 'user.component.html'
})
export class UserComponent implements OnInit {
  dsUsers: CustomStore;
  dsClinic: CustomStore;

  @ViewChild('gridUsers') gridUsers: DxDataGridComponent;
  // @ViewChild('gridClinic') gridClinic: DxDataGridComponent;

  onEditUserClick: any;

  constructor(public ds: DataService, public ui: UIService, public auth: iBisAuthService, public edit: iBisEditUserService) {
    this.onEditUserClick = this.onEditUserClickEv.bind(this);
    // this.getFilteredWc = this.getFilteredWcEv.bind(this);
  }

  ngOnInit() {
    this.dsUsers = this.ds.getUsers();
  }

  checkIfDoctor(cell: any) {
    if (cell.data.roles.indexOf('doctor') > -1) {
      return true;
    }
    return false;
  }

  openAddParaf(event: any) {
    confirm('Atentie imaginea ar trebui sa fie de marimea 375x335px cu fundal transparent. Continuati ?', 'Atentie').then((val) => {
      if (val) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '.png');
        input.addEventListener('change', (e) => {
          this.ds.uploadFile(input.files[0], event.row.data).then(() => {
            notify('Parafa a fost adaugata cu succes.', 'success', 3000);
          });
        }, false);
        input.click();
      }
    });
  }

  onEditUserClickEv(ev: any) {
    ev.event.preventDefault();
    this.edit.authServer = this.ds.environment.authServer;
    this.edit.showPopup(ev.row.data, true, this.ds.environment.saasServer).then(
      () => this.gridUsers.instance.refresh(),
      (error) => console.log(error)
    );
  }


  onRowInserting(event: any, user: any) {
    event.data.userId = user.u_id;
  }
  toolbarPreparing(event: any, userGrid = false) {
    if (userGrid) {
      this.ui.prepareToolbar(event, true);
      event.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          hint: 'Adauga utilizator',
          icon: 'plus',
          onClick: (e: any) => {
            this.edit.authServer = this.ds.environment.authServer;
            this.edit.showPopup(null).then(() => this.gridUsers.instance.refresh(), () => { });
          }
        },
        location: 'after'
      });
    } else {
      this.ui.prepareToolbar(event, false);
    }
  }

  onCellPrepared(e: any) {
    // if (e.rowType === 'data' && e.column.command === 'expand') {
    //   if (e.data.roles === 'admin') {
    //     e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
    //   }
    // }
  }

  onCellClick(e: any) {
    if (e.rowType === 'data') {
      if (e.cellElement.classList[0] === 'dx-command-expand' && e.data.roles === 'admin') {
        e.component.collapseAll(-1);
      }
    }
  }
}
