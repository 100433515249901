import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable()

export class EditSpecSelectionService extends iBisEditService {

    constructor(public ds: DataService, public lg: iBisLanguageService) {
        super(ds.lg);
        this.validation = 'specSelectionValidation';

    }

    closePopup() {
        this.popup.hide();
        this.createDefault();
    }

    getById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(data);
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            resolve(this.model);
        });
    }

    reset(): void {
        this.model = {};
    }

    createDefault() {
        this.reset();
    }
}
