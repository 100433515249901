import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../service/data.service';
import { EditRoleSelectionService } from './role-selection.service';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-role-selection',
    templateUrl: './role-selection.component.html'
})
export class EditRoleSelectionComponent implements OnInit {

    constructor(public ds: DataService, public edit: EditRoleSelectionService) {
    }

    ngOnInit() { }

    onSaveSelectUserRole(event: any, selection: any) {
        if (selection.length) {
            const typeId = this.ds.getHighestTypeIdOrNull(selection);
            const role = this.ds.static.userTypes.find((ut: any) => ut.id === typeId).name;
            this.edit.model.userType = typeId;
            this.edit.model.role = role;
            this.edit.model.isSaving = true;
            this.edit.onSaveClose(event);
        } else {
          notify('Selectati un rol!', 'warning', 3000);
        }
      }
}
